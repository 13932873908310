import * as yup from 'yup';
import { useLocalization } from '../../../../../localization';
import { IncomingPrintFields } from '../types';

export const useValidation = () => {
  const { translateLang } = useLocalization();

  const stringRequiredValidation = yup.string().required(translateLang('emptyInputError'));

  const validationSchema = yup.object({
    [IncomingPrintFields.BillNumber]: yup
      .number()
      .typeError(translateLang('mustBeNumber'))
      .moreThan(0, translateLang('validationErrorNumberMoreThan0'))
      .required(),
    [IncomingPrintFields.Email]: yup.string().email().nullable(),
  });

  return {
    validationSchema,
  };
};

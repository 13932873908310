import React from 'react';
import { Stack } from '@mui/material';
import { addDays, formatISO, startOfDay, endOfDay } from 'date-fns';

import { ReturnTabTable } from './ReturnTabTable';
import { ToolBarReturnTab } from './ToolBarReturnTab';
import { useLocalization } from '../../../../localization';
import { useGetOrderItemsReturnedFromCustomers } from '../../../../graphql/queries/hook/useGetOrderItemsReturnedFromCustomers';
import { useWarehouseReturnFromDeliveryTabSearchParams } from './hooks/useWarehouseReturnFromDeliveryTabSearchParams';
import { SearchInput } from '../../../../components/SearchInput';
import { PAGE_COUNT } from '../../../../helpers/constants';

export const ReturnTab = () => {
  const { translateLang } = useLocalization();

  const {
    page,
    filters,
    providers,
    searchText,
    changeParam,
    resetFilters,
    selectedDates,
    changSearchText,
  } = useWarehouseReturnFromDeliveryTabSearchParams({
    defaultDateFrom: formatISO(startOfDay(addDays(new Date(), -30))),
    defaultDateTo: formatISO(endOfDay(new Date())),
  });

  const { returnedItems, isLoading, totalPage, total } = useGetOrderItemsReturnedFromCustomers({
    filters,
    page: parseInt(page),
    pageSize: PAGE_COUNT,
  });

  return (
    <Stack width="100%" paddingBottom={10}>
      <SearchInput
        onChangeText={changSearchText}
        value={searchText}
        placeholder={translateLang('searchByNameArticle')}
      />
      <ToolBarReturnTab
        result={total}
        changeParam={changeParam}
        providers={providers}
        selectedDates={selectedDates}
        resetFilters={resetFilters}
      />
      <ReturnTabTable
        returnedItems={returnedItems}
        loading={isLoading}
        changePage={newPage => changeParam({ page: `${newPage + 1}` })}
        currentPage={parseInt(page)}
        totalPage={totalPage}
      />
    </Stack>
  );
};

import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { NewsletterSubscriptionEntityFragmentDoc } from '../../fragments/__generated__/newsletterSubscriptionEntity';
import { PaginationFragmentDoc } from '../../fragments/__generated__/Pagination';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetNewsletterSubscriptionsQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  filters?: Types.InputMaybe<Types.NewsletterSubscriptionFiltersInput>;
}>;


export type GetNewsletterSubscriptionsQuery = { __typename?: 'Query', newsletterSubscriptions?: { __typename?: 'NewsletterSubscriptionEntityResponseCollection', data: Array<{ __typename?: 'NewsletterSubscriptionEntity', id?: string | null, attributes?: { __typename?: 'NewsletterSubscription', name: string, phone: string, source: Types.Enum_Newslettersubscription_Source, status: Types.Enum_Newslettersubscription_Status, createdAt?: any | null, updatedAt?: any | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', page: number, pageCount: number, pageSize: number, total: number } } } | null };


export const GetNewsletterSubscriptionsDocument = gql`
    query getNewsletterSubscriptions($pagination: PaginationArg, $sort: [String], $filters: NewsletterSubscriptionFiltersInput) {
  newsletterSubscriptions(pagination: $pagination, sort: $sort, filters: $filters) {
    data {
      ...newsletterSubscriptionEntity
    }
    meta {
      ...Pagination
    }
  }
}
    ${NewsletterSubscriptionEntityFragmentDoc}
${PaginationFragmentDoc}`;

/**
 * __useGetNewsletterSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetNewsletterSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsletterSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsletterSubscriptionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetNewsletterSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetNewsletterSubscriptionsQuery, GetNewsletterSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsletterSubscriptionsQuery, GetNewsletterSubscriptionsQueryVariables>(GetNewsletterSubscriptionsDocument, options);
      }
export function useGetNewsletterSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsletterSubscriptionsQuery, GetNewsletterSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsletterSubscriptionsQuery, GetNewsletterSubscriptionsQueryVariables>(GetNewsletterSubscriptionsDocument, options);
        }
export type GetNewsletterSubscriptionsQueryHookResult = ReturnType<typeof useGetNewsletterSubscriptionsQuery>;
export type GetNewsletterSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetNewsletterSubscriptionsLazyQuery>;
export type GetNewsletterSubscriptionsQueryResult = Apollo.QueryResult<GetNewsletterSubscriptionsQuery, GetNewsletterSubscriptionsQueryVariables>;
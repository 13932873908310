import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type RegisterByPhoneMutationVariables = Types.Exact<{
  input: Types.RegisterByPhoneNumberInput;
}>;


export type RegisterByPhoneMutation = { __typename?: 'Mutation', registerByPhoneNumber?: { __typename?: 'UsersPermissionsPasswordPayload', ok: boolean } | null };


export const RegisterByPhoneDocument = gql`
    mutation registerByPhone($input: RegisterByPhoneNumberInput!) {
  registerByPhoneNumber(input: $input) {
    ok
  }
}
    `;
export type RegisterByPhoneMutationFn = Apollo.MutationFunction<RegisterByPhoneMutation, RegisterByPhoneMutationVariables>;

/**
 * __useRegisterByPhoneMutation__
 *
 * To run a mutation, you first call `useRegisterByPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterByPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerByPhoneMutation, { data, loading, error }] = useRegisterByPhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterByPhoneMutation(baseOptions?: Apollo.MutationHookOptions<RegisterByPhoneMutation, RegisterByPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterByPhoneMutation, RegisterByPhoneMutationVariables>(RegisterByPhoneDocument, options);
      }
export type RegisterByPhoneMutationHookResult = ReturnType<typeof useRegisterByPhoneMutation>;
export type RegisterByPhoneMutationResult = Apollo.MutationResult<RegisterByPhoneMutation>;
export type RegisterByPhoneMutationOptions = Apollo.BaseMutationOptions<RegisterByPhoneMutation, RegisterByPhoneMutationVariables>;
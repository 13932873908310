import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { addDays, format } from 'date-fns';

import { ToolbarInvoicesTab } from './ToolbarInvoicesTab';
import { PAGE_COUNT } from '../../../../helpers/constants';
import { IncomingInvoicesTabTable } from './IncomingInvoicesTabTable';
import { IncomingInvoice } from './components/IncomingInvoice/IncomingInvoice';
import { useGetIncomingInvoices } from '../../../../graphql/queries/hook/useGetIncomingInvoices';
import { SupplierReturnsTable } from '../../../SupplierInfo/components/SupplierReturns/SupplierReturnsTable';
import { useGetSupplierReturns } from '../../../SupplierInfo/components/SupplierReturns/hooks/useGetSupplierReturns';
import { ReturnInvoice } from '../../../SupplierInfo/components/SupplierReturns/component/ReturnInvoice/ReturnInvoice';
import { useAccountingSuppliersTabSearchParams } from './hooks/useAccountingSuppliersTabSearchParams';
import { useGetSuppliers } from '../../../../graphql/queries/hook/useGetSuppliers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  IncomingInvoiceFiltersInput,
  SupplierReturnFiltersInput,
} from '../../../../__generated__/types';

interface Props {
  idSupplier: string;
  refetchSupplierBalance: () => void;
}

export const IncomingInvoicesTab: FC<Props> = ({ idSupplier, refetchSupplierBalance }) => {
  const navigate = useNavigate();
  const { data: supplierInfo } = useGetSuppliers({
    id: {
      eq: idSupplier,
    },
  });

  const [searchParams] = useSearchParams({
    subTab: 'incomingInvoices',
  });

  const activeInvoicesTab: 'incomingInvoices' | 'returnInvoices' =
    (searchParams.get('subTab') as 'incomingInvoices' | 'returnInvoices') ?? 'incomingInvoices';

  const { changeParam, filters, page, selectedDates } = useAccountingSuppliersTabSearchParams({
    supplierId: idSupplier,
    defaultDateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    defaultDateTo: format(new Date(), 'yyyy-MM-dd'),
    subTab: activeInvoicesTab,
    tab: 'invoices',
  });

  const {
    data: incomingInvoicesData,
    loading,
    totalPage,
    refetch: refetchIncomingInvoices,
  } = useGetIncomingInvoices({
    filters: filters as IncomingInvoiceFiltersInput,
    page: parseInt(page),
    pageSize: PAGE_COUNT,
    skip: activeInvoicesTab !== 'incomingInvoices',
  });

  const { supplierReturns, pagination, isLoading } = useGetSupplierReturns({
    pagination: {
      page: parseInt(page),
      pageSize: PAGE_COUNT,
    },
    skip: activeInvoicesTab !== 'returnInvoices',
    filters: filters as SupplierReturnFiltersInput,
  });

  const [isInvoiceTable, setIsInvoiceTable] = useState(false);
  const [isReturnInvoiceTable, setIsReturnInvoiceTable] = useState(false);

  const [invoiceId, setInvoiceId] = useState('');
  const [returnInvoiceId, setReturnInvoiceId] = useState('');

  const toggleInvoiceTable = (id: string) => {
    setIsReturnInvoiceTable(false);
    setIsInvoiceTable(!isInvoiceTable);
    setInvoiceId(id);
  };

  const toggleReturnInvoiceTable = (id: string) => {
    setIsInvoiceTable(false);
    setIsReturnInvoiceTable(!isReturnInvoiceTable);
    setReturnInvoiceId(id);
  };

  const handleActiveInvoicesTab = (type: 'incomingInvoices' | 'returnInvoices') => {
    navigate(`/accounting/providers/${idSupplier}?tab=invoices&subTab=${type}`);
  };

  const supplierName = supplierInfo?.suppliers?.data?.[0]?.attributes?.name ?? '';

  return (
    <>
      {!isInvoiceTable && !isReturnInvoiceTable ? (
        <Stack width="100%">
          <ToolbarInvoicesTab
            selectedDates={selectedDates}
            changeParam={changeParam}
            supplier={supplierName}
            activeInvoicesTab={activeInvoicesTab}
            handleActiveInvoicesTab={handleActiveInvoicesTab}
            refetchIncomingInvoices={refetchIncomingInvoices}
          />
          {activeInvoicesTab === 'incomingInvoices' ? (
            <IncomingInvoicesTabTable
              loading={loading}
              currentPage={parseInt(page)}
              totalPage={totalPage}
              items={incomingInvoicesData?.incomingInvoices?.data}
              changePage={page => changeParam({ page: `${page + 1}` })}
              toggleInvoiceTable={toggleInvoiceTable}
              refetchSupplierBalance={refetchSupplierBalance}
            />
          ) : (
            <SupplierReturnsTable
              loading={isLoading}
              currentPage={parseInt(page)}
              supplierReturns={supplierReturns}
              totalPage={pagination?.pageCount}
              changePage={page => changeParam({ page: `${page + 1}` })}
              toggleReturnInvoiceTable={toggleReturnInvoiceTable}
            />
          )}
        </Stack>
      ) : isInvoiceTable ? (
        <IncomingInvoice
          invoiceId={invoiceId}
          toggleInvoiceTable={toggleInvoiceTable}
          idSupplier={idSupplier}
          refetchIncomingInvoices={refetchIncomingInvoices}
        />
      ) : (
        <ReturnInvoice
          returnInvoiceId={returnInvoiceId}
          toggleReturnInvoiceTable={toggleReturnInvoiceTable}
        />
      )}
    </>
  );
};

import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type ForgotPasswordByPhoneMutationVariables = Types.Exact<{
  input: Types.ForgotPasswordByPhoneNumberInput;
}>;


export type ForgotPasswordByPhoneMutation = { __typename?: 'Mutation', forgotPasswordByPhoneNumber?: { __typename?: 'UsersPermissionsPasswordPayload', ok: boolean } | null };


export const ForgotPasswordByPhoneDocument = gql`
    mutation forgotPasswordByPhone($input: ForgotPasswordByPhoneNumberInput!) {
  forgotPasswordByPhoneNumber(input: $input) {
    ok
  }
}
    `;
export type ForgotPasswordByPhoneMutationFn = Apollo.MutationFunction<ForgotPasswordByPhoneMutation, ForgotPasswordByPhoneMutationVariables>;

/**
 * __useForgotPasswordByPhoneMutation__
 *
 * To run a mutation, you first call `useForgotPasswordByPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordByPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordByPhoneMutation, { data, loading, error }] = useForgotPasswordByPhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordByPhoneMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordByPhoneMutation, ForgotPasswordByPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordByPhoneMutation, ForgotPasswordByPhoneMutationVariables>(ForgotPasswordByPhoneDocument, options);
      }
export type ForgotPasswordByPhoneMutationHookResult = ReturnType<typeof useForgotPasswordByPhoneMutation>;
export type ForgotPasswordByPhoneMutationResult = Apollo.MutationResult<ForgotPasswordByPhoneMutation>;
export type ForgotPasswordByPhoneMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordByPhoneMutation, ForgotPasswordByPhoneMutationVariables>;
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

import {
  InputMaybe,
  StockReturnedFromCustomerFiltersInput,
} from '../../../../../__generated__/types';
import { parseISO } from 'date-fns';
import { getStoredParams, saveParamsToStorage } from '../../../../../utils/localStorage';
import { getProperPage } from '../../../../../utils/getProperPage';
export type WarehouseReturnFromDeliveryTabSearchParams = {
  dateFrom?: string;
  dateTo?: string;
  searchText?: string;
  providers?: string[];
  page?: string;
};

type DefaultWarehouseReturnFromDeliveryTabSearchParams = {
  defaultDateFrom?: string;
  defaultDateTo?: string;
};

type SetParam = Partial<
  Record<keyof WarehouseReturnFromDeliveryTabSearchParams, string | string[]>
>;

const WAREHOUSE_RETURN_FROM_DELIVERY_TAB_LOCAL_STORAGE_KEY = 'spWRFDTSP';

export const useWarehouseReturnFromDeliveryTabSearchParams = ({
  defaultDateFrom,
  defaultDateTo,
}: DefaultWarehouseReturnFromDeliveryTabSearchParams = {}) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams({
    page: '1',
    tab: 'returnFromDelivery',
    ...(defaultDateFrom ? { dateFrom: defaultDateFrom } : null),
    ...(defaultDateTo ? { dateTo: defaultDateTo } : null),
    ...getStoredParams(WAREHOUSE_RETURN_FROM_DELIVERY_TAB_LOCAL_STORAGE_KEY),
  });

  const page = getProperPage(urlSearchParams.get('page') ?? '1');
  const dateTo = urlSearchParams.get('dateTo');
  const dateFrom = urlSearchParams.get('dateFrom');
  const searchText = urlSearchParams.get('searchText') ?? '';
  const providers = useMemo(() => urlSearchParams.getAll('providers'), [urlSearchParams]);

  useEffect(() => {
    setUrlSearchParams(oldSearchParams => {
      if (oldSearchParams.get('dateFrom')) {
        oldSearchParams.set('dateFrom', oldSearchParams.get('dateFrom') as string);
      } else {
        oldSearchParams.delete('dateFrom');
      }

      if (oldSearchParams.get('dateTo')) {
        oldSearchParams.set('dateTo', oldSearchParams.get('dateTo') as string);
      } else {
        oldSearchParams.delete('dateTo');
      }

      oldSearchParams.set('page', getProperPage(oldSearchParams.get('page') || '1'));
      oldSearchParams.set('tab', 'returnFromDelivery');

      return oldSearchParams;
    });
  }, []);

  useEffect(() => {
    const searchText = urlSearchParams.get('searchText');
    const dateFrom = urlSearchParams.get('dateFrom');
    const dateTo = urlSearchParams.get('dateTo');
    const providers = urlSearchParams.getAll('providers');
    const page = getProperPage(urlSearchParams.get('page') ?? '1');

    const tempUrlSearchParams = new URLSearchParams();
    if (page) {
      tempUrlSearchParams.set('page', page);
    }
    if (searchText) {
      tempUrlSearchParams.set('searchText', searchText);
    }
    if (dateFrom) {
      tempUrlSearchParams.set('dateFrom', dateFrom);
    }
    if (dateTo) {
      tempUrlSearchParams.set('dateTo', dateTo);
    }
    if (providers.length) {
      providers.forEach(provider => tempUrlSearchParams.append('providers', provider));
    }

    saveParamsToStorage(tempUrlSearchParams, WAREHOUSE_RETURN_FROM_DELIVERY_TAB_LOCAL_STORAGE_KEY);
  }, [urlSearchParams]);

  const changSearchText = useCallback(
    (text: string | undefined) => {
      if (text !== searchText) {
        setUrlSearchParams(oldSearchParams => {
          oldSearchParams.set('page', '1');

          if (text) {
            oldSearchParams.set('searchText', text);
          } else {
            oldSearchParams.delete('searchText');
          }

          oldSearchParams.set('tab', 'returnFromDelivery');

          return oldSearchParams;
        });
      }
    },
    [searchText, setUrlSearchParams]
  );

  const changeParam = useCallback(
    (params: SetParam) => {
      setUrlSearchParams(oldSearchParams => {
        let isPageParamChanged = false;
        Object.keys(params).forEach(key => {
          if (!isPageParamChanged && key === 'page') {
            isPageParamChanged = true;
          }
          const value = params[key as keyof WarehouseReturnFromDeliveryTabSearchParams];
          if (!value || (Array.isArray(value) && value.length === 0)) {
            oldSearchParams.delete(key);
          } else if (Array.isArray(value)) {
            oldSearchParams.delete(key);
            value.forEach(v => oldSearchParams.append(key, v));
          } else {
            oldSearchParams.set(key, value);
          }
        });

        if (!isPageParamChanged) {
          oldSearchParams.set('page', '1');
        }

        oldSearchParams.set('tab', 'returnFromDelivery');

        return oldSearchParams;
      });
    },
    [setUrlSearchParams]
  );

  const filters = useMemo(() => {
    const newFilters: InputMaybe<StockReturnedFromCustomerFiltersInput> = {
      and: [],
    };

    if (searchText) {
      newFilters.and?.push({
        order_item: {
          car_spare: {
            or: [
              { tecdoc_articleNumber: { containsi: searchText } },
              { car_spare_locales: { title: { containsi: searchText } } },
            ],
          },
        },
      });
    }

    if (providers.length) {
      newFilters.and?.push({
        parent_stock: {
          supplier: {
            id: {
              in: providers,
            },
          },
        },
      });
    }

    if (dateFrom && dateTo) {
      const dateFromParsed = parseISO(dateFrom);
      const dateToParsed = parseISO(dateTo);

      if (!isNaN(dateFromParsed.getTime()) && !isNaN(dateToParsed.getTime())) {
        newFilters.and?.push({
          createdAt: { between: [dateFrom, dateTo] },
        });
      }
    }

    return newFilters;
  }, [dateFrom, dateTo, providers, searchText]);

  const selectedDates = useMemo(() => {
    const datesRange = [];

    if (dateFrom) {
      const dateFromParsed = parseISO(dateFrom ?? '');
      if (!isNaN(dateFromParsed.getTime())) {
        datesRange.push(dateFromParsed);
        if (dateTo) {
          const dateToParsed = parseISO(dateTo ?? '');

          if (!isNaN(dateToParsed.getTime())) {
            datesRange.push(dateToParsed);
          }
        }
      }
    }

    return datesRange;
  }, [dateFrom, dateTo]);

  const resetFilters = useCallback(() => {
    setUrlSearchParams(() => {
      const newSearchParams = new URLSearchParams();
      if (defaultDateFrom) {
        newSearchParams.set('dateFrom', defaultDateFrom);
      }
      if (defaultDateTo) {
        newSearchParams.set('dateTo', defaultDateTo);
      }
      newSearchParams.set('page', '1');
      newSearchParams.set('tab', 'returnFromDelivery');

      return newSearchParams;
    });
  }, [defaultDateFrom, defaultDateTo, setUrlSearchParams]);

  return {
    page,
    dateTo,
    filters,
    dateFrom,
    providers,
    searchText,
    changeParam,
    resetFilters,
    selectedDates,
    changSearchText,
  };
};

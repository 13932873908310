import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetCategoryEntityFragment = { __typename?: 'CarCategoryEntity', id?: string | null, attributes?: { __typename?: 'CarCategory', name?: string | null, hasChildren: boolean, hasRoot?: boolean | null, tecdoc_linkingTargetType?: string | null, category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null } | null } | null, car_category_parent?: { __typename?: 'CarCategoryEntityResponse', data?: { __typename?: 'CarCategoryEntity', id?: string | null } | null } | null, popular_category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null } | null } | null } | null };

export const GetCategoryEntityFragmentDoc = gql`
    fragment getCategoryEntity on CarCategoryEntity {
  id
  attributes {
    name
    hasChildren
    hasRoot
    tecdoc_linkingTargetType
    category {
      data {
        id
      }
    }
    car_category_parent {
      data {
        id
      }
    }
    popular_category {
      data {
        id
      }
    }
  }
}
    `;
import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { useLocalization } from '../../../../localization';

interface Props {
  widthPercent: number;
  active: boolean;
  provider: string;
  orderID?: string;
  orderNumber?: string;
  onClickItem(): void;
}
export const ItemProvider: FC<Props> = ({
  widthPercent,
  active,
  provider,
  onClickItem,
  orderID,
}) => {
  const { translateLang } = useLocalization();
  const color = active ? '#97A408' : '#D9D9D9';
  const widthBorder = active ? 3 : 1;
  return (
    <div
      style={{
        width: `${widthPercent}%`,
        minWidth: '180px',
        marginBottom: '20px',
        height: '100px',
        borderRadius: '5px',
        border: `${widthBorder}px solid ${color}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: active ? '#E8EBE1' : 'white',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={onClickItem}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '25px',
        }}
      >
        {provider.toUpperCase()}
      </Typography>
      <Typography fontSize="10px" position="absolute" right="5px" bottom="5px">
        {`${translateLang('order')}: #${orderID}`}
      </Typography>
      {active && (
        <Box
          color="#97A408"
          width="24px"
          height="24px"
          position="absolute"
          right="-17px"
          top="-17px"
        >
          <CheckCircle />
        </Box>
      )}
    </div>
  );
};

import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetCarModelsGroupedQueryVariables = Types.Exact<{
  brandId: Types.Scalars['String'];
  locale: Types.Scalars['I18NLocaleCode'];
}>;


export type GetCarModelsGroupedQuery = { __typename?: 'Query', carModelsGrouped?: Array<{ __typename?: 'CarModelGrouped', shortName?: string | null, yearFrom?: number | null, yearTo?: number | null, models?: Array<{ __typename?: 'CarModelGroupedCarModel', name?: string | null, tecdoc_modelId?: string | null, yearFrom?: number | null, yearTo?: number | null } | null> | null } | null> | null };


export const GetCarModelsGroupedDocument = gql`
    query getCarModelsGrouped($brandId: String!, $locale: I18NLocaleCode!) {
  carModelsGrouped(brandId: $brandId, locale: $locale) {
    shortName
    yearFrom
    yearTo
    models {
      name
      tecdoc_modelId
      yearFrom
      yearTo
    }
  }
}
    `;

/**
 * __useGetCarModelsGroupedQuery__
 *
 * To run a query within a React component, call `useGetCarModelsGroupedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarModelsGroupedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarModelsGroupedQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetCarModelsGroupedQuery(baseOptions: Apollo.QueryHookOptions<GetCarModelsGroupedQuery, GetCarModelsGroupedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarModelsGroupedQuery, GetCarModelsGroupedQueryVariables>(GetCarModelsGroupedDocument, options);
      }
export function useGetCarModelsGroupedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarModelsGroupedQuery, GetCarModelsGroupedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarModelsGroupedQuery, GetCarModelsGroupedQueryVariables>(GetCarModelsGroupedDocument, options);
        }
export type GetCarModelsGroupedQueryHookResult = ReturnType<typeof useGetCarModelsGroupedQuery>;
export type GetCarModelsGroupedLazyQueryHookResult = ReturnType<typeof useGetCarModelsGroupedLazyQuery>;
export type GetCarModelsGroupedQueryResult = Apollo.QueryResult<GetCarModelsGroupedQuery, GetCarModelsGroupedQueryVariables>;
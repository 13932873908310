import React, { FC } from 'react';
import { Box } from '@mui/material';
import { TableProductActive } from '../../../../../components/TableProductActive/TableProductActive';
import { useGetAllOrdersAccountingTable } from '../hooks/useGetAllOrdersAccountingTable';
import { AllOrdersAccountingType } from '../../../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { OrderFiltersInput } from '../../../../../__generated__/types';

interface Props {
  totalPage: number;
  allOrdersAccounting?: AllOrdersAccountingType[];
  currentPage?: number;
  changePage(page: number): void;
  loading?: boolean;
  filters?: OrderFiltersInput;
}

export const AllOrdersTable: FC<Props> = ({
  allOrdersAccounting,
  totalPage,
  currentPage,
  changePage,
  loading,
  filters,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetAllOrdersAccountingTable({
    allOrdersAccounting,
    filters,
    currentPage,
  });
  return (
    <>
      <Box boxSizing="border-box" width="100%">
        <TableProductActive
          startPage={currentPage}
          totalPages={totalPage}
          itemsRows={getRowsTableActive()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePage}
          loading={loading}
        />
      </Box>
    </>
  );
};

import React from 'react';
import { IncomingInvoiceFiltersInput } from '../../../__generated__/types';
import { useGetIncomingInvoicesQuery } from '../__generated__/getIncomingInvoices';

interface PropsHook {
  filters?: IncomingInvoiceFiltersInput;
  idSupplier?: string;
  page: number;
  pageSize: number;
  skip?: boolean;
}

export const useGetIncomingInvoices = ({ filters, page, pageSize, skip = false }: PropsHook) => {
  const { data, loading, refetch, ...rest } = useGetIncomingInvoicesQuery({
    skip,
    variables: {
      pagination: {
        page,
        pageSize,
      },
      filters,
      sort: ['date:desc'],
    },
  });

  return {
    data,
    refetch,
    loading,
    total: data?.incomingInvoices?.meta.pagination.total || 0,
    totalPage: data?.incomingInvoices?.meta.pagination.pageCount || 0,
    ...rest,
  };
};

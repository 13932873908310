import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { SpecialProposalFragmentDoc } from '../../fragments/__generated__/specialProposal';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type UpdateSpecialProposalMutationVariables = Types.Exact<{
  data: Types.SpecialProposalInput;
  id: Types.Scalars['ID'];
  locale: Types.Scalars['I18NLocaleCode'];
}>;


export type UpdateSpecialProposalMutation = { __typename?: 'Mutation', updateSpecialProposal?: { __typename?: 'SpecialProposalEntityResponse', data?: { __typename?: 'SpecialProposalEntity', id?: string | null, attributes?: { __typename?: 'SpecialProposal', name?: string | null, system_name?: Types.Enum_Specialproposal_System_Name | null, generalSpares?: { __typename?: 'GeneralSparesRelationSpecialProposalCollection', data: Array<{ __typename?: 'GeneralSparesEntity', id: string, attributes?: { __typename?: 'GeneralSpare', brandId?: number | null, brandName?: string | null, images?: any | null, price?: number | null, tecdoc_articleNumber?: string | null, title?: string | null } | null }> } | null } | null } | null } | null };


export const UpdateSpecialProposalDocument = gql`
    mutation updateSpecialProposal($data: SpecialProposalInput!, $id: ID!, $locale: I18NLocaleCode!) {
  updateSpecialProposal(data: $data, id: $id, locale: $locale) {
    data {
      ...specialProposal
    }
  }
}
    ${SpecialProposalFragmentDoc}`;
export type UpdateSpecialProposalMutationFn = Apollo.MutationFunction<UpdateSpecialProposalMutation, UpdateSpecialProposalMutationVariables>;

/**
 * __useUpdateSpecialProposalMutation__
 *
 * To run a mutation, you first call `useUpdateSpecialProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpecialProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpecialProposalMutation, { data, loading, error }] = useUpdateSpecialProposalMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useUpdateSpecialProposalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpecialProposalMutation, UpdateSpecialProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpecialProposalMutation, UpdateSpecialProposalMutationVariables>(UpdateSpecialProposalDocument, options);
      }
export type UpdateSpecialProposalMutationHookResult = ReturnType<typeof useUpdateSpecialProposalMutation>;
export type UpdateSpecialProposalMutationResult = Apollo.MutationResult<UpdateSpecialProposalMutation>;
export type UpdateSpecialProposalMutationOptions = Apollo.BaseMutationOptions<UpdateSpecialProposalMutation, UpdateSpecialProposalMutationVariables>;
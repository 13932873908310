import React, { ChangeEvent, FC, ReactNode, useState } from 'react';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { OrderItemType } from '../../../helpers/types';
import { CustomModal } from '../../../components';
import { useLocalization } from '../../../localization';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateOrderItemMutation } from '../../../graphql/mutations/__generated__/updateOrderItem';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { handlerError } from '../../../helpers/functions';

interface Props {
  dataProduct?: OrderItemType | null;
  handleClose: () => void;
  open: boolean;
}

export const ChangeQuantityModal: FC<Props> = ({ dataProduct, handleClose, open }) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [runUpdateOrderItem, { loading }] = useUpdateOrderItemMutation();
  const [currentQuantity, setCurrentQuantity] = useState(dataProduct?.quantity ?? 0);

  const handleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const quantity = +event.target.value;
    if (quantity <= (dataProduct?.quantity ?? 0)) {
      setCurrentQuantity(quantity);
    } else {
      setCurrentQuantity(dataProduct?.quantity ?? 0);
    }
  };
  const handleSetDiscountForSpare = async () => {
    try {
      const response = await runUpdateOrderItem({
        variables: {
          id: dataProduct?.id ?? '',
          data: {
            quantity: currentQuantity,
          },
        },
      });
      if (response.data) {
        handleClose();
        addNotification({
          messageError: 'Update item discount success',
          typeMessage: 'success',
        });
      }
    } catch (error: unknown) {
      handlerError(error);
    }
  };

  const isDisable = currentQuantity === dataProduct?.quantity;
  return (
    <CustomModal
      title={translateLang('quantity')}
      handleClose={handleClose}
      open={open}
      bgcolor="#F1F3F8"
      width="460px"
      display="flex"
      flexDirection="column"
    >
      <Stack width="100%" height="100%" p="10px 20px" boxSizing="border-box">
        <InformItem label={translateLang('productName')} value={dataProduct?.productName ?? ''} />
        <InformItem label={translateLang('provider')} value={dataProduct?.provider ?? ''} />
        <InformItem
          label={translateLang('quantity')}
          value={
            <TextField
              autoComplete="off"
              value={currentQuantity}
              InputProps={{ inputProps: { min: 1 } }}
              onChange={handleTextInputChange}
              type="number"
              style={{ width: '100%' }}
            />
          }
        />
        <Button
          onClick={handleSetDiscountForSpare}
          variant="contained"
          disabled={isDisable}
          style={{
            textTransform: 'none',
            backgroundColor: isDisable ? '#c0c0c0' : '#5269A3',
            borderRadius: '8px',
            height: '50px',
            marginTop: '20px',
          }}
        >
          <TranslatedField
            originText="addQuantity"
            fontSize={16}
            isTranslate
            noWrap
            color={isDisable ? '#8e8e8e' : '#fff'}
          />
        </Button>
        {loading && (
          <Box display="flex" justifyContent="center" py={4}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Stack>
    </CustomModal>
  );
};

interface PropsInformItem {
  label: string;
  value: string | ReactNode;
}
function InformItem({ label, value }: PropsInformItem) {
  return (
    <Box display="flex" width="100%" alignItems="flex-end" mb={2} justifyContent="space-between">
      <Typography mr={3} fontSize="20px">{`${label}: `}</Typography>
      {typeof value === 'string' ? <Typography fontSize="20px">{`${value}`}</Typography> : value}
    </Box>
  );
}

import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { InputMaybe, StockFiltersInput } from '../../../../../__generated__/types';
import { getStoredParams, saveParamsToStorage } from '../../../../../utils/localStorage';
import { getProperPage } from '../../../../../utils/getProperPage';
export type WarehouseWarehouseTabSearchParams = {
  searchText?: string;
  page?: string;
  showZeroBalance?: boolean;
  sort?: string[];
};

type SetParam = Partial<Record<keyof WarehouseWarehouseTabSearchParams, string | string[]>>;

const WAREHOUSE_WAREHOUSE_TAB_LOCAL_STORAGE_KEY = 'spWWTSP';

export const useWarehouseWarehouseTabSearchParams = () => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams({
    page: '1',
    tab: 'warehouse',
    sort: ['car_spare.tecdoc_articleNumber:asc'],
    ...getStoredParams(WAREHOUSE_WAREHOUSE_TAB_LOCAL_STORAGE_KEY),
  });

  const page = getProperPage(urlSearchParams.get('page') ?? '1');
  const searchText = urlSearchParams.get('searchText') ?? '';
  const showZeroBalance = !!(urlSearchParams.get('showZeroBalance') ?? '');
  const sorts = urlSearchParams.getAll('sort');

  useEffect(() => {
    setUrlSearchParams(currentUrlSearchParams => {
      currentUrlSearchParams.set('page', getProperPage(currentUrlSearchParams.get('page') ?? '1'));
      currentUrlSearchParams.set('tab', 'warehouse');

      return currentUrlSearchParams;
    });
  }, []);

  useEffect(() => {
    const tempUrlSearchParams = new URLSearchParams();
    if (urlSearchParams.get('searchText')) {
      tempUrlSearchParams.set('searchText', urlSearchParams.get('searchText') as string);
    }
    tempUrlSearchParams.set('page', getProperPage(urlSearchParams.get('page') ?? '1'));
    saveParamsToStorage(tempUrlSearchParams, WAREHOUSE_WAREHOUSE_TAB_LOCAL_STORAGE_KEY);
  }, [urlSearchParams]);

  const changSearchText = useCallback(
    (text: string | undefined) => {
      if (text !== searchText) {
        setUrlSearchParams(oldSearchParams => {
          if (text) {
            oldSearchParams.set('searchText', text);
          } else {
            oldSearchParams.delete('searchText');
          }

          oldSearchParams.set('tab', 'warehouse');
          oldSearchParams.set('page', '1');

          return oldSearchParams;
        });
      }
    },
    [setUrlSearchParams, searchText]
  );

  const changeParam = useCallback(
    (params: SetParam) => {
      setUrlSearchParams(oldSearchParams => {
        let isPageParamChanged = false;
        Object.keys(params).forEach(key => {
          const value = params[key as keyof WarehouseWarehouseTabSearchParams];

          if (!isPageParamChanged && key === 'page') {
            isPageParamChanged = true;
          }
          if (!value || (Array.isArray(value) && value.length === 0)) {
            oldSearchParams.delete(key);
          } else if (Array.isArray(value)) {
            oldSearchParams.delete(key);
            value.forEach(v => oldSearchParams.append(key, v));
          } else {
            oldSearchParams.set(key, value);
          }
        });

        if (!isPageParamChanged) {
          oldSearchParams.set('page', '1');
        }
        oldSearchParams.set('tab', 'warehouse');

        return oldSearchParams;
      });
    },
    [setUrlSearchParams]
  );

  const filters = useMemo(() => {
    const newFilters: InputMaybe<StockFiltersInput> = { and: [] };

    if (searchText) {
      newFilters.and?.push({
        or: [
          {
            car_spare: {
              or: [
                { tecdoc_articleNumber: { containsi: searchText } },
                { car_spare_locales: { title: { containsi: searchText } } },
              ],
            },
          },
          { supplier: { name: { containsi: searchText } } },
        ],
      });
    }

    if (!showZeroBalance) {
      newFilters.and?.push({
        balanceQuantity: {
          gt: 0,
        },
      });
    }

    return newFilters;
  }, [searchText, showZeroBalance]);

  const sort = useMemo(() => {
    const newSort: string[] = [...sorts];
    if (!newSort.some(item => item.startsWith('id:'))) {
      newSort.push('id:desc');
    }
    return newSort;
  }, [sorts.join()]);

  return {
    page,
    sort,
    filters,
    searchText,
    changeParam,
    showZeroBalance,
    changSearchText,
  };
};

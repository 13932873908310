import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

import { getOrderNumberFilter } from '../../../../../utils/getOrderNumberFilter';
import {
  Enum_Componentorderstatuseditinghistory_Status,
  Enum_Order_Status,
  InputMaybe,
  OrderFiltersInput,
} from '../../../../../__generated__/types';
import { getStoredParams, saveParamsToStorage } from '../../../../../utils/localStorage';
import { getProperPage } from '../../../../../utils/getProperPage';

export type WarehouseOrderTabSearchParams = {
  searchText?: string;
  statuses?: (keyof Enum_Order_Status)[];
  page?: string;
};

type SetParam = Partial<Record<keyof WarehouseOrderTabSearchParams, string | string[]>>;

const WAREHOUSE_ORDER_TAB_LOCAL_STORAGE_KEY = 'spWOTSP';

const paramsValidation = (
  key: keyof WarehouseOrderTabSearchParams,
  value: string | string[] | undefined
): boolean => {
  if (value) {
    switch (key) {
      case 'statuses': {
        if (Array.isArray(value)) {
          if (value.length) {
            return value.every(
              item =>
                item === Enum_Componentorderstatuseditinghistory_Status.Awaiting ||
                item === Enum_Componentorderstatuseditinghistory_Status.InStock ||
                item === Enum_Componentorderstatuseditinghistory_Status.PickedUp ||
                item === Enum_Componentorderstatuseditinghistory_Status.Completed ||
                item === Enum_Componentorderstatuseditinghistory_Status.Delivery
            );
          }
          return true;
        }
        return (
          value === Enum_Componentorderstatuseditinghistory_Status.Awaiting ||
          value === Enum_Componentorderstatuseditinghistory_Status.InStock ||
          value === Enum_Componentorderstatuseditinghistory_Status.PickedUp ||
          value === Enum_Componentorderstatuseditinghistory_Status.Completed ||
          value === Enum_Componentorderstatuseditinghistory_Status.Delivery
        );
      }
      default: {
        return true;
      }
    }
  }
  return true;
};

export const useWarehouseOrderTabSearchParams = () => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams({
    page: '1',
    tab: 'order',
    ...getStoredParams(WAREHOUSE_ORDER_TAB_LOCAL_STORAGE_KEY),
  });
  const page = getProperPage(urlSearchParams.get('page') ?? '1');
  const searchText = urlSearchParams.get('searchText') ?? '';
  const statuses = useMemo(() => urlSearchParams.getAll('statuses'), [urlSearchParams]);

  useEffect(() => {
    setUrlSearchParams(currentUrlSearchParams => {
      const currentSearchText = currentUrlSearchParams.get('searchText');
      const currentStatuses = currentUrlSearchParams.getAll('statuses');
      if (currentSearchText) {
        currentUrlSearchParams.set('searchText', currentSearchText);
      }
      if (currentStatuses.length) {
        currentStatuses.forEach(status => currentUrlSearchParams.set('statuses', status));
      }

      if (urlSearchParams.get('page')) {
        currentUrlSearchParams.set('page', getProperPage(urlSearchParams.get('page') ?? '1'));
      }
      return currentUrlSearchParams;
    });
  }, []);

  useEffect(() => {
    const tempUrlSearchParams = new URLSearchParams();
    if (urlSearchParams.get('searchText')) {
      tempUrlSearchParams.set('searchText', urlSearchParams.get('searchText') as string);
    }
    if (urlSearchParams.get('page')) {
      tempUrlSearchParams.set('page', getProperPage(urlSearchParams.get('page') ?? '1'));
    }
    if (urlSearchParams.getAll('statuses').length) {
      urlSearchParams
        .getAll('statuses')
        .forEach(status => tempUrlSearchParams.append('statuses', status));
    }

    saveParamsToStorage(tempUrlSearchParams, WAREHOUSE_ORDER_TAB_LOCAL_STORAGE_KEY);
  }, [urlSearchParams]);

  const changSearchText = useCallback(
    (text: string | undefined) => {
      if (text !== searchText) {
        setUrlSearchParams(oldSearchParams => {
          if (text) {
            oldSearchParams.set('searchText', text);
          } else {
            oldSearchParams.delete('searchText');
          }

          oldSearchParams.set('tab', 'order');
          oldSearchParams.set('page', '1');

          return oldSearchParams;
        });
      }
    },
    [setUrlSearchParams, searchText]
  );

  const changeParam = useCallback(
    (params: SetParam) => {
      setUrlSearchParams(oldSearchParams => {
        let isPageParamChanged = false;
        Object.keys(params).forEach(key => {
          const value = params[key as keyof WarehouseOrderTabSearchParams];
          if (paramsValidation(key as keyof WarehouseOrderTabSearchParams, value)) {
            if (!isPageParamChanged && key === 'page') {
              isPageParamChanged = true;
            }
            if (!value || (Array.isArray(value) && value.length === 0)) {
              oldSearchParams.delete(key);
            } else if (Array.isArray(value)) {
              oldSearchParams.delete(key);
              value.forEach(v => oldSearchParams.append(key, v));
            } else {
              oldSearchParams.set(key, value);
            }
          }
        });

        if (!isPageParamChanged) {
          oldSearchParams.set('page', '1');
        }
        oldSearchParams.set('tab', 'order');

        return oldSearchParams;
      });
    },
    [setUrlSearchParams]
  );

  const filters = useMemo(() => {
    const newFilters: InputMaybe<OrderFiltersInput> = {
      and: [{ or: [{ isDeleted: { eq: false } }, { isDeleted: { null: true } }] }],
    };

    if (searchText) {
      const orderNumberFilter = getOrderNumberFilter(searchText);
      let cleanedSearchText = searchText.trim().replace(/\s+/g, '');

      if (cleanedSearchText.startsWith('+')) {
        cleanedSearchText = cleanedSearchText.substring(1);
      }
      newFilters.and?.push({
        or: [
          ...orderNumberFilter,
          {
            customer: {
              customer_contact_info: {
                or: [
                  { phoneNumbers: { number: { containsi: cleanedSearchText } } },
                  { firstName: { containsi: searchText } },
                  { lastName: { containsi: searchText } },
                  { user: { id: { containsi: searchText } } },
                ],
              },
            },
          },
          {
            customer: {
              username: { containsi: searchText },
            },
          },
        ],
      });
    }

    if (statuses.length) {
      newFilters.and?.push({
        status: { in: statuses },
      });
    } else {
      newFilters.and?.push({
        status: {
          in: [
            Enum_Componentorderstatuseditinghistory_Status.Awaiting,
            Enum_Componentorderstatuseditinghistory_Status.InStock,
            Enum_Componentorderstatuseditinghistory_Status.PickedUp,
            Enum_Componentorderstatuseditinghistory_Status.Completed,
            Enum_Componentorderstatuseditinghistory_Status.Delivery,
          ],
        },
      });
    }

    return newFilters;
  }, [searchText, statuses]);

  return {
    page,
    filters,
    statuses,
    searchText,
    changeParam,
    changSearchText,
  };
};

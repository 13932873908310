import React, { FC } from 'react';
import { Avatar, Box, Button, Typography, Zoom } from '@mui/material';
import { useLocalization } from '../../../localization';
import { useBarcode } from 'next-barcode';
import { useGetOrderItemsAwaiting } from '../../../graphql/queries/hook/useGetOrderItems';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../../components/TableProduct/TableProduct';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useBindProductToBarcodeMutation } from '../../../graphql/mutations/__generated__/bindProductToBarcode';
import { handlerError } from '../../../helpers/functions';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { Enum_Componentorderstatuseditinghistory_Status } from '../../../__generated__/types';

interface Props {
  isOpen: boolean;
  eanNumber: string;
  onChange(): void;
}
export const AssignBarCodeComponent: FC<Props> = ({ eanNumber, isOpen, onChange }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const { orderItems, loading: loadOrderItems } = useGetOrderItemsAwaiting({
    filters: {
      order: { status: { containsi: Enum_Componentorderstatuseditinghistory_Status.Awaiting } },
    },
  });
  const [runBindProductToBarcode, { loading: loadBind }] = useBindProductToBarcodeMutation();
  const { inputRef } = useBarcode({
    value: eanNumber || 'no number',
    options: {
      background: 'transparent',
      textPosition: 'top',
      textMargin: 10,
      height: 60,
      fontSize: 14,
    },
  });

  const handlerBindProductToBarcode = async (carSpareId?: string | null) => {
    if (carSpareId) {
      try {
        const resp = await runBindProductToBarcode({
          variables: {
            barcode: eanNumber,
            carSpareId,
            locale: updateSelectLanguage,
          },
        });
        if (resp.data?.addBarcodeToCarSpare?.data?.id) {
          addNotification({
            messageError: `[SUCCESS] ${translateLang('operationSuccessfullyCompleted')}`,
            typeMessage: 'success',
          });
          onChange();
        }
      } catch (err: unknown) {
        handlerError(err);
      }
    }
  };
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      { title: translateLang('photo'), containerProps: { width: '66px' } },
      { title: translateLang('article') },
      { title: translateLang('productName') },
      { title: translateLang('manufacturer') },
      { title: translateLang('provider'), containerProps: { width: '40px' } },
      { title: translateLang(''), containerProps: { width: '40px' } },
    ];
  };
  const getRowsTable = (): Array<TableCellsValue> => {
    if (orderItems.length) {
      return orderItems
        .filter(item => !item.eanNumber?.length)
        .map(item => {
          const values: Array<BoxCell> = [];
          const img = item?.images as Array<{ [key: string]: string }>;
          const url = img && img.length ? img[0].imageURL200 : '';
          values.push({
            val: (
              <Avatar
                alt="Remy Sharp"
                src={url}
                variant="rounded"
                sx={{
                  width: '100%',
                  height: '100%',
                  '& img': {
                    objectFit: 'contain',
                  },
                }}
              />
            ),
            containerProps: {
              borderLeft: 'none',
            },
          });
          values.push({ val: item.article });
          values.push({ val: item.productName });
          values.push({ val: item.brandName });
          values.push({ val: item.provider });
          values.push({
            val: (
              <Button
                onClick={() => handlerBindProductToBarcode(item?.carSpareId)}
                variant="contained"
                style={{
                  width: '172px',
                  textTransform: 'none',
                  backgroundColor: '#5269A3',
                  borderRadius: '10px',
                  height: '40px',
                }}
              >
                <TranslatedField
                  originText="bindToBarcode"
                  fontSize={14}
                  isTranslate
                  noWrap
                  // color={textColor}
                />
              </Button>
            ),
            containerProps: { justifyContent: 'center', padding: '0 20px' },
          });
          return { cellsValues: values };
        });
    }
    return [];
  };

  return (
    <Zoom
      in={isOpen}
      mountOnEnter
      unmountOnExit
      style={{ transitionDelay: isOpen ? '500ms' : '0ms' }}
    >
      <Box width="100%" height="100%" display="flex" flexDirection="column">
        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
          <svg ref={inputRef} />
        </Box>
        <Typography sx={{ fontWeight: 400, fontSize: '36px', lineHeight: '45px', mb: '10px' }}>
          {translateLang('barcodeNotDatabase')}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '20px', lineHeight: '30px', mb: '16px' }}>
          {translateLang('linkProductToBarcode')}
        </Typography>
        <Box
          flex={1}
          width="100%"
          overflow="auto"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <TableProduct
            itemsRows={getRowsTable()}
            itemsHeader={getHeadersTable()}
            loading={loadOrderItems || loadBind}
          />
        </Box>
      </Box>
    </Zoom>
  );
};

/* eslint-disable react/prop-types */
import React, { FC, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  CircularProgress,
  Color,
  Pagination,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCellProps,
  TableContainer,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { ContainerCell, StyledTableCell } from '../TableProduct/TableCellProduct';
import { StyledTableRow } from '../TableProduct/TableRowProduct';
import { NoRowsImage } from '../NoRowsImage/NoRowsImage';

export interface HeaderCell {
  title?: string | ReactNode;
  containerProps?: TableCellProps;
  checkBox?: JSX.Element;
  sortProps?: {
    fieldName: string;
    active: boolean;
    direction: 'asc' | 'desc';
    color?: string;
  };
}
interface PropsTable {
  itemsHeader: Array<HeaderCell>;
  itemsRows: ReactNode[];
  totalPages?: number;
  startPage?: number;
  changeCurrentPage?: (value: number) => void;
  loading?: boolean;
  bgColorPagination?: string;
  boxShadow?: string;
  sortHandler?: (field: string) => void;
}
interface TableSubComponents {
  RowTable: typeof RowTable;
  CellTable: typeof CellTable;
}

export const TableProductActive: FC<PropsTable> & TableSubComponents = ({
  itemsHeader,
  changeCurrentPage,
  itemsRows,
  totalPages,
  startPage,
  loading,
  boxShadow,
  sortHandler,
  bgColorPagination = 'none',
}) => {
  const getEmptyContent = () => {
    if (itemsRows.length === 0) {
      return (
        <Box
          p="20px 30px"
          minHeight={200}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {loading ? <CircularProgress size={30} color="secondary" /> : <NoRowsImage />}
        </Box>
      );
    }
    return null;
  };
  const getTableBody = () => {
    if (itemsRows.length === 0) {
      return null;
    }
    return <TableBody>{itemsRows}</TableBody>;
  };

  return (
    <TableContainer component={Paper} sx={{ minHeight: 280, boxShadow: boxShadow ?? undefined }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {itemsHeader.map((item, index) => (
              <StyledTableCell
                key={`${item.title}${index}`}
                {...item.containerProps}
                sortDirection={item.sortProps?.direction ?? false}
              >
                {item.sortProps ? (
                  <TableSortLabel
                    key={item.sortProps?.fieldName}
                    active={item.sortProps.active}
                    color="secondary"
                    direction={item.sortProps.direction}
                    onClick={e => {
                      if (sortHandler && item.sortProps?.fieldName) {
                        sortHandler(item.sortProps.fieldName);
                      }
                    }}
                    sx={{
                      ':focus': {
                        color: item.sortProps?.color,
                      },
                      ':hover': {
                        color: item.sortProps?.color,
                      },
                      color: item.sortProps?.color,
                      '&.Mui-active': {
                        color: item.sortProps?.color,
                      },
                      '& .MuiTableSortLabel-icon': {
                        fill: item.sortProps?.color,
                      },
                    }}
                  >
                    {item.checkBox ? item.checkBox : item.title}
                  </TableSortLabel>
                ) : item.checkBox ? (
                  item.checkBox
                ) : (
                  item.title
                )}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        {getTableBody()}
      </Table>
      {getEmptyContent()}
      {totalPages ? (
        <Box p="20px 30px" display="flex" justifyContent="flex-end" bgcolor={bgColorPagination}>
          <Pagination
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: '#5269A3',
                  color: 'white',
                  borderRadius: '8px',
                },
              },
            }}
            count={totalPages}
            shape="rounded"
            page={startPage}
            onChange={
              changeCurrentPage ? (event, value) => changeCurrentPage(value - 1) : undefined
            }
            defaultPage={5}
            siblingCount={3}
            boundaryCount={3}
          />
        </Box>
      ) : null}
    </TableContainer>
  );
};

export interface CellTableProps {
  children: ReactNode | string;
  ceilStyle?: BoxProps;
  styleTd?: TableCellProps;
}
const CellTable: FC<CellTableProps> = ({ children, ceilStyle, styleTd }) => {
  return (
    <StyledTableCell {...styleTd}>
      <ContainerCell {...ceilStyle}>{children}</ContainerCell>
    </StyledTableCell>
  );
};
interface RowTableProps {
  children?: ReactNode;
  sx?: SxProps;
}
const RowTable: FC<RowTableProps> = ({ children, sx }) => {
  return <StyledTableRow sx={sx}>{children}</StyledTableRow>;
};
TableProductActive.RowTable = RowTable;
TableProductActive.CellTable = CellTable;

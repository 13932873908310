import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

import {
  IncomingInvoiceFiltersInput,
  InputMaybe,
  SupplierReturnFiltersInput,
  SupplierTransactionsFiltersInput,
} from '../../../../../__generated__/types';
import { parse } from 'date-fns';
import { getStoredParams, saveParamsToStorage } from '../../../../../utils/localStorage';

export type AccountingSuppliersTabSearchParams = {
  dateFrom?: string;
  dateTo?: string;
  page?: string;
};

type DefaultAccountingSuppliersTabSearchParams = {
  supplierId: string;
  defaultDateFrom?: string;
  defaultDateTo?: string;
  subTab: string;
  tab: string;
};

type SetParam = Partial<Record<keyof AccountingSuppliersTabSearchParams, string | string[]>>;

const ACCOUNTING_INCOMING_INVOICE_TAB_LOCAL_STORAGE_KEY = 'spAIITSP';

export const useAccountingSuppliersTabSearchParams = ({
  supplierId,
  defaultDateFrom,
  defaultDateTo,
  subTab,
  tab,
}: DefaultAccountingSuppliersTabSearchParams) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams({
    page: '1',
    tab,
    subTab,
    ...(defaultDateFrom ? { dateFrom: defaultDateFrom } : null),
    ...(defaultDateTo ? { dateTo: defaultDateTo } : null),
    ...getStoredParams(
      `${ACCOUNTING_INCOMING_INVOICE_TAB_LOCAL_STORAGE_KEY}#${tab}#${subTab}#${supplierId}`
    ),
  });

  const page = urlSearchParams.get('page') ?? '1';
  const dateTo = urlSearchParams.get('dateTo');
  const dateFrom = urlSearchParams.get('dateFrom');

  useEffect(() => {
    setUrlSearchParams(oldSearchParams => {
      const storedParams = getStoredParams(
        `${ACCOUNTING_INCOMING_INVOICE_TAB_LOCAL_STORAGE_KEY}#${tab}#${subTab}#${supplierId}`
      );

      oldSearchParams.set(
        'dateFrom',
        oldSearchParams.get('dateFrom') ?? (storedParams.dateFrom as string) ?? defaultDateFrom
      );

      oldSearchParams.set(
        'dateTo',
        oldSearchParams.get('dateTo') ?? (storedParams.dateTo as string) ?? defaultDateTo
      );

      oldSearchParams.set('tab', tab);
      oldSearchParams.set('subTab', subTab);
      return oldSearchParams;
    });
  }, [subTab, supplierId, defaultDateFrom, defaultDateTo, setUrlSearchParams, tab]);

  useEffect(() => {
    const dateFrom = urlSearchParams.get('dateFrom');
    const dateTo = urlSearchParams.get('dateTo');
    const page = urlSearchParams.get('page');
    const tempUrlSearchParams = new URLSearchParams();
    if (dateFrom) {
      tempUrlSearchParams.set('dateFrom', dateFrom);
    }
    if (dateTo) {
      tempUrlSearchParams.set('dateTo', dateTo);
    }
    if (page) {
      tempUrlSearchParams.set('page', page);
    }
    saveParamsToStorage(
      tempUrlSearchParams,
      `${ACCOUNTING_INCOMING_INVOICE_TAB_LOCAL_STORAGE_KEY}#${tab}#${subTab}#${supplierId}`
    );
  }, [urlSearchParams, supplierId, subTab, tab]);

  const changeParam = useCallback(
    (params: SetParam) => {
      setUrlSearchParams(oldSearchParams => {
        let isPageParamChanged = false;
        Object.keys(params).forEach(key => {
          if (!isPageParamChanged && key === 'page') {
            isPageParamChanged = true;
          }
          const value = params[key as keyof AccountingSuppliersTabSearchParams];
          if (!value || (Array.isArray(value) && value.length === 0)) {
            oldSearchParams.delete(key);
          } else if (Array.isArray(value)) {
            oldSearchParams.delete(key);
            value.forEach(v => oldSearchParams.append(key, v));
          } else {
            oldSearchParams.set(key, value);
          }
        });

        if (!isPageParamChanged) {
          oldSearchParams.set('page', '1');
        }

        oldSearchParams.set('tab', tab);
        oldSearchParams.set('subTab', subTab);

        return oldSearchParams;
      });
    },
    [setUrlSearchParams, subTab, tab]
  );

  const filters = useMemo(() => {
    if (subTab === 'incomingInvoices') {
      const newFilters: InputMaybe<IncomingInvoiceFiltersInput> = {
        and: [
          {
            supplier: {
              id: {
                eq: supplierId,
              },
            },
          },
        ],
      };

      if (dateFrom && dateTo) {
        const dateFromParsed = parse(dateFrom, 'yyyy-MM-dd', new Date());
        const dateToParsed = parse(dateTo, 'yyyy-MM-dd', new Date());

        if (!isNaN(dateFromParsed.getTime()) && !isNaN(dateToParsed.getTime())) {
          newFilters.and?.push({
            date: {
              between: [dateFrom, dateTo],
            },
          });
        }
      }

      return newFilters;
    } else if (subTab === 'returnInvoices') {
      const newFilters: InputMaybe<SupplierReturnFiltersInput> = {
        and: [
          {
            supplier: {
              id: {
                eq: supplierId,
              },
            },
          },
        ],
      };

      if (dateFrom && dateTo) {
        const dateFromParsed = parse(dateFrom, 'yyyy-MM-dd', new Date());
        const dateToParsed = parse(dateTo, 'yyyy-MM-dd', new Date());

        if (!isNaN(dateFromParsed.getTime()) && !isNaN(dateToParsed.getTime())) {
          newFilters.and?.push({
            dateReturned: {
              between: [dateFrom, dateTo],
            },
          });
        }
      }

      return newFilters;
    } else if (subTab === 'transactions') {
      if (dateFrom && dateTo) {
        const dateFromParsed = parse(dateFrom, 'yyyy-MM-dd', new Date());
        const dateToParsed = parse(dateTo, 'yyyy-MM-dd', new Date());

        if (!isNaN(dateFromParsed.getTime()) && !isNaN(dateToParsed.getTime())) {
          const newFilters: InputMaybe<SupplierTransactionsFiltersInput> = {
            supplierId: supplierId,
            dateStart: dateFrom,
            dateEnd: dateTo,
          };
          return newFilters;
        }
      }

      return { supplierId: supplierId, dateStart: defaultDateFrom, dateEnd: defaultDateTo };
    }

    return {};
  }, [subTab, supplierId, dateFrom, dateTo, defaultDateFrom, defaultDateTo]);

  const selectedDates = useMemo(() => {
    const datesRange = [];

    if (dateFrom) {
      const dateFromParsed = parse(dateFrom ?? '', 'yyyy-MM-dd', new Date());
      if (!isNaN(dateFromParsed.getTime())) {
        datesRange.push(dateFromParsed);
        if (dateTo) {
          const dateToParsed = parse(dateTo ?? '', 'yyyy-MM-dd', new Date());

          if (!isNaN(dateToParsed.getTime())) {
            datesRange.push(dateToParsed);
          }
        }
      }
    }

    return datesRange;
  }, [dateFrom, dateTo]);

  const resetFilters = useCallback(() => {
    setUrlSearchParams(() => {
      const newSearchParams = new URLSearchParams();
      if (defaultDateFrom) {
        newSearchParams.set('dateFrom', defaultDateFrom);
      }
      if (defaultDateTo) {
        newSearchParams.set('dateTo', defaultDateTo);
      }
      newSearchParams.set('page', '1');
      newSearchParams.set('tab', tab);
      newSearchParams.set('subTab', subTab);

      return newSearchParams;
    });
  }, [defaultDateFrom, defaultDateTo, setUrlSearchParams, subTab, tab]);

  return {
    page,
    dateTo,
    filters,
    dateFrom,
    changeParam,
    resetFilters,
    selectedDates,
  };
};

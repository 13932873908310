import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { Icon } from '../../../legos';
import { theme } from '../../../helpers';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { AllOrdersAccountingType } from '../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { useLocalization } from '../../../localization';

interface CustomerAllNotPaidOrdersAccountingType extends AllOrdersAccountingType {
  checked: boolean;
}

interface InvoiceModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  orderId: string;
  customerAllNotPaidOrdersAccounting: CustomerAllNotPaidOrdersAccountingType[];
  allOrdersAccounting: AllOrdersAccountingType[];
  handleGoToInvoicePage(ids: string[]): Promise<void>;
  currentOrderNumber: string;
  currentOrderDate: string;
  currentOrderTotal: number;
}

export const InvoiceModal: FC<InvoiceModalProps> = ({
  isModalOpen,
  handleClose,
  orderId,
  allOrdersAccounting,
  customerAllNotPaidOrdersAccounting,
  handleGoToInvoicePage,
  currentOrderNumber,
  currentOrderDate,
  currentOrderTotal,
}) => {
  const [isCombineModal, setIsCombineModal] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const { translateLang } = useLocalization();
  const [customerNotPaidOrdersAccounting, setIsCustomerNotPaidOrdersAccounting] = useState(
    customerAllNotPaidOrdersAccounting
  );

  const handleCheckboxChange = (index: number) => {
    const newCheckedState = [...customerNotPaidOrdersAccounting];
    newCheckedState[index] = {
      ...newCheckedState[index],
      checked: !newCheckedState[index].checked,
    };
    setIsCustomerNotPaidOrdersAccounting(newCheckedState);
  };

  const handleSelectDeselectAll = (isSelectedAll: boolean): void => {
    const newCheckedState = [...customerNotPaidOrdersAccounting];
    newCheckedState.forEach(item => {
      item.checked = !isSelectedAll;
    });
    setIsCustomerNotPaidOrdersAccounting(newCheckedState);
    setIsSelectedAll(!isSelectedAll);
  };

  const total = useMemo(() => {
    if (isCombineModal) {
      return (
        customerNotPaidOrdersAccounting.reduce(
          (sum, item) => (item.checked ? sum + (item.total ?? 0) : sum),
          0
        ) + currentOrderTotal
      );
    }

    return 0;
  }, [customerNotPaidOrdersAccounting, isCombineModal, currentOrderTotal]);

  const toggleCombineModal = () => {
    setIsCombineModal(!isCombineModal);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      maxWidth={isCombineModal ? 'md' : 'sm'}
      fullWidth
      scroll="paper"
    >
      {!isCombineModal ? (
        <DialogContent>
          <TranslatedField
            isTranslate
            variant="h5"
            color={theme.palette.common.darkBlue}
            marginBottom={5}
            originText={'doYouWantCombineOrders'}
          />

          <Stack flexDirection="row" gap={5}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                height: 50,
                p: 2,
                textTransform: 'none',
                borderRadius: '5px',
              }}
              onClick={() => handleGoToInvoicePage([orderId])}
            >
              <TranslatedField originText={'no'} fontSize={16} isTranslate noWrap />
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{
                height: 50,
                p: 2,
                textTransform: 'none',
                backgroundColor: '#5269A3',
                borderRadius: '5px',
              }}
              onClick={toggleCombineModal}
            >
              <TranslatedField originText={'yes'} fontSize={16} isTranslate noWrap />
            </Button>
          </Stack>
        </DialogContent>
      ) : (
        <>
          <DialogTitle>
            <Stack width="100%" flexDirection="row" justifyContent="space-between" mb={2}>
              <IconButton
                onClick={() => {
                  handleClose();
                  toggleCombineModal();
                }}
                sx={{ p: 0 }}
              >
                <ArrowBack sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              <TranslatedField
                variant="h4"
                originText="combiningOrders"
                fontSize={30}
                isTranslate
                noWrap
              />
              <IconButton onClick={handleClose} sx={{ p: 0, height: 36, width: 36 }}>
                <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Typography fontSize={30} fontWeight={400} color={theme.palette.common.darkBlue} mb={1}>
              {allOrdersAccounting[0].customerInfo}
            </Typography>
            <Stack display="flex" flexDirection="row" justifyContent="space-between">
              <Stack display="flex" flexDirection="row" alignItems="baseline">
                <TranslatedField
                  variant="h4"
                  originText="order"
                  fontSize={16}
                  isTranslate
                  noWrap
                  suffix=" :"
                />
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={theme.palette.common.darkBlue}
                  sx={{ paddingX: 1 }}
                >
                  {currentOrderNumber}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={theme.palette.common.darkBlue}
                  sx={{ borderLeft: '1px solid #8D8D8D', paddingX: 1 }}
                >
                  {currentOrderDate}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={theme.palette.common.darkBlue}
                  sx={{ borderLeft: '1px solid #8D8D8D', paddingX: 1 }}
                >
                  {currentOrderTotal} €
                </Typography>
              </Stack>

              <Typography
                fontSize={16}
                fontWeight={700}
                color={theme.palette.common.darkBlue}
                sx={{ paddingX: 1 }}
              >
                {translateLang('invoiceAmount')}: {total.toFixed(2)} €
              </Typography>
            </Stack>
            <TableContainer>
              <Table
                sx={{
                  mb: 5,
                  borderCollapse: 'separate',
                  borderSpacing: '0 7px',
                  borderRadius: '20px',
                }}
                aria-label="simple table"
              >
                <Stack flexDirection="row" ml="4px">
                  <Checkbox
                    checked={isSelectedAll}
                    onChange={() => {
                      handleSelectDeselectAll(isSelectedAll);
                    }}
                  />
                  <Button onClick={() => handleSelectDeselectAll(isSelectedAll)}>
                    {translateLang('selectAll')}
                  </Button>
                </Stack>

                <TableBody
                  sx={{
                    '& .MuiTableRow-root td:first-child': {
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                    },
                    '& .MuiTableRow-root td:last-child': {
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                    },
                  }}
                >
                  {customerNotPaidOrdersAccounting.map((item, index) => (
                    <TableRow
                      key={item.orderId}
                      sx={{
                        border: 0,
                        bgcolor: theme.palette.common.white,
                      }}
                    >
                      <TableCell scope="row" sx={{ p: 0.5 }}>
                        <Checkbox
                          checked={item.checked}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </TableCell>
                      <TableCell sx={{ p: 0.5 }}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          width="100%"
                          justifyContent="center"
                          borderLeft="1px solid #8D8D8D"
                        >
                          {`#${item.orderNumber}`}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ p: 0.5 }}>
                        {' '}
                        <Box
                          display="flex"
                          flexDirection="row"
                          width="100%"
                          justifyContent="center"
                          borderLeft="1px solid #8D8D8D"
                        >
                          {item.orderData}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ p: 0.5 }} align="right">
                        <Box
                          display="flex"
                          flexDirection="row"
                          width="100%"
                          justifyContent="center"
                          borderLeft="1px solid #8D8D8D"
                        >
                          {`${item.total} €`}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
            <Button
              variant="contained"
              disabled={!customerNotPaidOrdersAccounting?.some(checked => checked?.checked)}
              fullWidth
              sx={{
                height: 50,
                maxWidth: 320,
                p: 2,
                textTransform: 'none',
                backgroundColor: '#5269A3',
                borderRadius: '19px',
                '&:disabled': {
                  color: theme.palette.common.white,
                },
              }}
              onClick={() =>
                handleGoToInvoicePage(
                  customerNotPaidOrdersAccounting
                    .filter(item => item.checked)
                    .map(item => item.orderId)
                )
              }
            >
              <TranslatedField originText={'combine'} fontSize={16} isTranslate noWrap />
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

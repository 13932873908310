import React, { FC, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { format } from 'date-fns';

import { useLocalization } from '../../../../localization';
import { useDefaultRangeCalendar } from '../../../../hooks';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import { IncomingInvoiceModal } from './components/IncomingInvoiceModal';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { AccountingSuppliersTabSearchParams } from './hooks/useAccountingSuppliersTabSearchParams';

interface Props {
  supplier?: string | null;
  selectedDates: Date[];
  changeParam: (
    params: Partial<Record<keyof AccountingSuppliersTabSearchParams, string | string[]>>
  ) => void;
  activeInvoicesTab: 'incomingInvoices' | 'returnInvoices';
  refetchIncomingInvoices: () => void;
  handleActiveInvoicesTab: (type: 'incomingInvoices' | 'returnInvoices') => void;
}

export const ToolbarInvoicesTab: FC<Props> = ({
  supplier,
  changeParam,
  selectedDates,
  activeInvoicesTab,
  refetchIncomingInvoices,
  handleActiveInvoicesTab,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();

  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Stack width="100%" flexDirection="row" justifyContent="end" gap={6.25} mb={3}>
        <RangeCalendar
          selectedDates={selectedDates}
          defaultRangeDates={reportRangeDates}
          setSelectedDates={dateRange => {
            changeParam({
              dateTo: format(dateRange[1], 'yyyy-MM-dd'),
              dateFrom: format(dateRange[0], 'yyyy-MM-dd'),
            });
          }}
        />
      </Stack>
      <WrapperToolBar flexDirection="column">
        <Stack width="100%" flexDirection="row" justifyContent="space-between">
          <Stack width="100%" flexDirection="row" justifyContent="left" gap={2.5}>
            <TranslatedField
              originText={translateLang('incomingInvoices')}
              fontSize="26px"
              sx={{
                color: activeInvoicesTab === 'incomingInvoices' ? '#191516' : '#2A345380',
                textDecoration: activeInvoicesTab === 'incomingInvoices' ? 'underline' : 'auto',
                cursor: 'pointer',
              }}
              onClick={() => handleActiveInvoicesTab('incomingInvoices')}
            />
            <TranslatedField
              originText={translateLang('returnInvoices')}
              fontSize="26px"
              sx={{
                color: activeInvoicesTab === 'returnInvoices' ? '#191516' : '#2A345380',
                textDecoration: activeInvoicesTab === 'returnInvoices' ? 'underline' : 'auto',
                cursor: 'pointer',
              }}
              onClick={() => handleActiveInvoicesTab('returnInvoices')}
            />
          </Stack>
          {activeInvoicesTab === 'incomingInvoices' ? (
            <Stack spacing={2} bgcolor="white" flexDirection="row" justifyContent="right">
              <Button
                variant="contained"
                startIcon={<Add />}
                sx={{
                  height: 50,
                  p: 2,
                  textTransform: 'none',
                  backgroundColor: '#5269A3',
                  borderRadius: '10px',
                }}
                onClick={toggleModal}
              >
                <TranslatedField
                  originText={'createIncomingInvoice'}
                  fontSize={16}
                  isTranslate
                  noWrap
                />
              </Button>
            </Stack>
          ) : null}
        </Stack>

        <IncomingInvoiceModal
          openModal={openModal}
          closeModal={toggleModal}
          supplier={supplier}
          refetchIncomingInvoices={refetchIncomingInvoices}
        />
      </WrapperToolBar>
    </>
  );
};

import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type AddOrderMutationVariables = Types.Exact<{
  data: Types.OrderInput;
}>;


export type AddOrderMutation = { __typename?: 'Mutation', createOrder?: { __typename?: 'OrderEntityResponse', data?: { __typename?: 'OrderEntity', id?: string | null, attributes?: { __typename?: 'Order', status: Types.Enum_Order_Status, total?: number | null, comment?: string | null, date: any, updatedAt?: any | null, customer?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string } | null } | null } | null, order_items?: { __typename?: 'OrderItemRelationResponseCollection', data: Array<{ __typename?: 'OrderItemEntity', id?: string | null, attributes?: { __typename?: 'OrderItem', discount?: number | null, createdAt?: any | null, delivery_date?: any | null, customer_price?: number | null, quantity?: { __typename?: 'OrderItemQuantity', outgoingQuantity?: number | null, deliveryQuantity?: number | null, orderedQuantity?: number | null, returnedQuantity?: number | null } | null } | null }> } | null, managers?: { __typename?: 'UsersPermissionsUserRelationResponseCollection', data: Array<{ __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string } | null }> } | null } | null } | null } | null };


export const AddOrderDocument = gql`
    mutation addOrder($data: OrderInput!) {
  createOrder(data: $data) {
    data {
      id
      attributes {
        status
        total
        comment
        date
        updatedAt
        customer {
          data {
            id
            attributes {
              username
            }
          }
        }
        order_items(pagination: {limit: -1}) {
          data {
            id
            attributes {
              discount
              quantity {
                outgoingQuantity
                deliveryQuantity
                orderedQuantity
                returnedQuantity
              }
              createdAt
              delivery_date
              customer_price
            }
          }
        }
        managers {
          data {
            id
            attributes {
              username
            }
          }
        }
      }
    }
  }
}
    `;
export type AddOrderMutationFn = Apollo.MutationFunction<AddOrderMutation, AddOrderMutationVariables>;

/**
 * __useAddOrderMutation__
 *
 * To run a mutation, you first call `useAddOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderMutation, { data, loading, error }] = useAddOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddOrderMutation(baseOptions?: Apollo.MutationHookOptions<AddOrderMutation, AddOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrderMutation, AddOrderMutationVariables>(AddOrderDocument, options);
      }
export type AddOrderMutationHookResult = ReturnType<typeof useAddOrderMutation>;
export type AddOrderMutationResult = Apollo.MutationResult<AddOrderMutation>;
export type AddOrderMutationOptions = Apollo.BaseMutationOptions<AddOrderMutation, AddOrderMutationVariables>;
import React, { useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { TabsBar } from './components/TabsBar';
import { useLocalization } from '../../localization';
import { SuppliersTab } from './components/Suppliers/SuppliersTab';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { AllOrdersTab } from './components/AllOrders/components/AllOrdersTab';
import { AllClientsTab } from './components/AllClients/components/AllClientsTab';
import { getStoredParams, saveParamsToStorage } from '../../utils/localStorage';

const ACCOUNTING_LAST_TAB = 'spALT';

export const AccountingPage = () => {
  const navigate = useNavigate();
  const { translateLang } = useLocalization();
  const [searchParams] = useSearchParams({
    tabs: 'orders',
    ...getStoredParams(ACCOUNTING_LAST_TAB),
  });

  const activeTable = searchParams.get('tab') ?? 'orders';
  useEffect(() => {
    const tempSearchParams = new URLSearchParams({ tab: searchParams.get('tab') ?? 'orders' });
    saveParamsToStorage(tempSearchParams, ACCOUNTING_LAST_TAB);
  }, [searchParams]);

  const handleChange = (val: string | undefined) => {
    if (val && searchParams.get('tab') !== val) {
      navigate(`/accounting?tab=${val}`);
    }
  };

  const getActiveTable = () => {
    switch (searchParams.get('tab')) {
      case 'orders':
        return <AllOrdersTab />;
      case 'clients':
        return <AllClientsTab />;
      case 'providers':
        return <SuppliersTab />;
      default:
        return <AllOrdersTab />;
    }
  };

  return (
    <WrapperPages isButton={false} titleHeader={translateLang('accounting')}>
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          height="48px"
          marginBottom="30px"
          flexWrap="wrap"
          spacing={3}
        >
          <TabsBar activeTable={activeTable} handleChange={handleChange} />
        </Stack>
        <Stack>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              placeContent: 'center',
            }}
          >
            {getActiveTable()}
          </Box>
        </Stack>
      </Stack>
    </WrapperPages>
  );
};

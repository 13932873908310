import React, { FC, useCallback } from 'react';

type ExportToCSVParams = {
  data: string[][];
  headers: string[];
  fileName: string;
};

export const useExportToCSV = () => {
  const downloadCSV = useCallback(({ data, fileName, headers }: ExportToCSVParams) => {
    const csvString = [
      headers.map(header => `"${header}"`),
      ...data.map(row => row.map(item => `"${item}"`)),
    ]
      .map(row => row.join(','))
      .join('\n');

    const blob = new Blob([csvString], { type: 'text/csv' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'download.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, []);

  return downloadCSV;
};

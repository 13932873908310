import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';

import {
  AllOrdersAccountingType,
  Enum_Order_Payment_Status,
  useLazyGetAllOrdersAccounting,
} from '../../../../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { theme } from '../../../../../../helpers';
import { InvoiceModal } from '../../../InvoiceModal';
import { PatchesName, RolesEnum } from '../../../../../../types/types';
import { useLocalization } from '../../../../../../localization';
import { PaymentModal } from '../../../PaymentModal/PaymentModal';
import { Enum_Order_Status, OrderFiltersInput } from '../../../../../../__generated__/types';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useCreateCustomerBillMutation } from '../../../../../../graphql/mutations/__generated__/createCustomerBill';
import { GetOrdersAccountingDocument } from '../../../../../../graphql/queries/__generated__/getOrdersAccounting';
import { formatDate } from '../../../../../../helpers/functions';
import { PAGE_COUNT } from '../../../../../../helpers/constants';
import { useGetMe } from '../../../../../../graphql/queries/hook/useGetMe';
export const AllOrdersAccountingRowItem = ({
  order,
  filters,
  currentPage,
}: {
  order: AllOrdersAccountingType;
  filters?: OrderFiltersInput;
  currentPage?: number;
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const { role } = useGetMe();
  const [customerOrders, setCustomerOrders] =
    useState<(AllOrdersAccountingType & { checked: boolean })[]>();

  const { translateLang, updateSelectLanguage } = useLocalization();
  const {
    orderNumber,
    orderData,
    customerInfo,
    customerId,
    total,
    paymentTotal,
    status,
    statusPayment,
    orderId,
    fullPaidDate,
    billDate,
    billNumber,
    billId,
  } = order;

  const { allOrdersAccounting, getOrdersData } = useLazyGetAllOrdersAccounting({
    filters: {
      and: [
        { or: [{ isDeleted: { eq: false } }, { isDeleted: { null: true } }] },
        { customer: { id: { eq: customerId } } },
        {
          status: {
            in: [
              Enum_Order_Status.Ordered,
              Enum_Order_Status.Awaiting,
              Enum_Order_Status.InStock,
              Enum_Order_Status.PickedUp,
              Enum_Order_Status.Delivery,
              Enum_Order_Status.Completed,
            ],
          },
        },
      ],
    },
  });

  const [createCustomerBillMutation] = useCreateCustomerBillMutation({
    refetchQueries: [
      {
        query: GetOrdersAccountingDocument,
        variables: {
          locale: updateSelectLanguage as string,
          filters,
          sort: 'orderNumber:desc',
          pagination: {
            page: currentPage,
            pageSize: PAGE_COUNT,
          },
        },
      },
    ],
  });

  const toggleInvoiceModal = () => {
    setInvoiceModal(!invoiceModal);
  };
  const togglePaymentModal = () => {
    setPaymentModal(!paymentModal);
  };

  const handleGoToInvoicePage = async (orderIds?: string[]) => {
    setLoading(true);
    if (!orderIds) {
      if (billId) {
        navigate(`/${PatchesName.Accounting}/invoice/${billId}/${customerId}`, {});
      } else {
        await createCustomerBillMutation({
          variables: {
            data: {
              orders: [orderId],
            },
          },
        })
          .then(data => {
            navigate(
              `/${PatchesName.Accounting}/invoice/${data?.data?.createCustomerBill?.data?.id}/${customerId}`,
              {}
            );
          })
          .finally(() => setLoading(false));
      }
      setLoading(false);
    } else {
      await createCustomerBillMutation({
        variables: {
          data: {
            orders: orderIds ? [...orderIds, orderId] : [orderId],
          },
        },
      })
        .then(data => {
          navigate(
            `/${PatchesName.Accounting}/invoice/${data?.data?.createCustomerBill?.data?.id}/${customerId}`,
            {}
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleGoToClientPage = () => {
    navigate(`/${PatchesName.Clients}/${customerId}/`);
  };

  const handleGetInvoceBill = async () => {
    if (!billId) {
      await getOrdersData().then(res => {
        if (res?.length) {
          const customerFilteredOrders = res
            .filter(item => !item.billId && item.orderId !== order.orderId)
            .map(item => ({
              ...item,
              checked: false,
            }));
          if (customerFilteredOrders.length > 1) {
            setCustomerOrders(customerFilteredOrders);
            toggleInvoiceModal();
          } else {
            handleGoToInvoicePage();
          }
        }
      });
    } else {
      handleGoToInvoicePage();
    }
  };

  const debtAmount = (total || 0) - (paymentTotal || 0);

  const actionDisabled =
    (status !== Enum_Order_Status.Ordered &&
      status !== Enum_Order_Status.Awaiting &&
      status !== Enum_Order_Status.InStock &&
      status !== Enum_Order_Status.PickedUp &&
      status !== Enum_Order_Status.Delivery &&
      status !== Enum_Order_Status.Completed) ||
    !(total && total > 0);

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ width: '40px', borderLeft: 'none' }}>
        {`#${orderNumber}`}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '60px' }}>
        {`${formatDate(orderData as string)}`}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '200px' }}>
        <Box
          sx={{
            cursor: 'pointer',
            transition: 'all 0.2s',
            '&:hover': {
              color: '#700a0a',
            },
          }}
          onClick={handleGoToClientPage}
        >
          {customerInfo}
        </Box>
      </TableProductActive.CellTable>
      {role === RolesEnum.Accounter ? (
        <TableProductActive.CellTable ceilStyle={{ width: '60px', alignItems: 'center' }}>
          {translateLang(status as string)}
        </TableProductActive.CellTable>
      ) : (
        ''
      )}
      <TableProductActive.CellTable ceilStyle={{ width: '60px', alignItems: 'center' }}>
        {`${total?.toFixed(2)} €`}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{
          width: '60px',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          color:
            statusPayment === Enum_Order_Payment_Status.Expired
              ? theme.palette.common.red
              : undefined,
        }}
      >
        {`${debtAmount?.toFixed(2)} €`}
      </TableProductActive.CellTable>

      <TableProductActive.CellTable
        ceilStyle={{
          width: '60px',
          alignItems: 'center',
          color:
            statusPayment === Enum_Order_Payment_Status.Expired
              ? theme.palette.common.red
              : undefined,
        }}
      >
        <Stack display="flex" flexDirection="column">
          {translateLang(statusPayment)}
          <Box>{formatDate(fullPaidDate ?? '')}</Box>
        </Stack>
      </TableProductActive.CellTable>

      <TableProductActive.CellTable
        ceilStyle={{
          width: '60px',
          alignItems: 'center',
        }}
      >
        <Stack display="flex" flexDirection="column">
          <Box>{billNumber !== null ? `#${billNumber}` : ''}</Box>
          <Box>{formatDate(billDate ?? '')}</Box>
        </Stack>
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '60px', alignItems: 'end' }}>
        <Stack flexDirection="row" gap={1.25}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleGetInvoceBill}
            disabled={actionDisabled || loading}
            style={{
              width: '100%',
              height: 40,
              textTransform: 'none',
            }}
          >
            <TranslatedField
              noWrap
              isTranslate
              fontSize={16}
              color="black"
              originText="invoiceBill"
              sx={{ overflow: 'initial' }}
            />
          </Button>
          <Button
            disabled={actionDisabled}
            color="primary"
            variant="outlined"
            style={{
              width: '100%',
              height: 40,
              textTransform: 'none',
            }}
            onClick={togglePaymentModal}
          >
            <TranslatedField
              noWrap
              isTranslate
              fontSize={16}
              color="black"
              originText="payment"
              sx={{ overflow: 'initial' }}
            />
          </Button>
        </Stack>
      </TableProductActive.CellTable>
      {invoiceModal && (
        <InvoiceModal
          isModalOpen={invoiceModal}
          handleClose={toggleInvoiceModal}
          orderId={orderId}
          currentOrderNumber={orderNumber ?? ''}
          currentOrderDate={formatDate(orderData as string)}
          currentOrderTotal={total ?? 0}
          allOrdersAccounting={allOrdersAccounting}
          customerAllNotPaidOrdersAccounting={customerOrders || []}
          handleGoToInvoicePage={handleGoToInvoicePage}
        />
      )}
      {paymentModal && (
        <PaymentModal
          isModalOpen={paymentModal}
          handleClose={togglePaymentModal}
          customerId={customerId}
          orderId={orderId}
        />
      )}
    </TableProductActive.RowTable>
  );
};

import { Stack, Box } from '@mui/material';
import React, { FC } from 'react';
import { useGetTabs } from '../hooks/useGetTabs';
import { BadgetButtons } from '../../FeedbackPage/components/BadgetButtons';

interface Props {
  activeTable: string | null;
  handleChange: (val: string | undefined) => void;
}
export const TabsBar: FC<Props> = ({ activeTable, handleChange }) => {
  const { tabs } = useGetTabs();
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack
        sx={{
          height: '58px',
          backgroundColor: 'white',
          borderRadius: '10px',
          paddingRight: '30px',
        }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        divider={
          <Box
            sx={{
              width: '0.5px',
              backgroundColor: '#2A3453',
              height: '27px',
              marginLeft: '20px',
            }}
          />
        }
      >
        {tabs.map(tab => (
          <BadgetButtons
            key={tab.id}
            value={tab.value}
            label={tab.value}
            handleSet={handleChange}
            activeTab={activeTable}
            notBadge
          />
        ))}
      </Stack>
    </Stack>
  );
};

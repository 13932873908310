import React from 'react';

import { Box, Stack } from '@mui/material';
import { AllClientsTable } from './AllClientsTable';
import { PAGE_COUNT } from '../../../../../helpers/constants';
import { useLocalization } from '../../../../../localization';
import { useGetClients } from '../../../../../graphql/queries/hook/useGetClients';
import { useClientsListSearchParams } from '../../../../ClientsList/hooks/useClientsListSearchParams';
import { SearchInput } from '../../../../../components/SearchInput';

const ACCOUNTING_ALL_CLIENTS_LIST_LOCAL_STORAGE_KEY = 'spAACLSP';

export const AllClientsTab = () => {
  const { translateLang } = useLocalization();

  const { page, filters, searchText, changeSearchText, changeParam } = useClientsListSearchParams(
    ACCOUNTING_ALL_CLIENTS_LIST_LOCAL_STORAGE_KEY,
    'clients'
  );

  const { clients, pagination, isLoading } = useGetClients({
    pagination: {
      page: parseInt(page),
      pageSize: PAGE_COUNT,
    },
    filters,
    sort: ['customer_contact_info.firstName', 'customer_contact_info.lastName', 'id:desc'],
  });

  const changePageNumber = (value: number) => {
    changeParam({ page: `${value + 1}` });
  };

  return (
    <Box width="100%">
      <Stack flexGrow={1}>
        <SearchInput
          value={searchText}
          onChangeText={changeSearchText}
          placeholder={translateLang('searchOrdersAccountingLabel')}
        />
      </Stack>

      <AllClientsTable
        allClientsAccounting={clients}
        totalPage={pagination?.pageCount}
        changePage={changePageNumber}
        currentPage={parseInt(page)}
        loading={isLoading}
      />
    </Box>
  );
};

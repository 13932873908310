import * as Types from '../../../../../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetInvoicePaymentDetailsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.SupplierPaymentDetailFiltersInput>;
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetInvoicePaymentDetailsQuery = { __typename?: 'Query', supplierPaymentDetails?: { __typename?: 'SupplierPaymentDetailEntityResponseCollection', data: Array<{ __typename?: 'SupplierPaymentDetailEntity', id?: string | null, attributes?: { __typename?: 'SupplierPaymentDetail', amount?: number | null, paymentType?: Types.Enum_Supplierpaymentdetail_Paymenttype | null, supplier_return?: { __typename?: 'SupplierReturnEntityResponse', data?: { __typename?: 'SupplierReturnEntity', id?: string | null, attributes?: { __typename?: 'SupplierReturn', total?: number | null, dateReturned?: any | null } | null } | null } | null, supplier_payment?: { __typename?: 'SupplierPaymentEntityResponse', data?: { __typename?: 'SupplierPaymentEntity', id?: string | null, attributes?: { __typename?: 'SupplierPayment', total: number, paymentDate: any, paymentMethod: Types.Enum_Supplierpayment_Paymentmethod, paymentDirection: Types.Enum_Supplierpayment_Paymentdirection } | null } | null } | null } | null }> } | null };


export const GetInvoicePaymentDetailsDocument = gql`
    query getInvoicePaymentDetails($filters: SupplierPaymentDetailFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  supplierPaymentDetails(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        amount
        paymentType
        supplier_return {
          data {
            id
            attributes {
              total
              dateReturned
            }
          }
        }
        supplier_payment {
          data {
            id
            attributes {
              total
              paymentDate
              paymentMethod
              paymentDirection
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetInvoicePaymentDetailsQuery__
 *
 * To run a query within a React component, call `useGetInvoicePaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicePaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicePaymentDetailsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetInvoicePaymentDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicePaymentDetailsQuery, GetInvoicePaymentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicePaymentDetailsQuery, GetInvoicePaymentDetailsQueryVariables>(GetInvoicePaymentDetailsDocument, options);
      }
export function useGetInvoicePaymentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicePaymentDetailsQuery, GetInvoicePaymentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicePaymentDetailsQuery, GetInvoicePaymentDetailsQueryVariables>(GetInvoicePaymentDetailsDocument, options);
        }
export type GetInvoicePaymentDetailsQueryHookResult = ReturnType<typeof useGetInvoicePaymentDetailsQuery>;
export type GetInvoicePaymentDetailsLazyQueryHookResult = ReturnType<typeof useGetInvoicePaymentDetailsLazyQuery>;
export type GetInvoicePaymentDetailsQueryResult = Apollo.QueryResult<GetInvoicePaymentDetailsQuery, GetInvoicePaymentDetailsQueryVariables>;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { addDays, format } from 'date-fns';

import { PatchesName } from '../../types/types';
import { PAGE_COUNT } from '../../helpers/constants';
import { useLocalization } from '../../localization';
import { TableOrders } from './components/TableOrders';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { useGetOrders } from '../../graphql/queries/hook/useGetOrders';
import { ToolBarWithSearchAndSelect } from './components/ToolBarWithSearchAndSelect';
import { HelpSearchOrder } from '../../components/HelpSearchOrder';
import { useOrdersSearchParams } from './hooks/useOrdersSearchParams';
import { SearchInput } from '../../components/SearchInput';

export const OrderList = () => {
  const { translateLang } = useLocalization();
  const navigate = useNavigate();

  const {
    page,
    filters,
    managers,
    statuses,
    searchText,
    changeParam,
    resetFilters,
    selectedDates,
    changSearchText,
  } = useOrdersSearchParams({
    defaultDateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    defaultDateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  const handlerButtonWrapper = () => {
    navigate(`/${PatchesName.Orders}/${PatchesName.CreateOrder}`);
  };

  const { orders, pagination, loadingOrders, refetch } = useGetOrders({
    pagination: {
      page: parseInt(page),
      pageSize: PAGE_COUNT,
    },
    filters,
  });

  return (
    <WrapperPages
      isButton
      titleButton={translateLang('newOrder')}
      titleHeader={translateLang('orders')}
      handlerButton={handlerButtonWrapper}
    >
      <SearchInput
        value={searchText}
        onChangeText={changSearchText}
        placeholder={translateLang('searchOrdersAccountingLabel')}
        endAdornment={<HelpSearchOrder />}
      />

      <ToolBarWithSearchAndSelect
        result={pagination?.total || '0'}
        changeParam={changeParam}
        managers={managers}
        resetFilters={resetFilters}
        selectedDates={selectedDates}
        statuses={statuses}
      />
      <TableOrders
        refetch={refetch}
        orders={orders}
        pagination={pagination}
        changePage={page => changeParam({ page: `${page + 1}` })}
        pageCount={PAGE_COUNT}
        loadingOrders={loadingOrders}
      />
    </WrapperPages>
  );
};

import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { NoSparePartEntityFragmentDoc } from '../../fragments/__generated__/noSparePartEntity';
import { PaginationFragmentDoc } from '../../fragments/__generated__/Pagination';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetNewSparePartsQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  filters?: Types.InputMaybe<Types.NoSparePartFiltersInput>;
}>;


export type GetNewSparePartsQuery = { __typename?: 'Query', noSpareParts?: { __typename?: 'NoSparePartEntityResponseCollection', data: Array<{ __typename?: 'NoSparePartEntity', id?: string | null, attributes?: { __typename?: 'NoSparePart', phone: string, vin_code?: string | null, message?: string | null, source?: Types.Enum_Nosparepart_Source | null, status?: Types.Enum_Nosparepart_Status | null, createdAt?: any | null, updatedAt?: any | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', page: number, pageCount: number, pageSize: number, total: number } } } | null };


export const GetNewSparePartsDocument = gql`
    query getNewSpareParts($pagination: PaginationArg, $sort: [String], $filters: NoSparePartFiltersInput) {
  noSpareParts(pagination: $pagination, sort: $sort, filters: $filters) {
    data {
      ...noSparePartEntity
    }
    meta {
      ...Pagination
    }
  }
}
    ${NoSparePartEntityFragmentDoc}
${PaginationFragmentDoc}`;

/**
 * __useGetNewSparePartsQuery__
 *
 * To run a query within a React component, call `useGetNewSparePartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewSparePartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewSparePartsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetNewSparePartsQuery(baseOptions?: Apollo.QueryHookOptions<GetNewSparePartsQuery, GetNewSparePartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewSparePartsQuery, GetNewSparePartsQueryVariables>(GetNewSparePartsDocument, options);
      }
export function useGetNewSparePartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewSparePartsQuery, GetNewSparePartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewSparePartsQuery, GetNewSparePartsQueryVariables>(GetNewSparePartsDocument, options);
        }
export type GetNewSparePartsQueryHookResult = ReturnType<typeof useGetNewSparePartsQuery>;
export type GetNewSparePartsLazyQueryHookResult = ReturnType<typeof useGetNewSparePartsLazyQuery>;
export type GetNewSparePartsQueryResult = Apollo.QueryResult<GetNewSparePartsQuery, GetNewSparePartsQueryVariables>;
import React from 'react';
import { Stack } from '@mui/system';
import { addDays, format } from 'date-fns';

import { ExpectedTabTable } from './ExpectedTabTable';
import { ToolBarExpectedTab } from './ToolBarExpectedTab';
import { useLocalization } from '../../../../localization';
import { useGetOrderItemsAwaiting } from '../../../../graphql/queries/hook/useGetOrderItems';
import { useWarehouseExpectedTabSearchParams } from './hooks/useWarehouseExpectedTabSearchParams';
import { SearchInput } from '../../../../components/SearchInput';
import { HelpSearchOrder } from '../../../../components/HelpSearchOrder';

export const ExpectedTab = () => {
  const {
    filters,
    providers,
    searchText,
    changeParam,
    resetFilters,
    selectedDates,
    changSearchText,
  } = useWarehouseExpectedTabSearchParams({
    defaultDateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    defaultDateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  const { orderItems, loading, pageSize } = useGetOrderItemsAwaiting({
    filters,
  });

  const { translateLang } = useLocalization();

  return (
    <Stack width="100%" paddingBottom={10}>
      <SearchInput
        value={searchText}
        onChangeText={text => changSearchText(text)}
        placeholder={translateLang('expected_spare_search_placeholder')}
        endAdornment={<HelpSearchOrder />}
      />
      <ToolBarExpectedTab
        result={pageSize}
        changeParam={changeParam}
        resetFilters={resetFilters}
        selectedDates={selectedDates}
        providers={providers}
      />
      <ExpectedTabTable orderItems={orderItems} loading={loading} />
    </Stack>
  );
};

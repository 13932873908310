import React, { useState } from 'react';
import { useGetWarehouse } from '../../../../graphql/queries/hook/useGetWarehouse';
import { Checkbox, Stack } from '@mui/material';
import { WarhouseOrderTabTable } from './components/WarhouseOrderTabTable';
import { useLocalization } from '../../../../localization';
import { useWarehouseWarehouseTabSearchParams } from './hooks/useWarehouseWarehouseTabSearchParams';
import { PAGE_COUNT } from '../../../../helpers/constants';
import { SearchInput } from '../../../../components/SearchInput';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import { updateSortList } from '../../../../utils/sortUtils';

export const WarehouseTab = () => {
  const { translateLang } = useLocalization();
  const { changSearchText, filters, page, searchText, changeParam, showZeroBalance, sort } =
    useWarehouseWarehouseTabSearchParams();

  const { itemsWarehouse, loading, totalPage } = useGetWarehouse({
    filters,
    page: parseInt(page),
    pageSize: PAGE_COUNT,
    sort,
  });

  const sortHandler = (field: string) => {
    const updatedSortList = updateSortList(sort, field);
    changeParam({ sort: updatedSortList });
  };

  return (
    <Stack width="100%">
      <SearchInput
        value={searchText}
        onChangeText={text => changSearchText(text)}
        placeholder={translateLang('searchByPartName')}
      />
      <WrapperToolBar justifyContent="flex-end">
        <Stack flexDirection="row" alignItems="center">
          <TranslatedField
            originText={'showZeroBalance'}
            fontSize={16}
            isTranslate
            noWrap
            overflow="initial"
          />
          <Checkbox
            checked={showZeroBalance}
            onChange={(e, checked) => {
              changeParam({ showZeroBalance: checked ? 'true' : '' });
            }}
          />
        </Stack>
      </WrapperToolBar>
      <WarhouseOrderTabTable
        itemsWarehouse={itemsWarehouse}
        totalPage={totalPage}
        changePage={page => changeParam({ page: `${page + 1}` })}
        currentPage={parseInt(page)}
        loading={loading}
        sort={sort}
        sortHandler={sortHandler}
      />
    </Stack>
  );
};

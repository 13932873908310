import React, { Fragment, forwardRef, useMemo } from 'react';
import { addDays } from 'date-fns';
import { theme } from '../../../../helpers';
import { invoiceFooterData } from './helpers';
import logo from './../../../../assets/logotype.svg';
import { IBAN } from '../../../../helpers/constants';
import { CustomAvatar } from '../../../../components';
import { roundAmountToString } from '../../../../utils/currencyUtils';
import { formatDate } from '../../../../helpers/functions';

interface IbillInfo {
  companyName: string;
  VATId: string;
  street: string;
  house: string;
  city: string;
  zipCode: string;
  email: string;
}

interface InvoicePrintProps {
  invoicePaid: boolean;
  invoicePaidComment: string;
  billInfo?: IbillInfo;
  billNumber: string;
  date: Date | null;
  total: number;
  ordersInvoice?: {
    orderNumber?: string | null;
    data?: {
      title?: string | null;
      count: number;
      price?: number | null;
      brandName?: string | null;
      article?: string | null;
    }[];
  }[];
}

export const InvoicePrint = forwardRef<HTMLDivElement, InvoicePrintProps>((props, ref) => {
  const { billInfo, ordersInvoice, invoicePaid, invoicePaidComment, billNumber, total, date } =
    props;

  const ordersInvoiceFiltered = useMemo(
    () =>
      ordersInvoice?.map(item => ({
        ...item,
        data: item.data?.filter(itemData => itemData.count > 0),
      })),
    [ordersInvoice]
  );
  return (
    <div
      ref={ref}
      style={{
        width: 595,
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '40px',
          paddingBottom: '50px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '3px solid #F44336',
            marginBottom: '30px',
          }}
        >
          <h4
            style={{
              margin: 0,
              fontFamily: 'Mulish,sans-serif',
              fontSize: '24px',
              textTransform: 'uppercase',
              color: '#979797',
              fontWeight: 500,
            }}
          >
            Rechnung
          </h4>
          <CustomAvatar
            alt="Remy Sharp"
            logo={logo}
            variant="rounded"
            sx={{
              width: '130px',
              height: '100%',
              '& img': {
                objectFit: 'contain',
              },
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '40px',
          }}
        >
          <p
            style={{
              margin: 0,
              fontFamily: 'Mulish,sans-serif',
              fontSize: '12px',
              fontWeight: 700,
              lineHeight: 1.5,
            }}
          >
            <div style={{ whiteSpace: 'pre-line' }}>{billInfo?.email}</div>
            <br />
            {billInfo?.companyName ?? 'test'} <br />
            {billInfo?.street} {billInfo?.house} <br />
            {billInfo?.zipCode} {billInfo?.city} <br />
            {billInfo?.VATId}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontFamily: 'Mulish,sans-serif',
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: 1.5,
                }}
              >
                Rechnung Nr:
              </p>
              <p
                style={{
                  margin: 0,
                  fontFamily: 'Mulish,sans-serif',
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: 1.5,
                }}
              >
                {billNumber}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontFamily: 'Mulish,sans-serif',
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: 1.5,
                }}
              >
                Rechnungsdatum:
              </p>
              <p
                style={{
                  margin: 0,
                  fontFamily: 'Mulish,sans-serif',
                  fontSize: '12px',
                  fontWeight: 700,
                  lineHeight: 1.5,
                }}
              >
                {formatDate(date ?? new Date())}
              </p>
            </div>
          </div>
        </div>

        <p
          style={{
            margin: 0,
            fontFamily: 'Mulish,sans-serif',
            fontSize: '12px',
            fontWeight: 700,
            textTransform: 'uppercase',
            lineHeight: 1.5,
            marginBottom: '20px',
          }}
        >
          {`Rechnung ${billNumber}`}
        </p>
        <div
          style={{
            width: '100%',
            overflowX: 'auto',
            marginBottom: '40px',
          }}
        >
          <table
            style={{
              display: 'table',
              width: '100%',
              borderCollapse: 'collapse',
              borderSpacing: 0,
            }}
          >
            <thead
              style={{
                display: 'table-header-group',
                backgroundColor: '#F44336',
              }}
            >
              <tr
                style={{
                  color: 'inherit',
                  display: 'table-row',
                  verticalAlign: 'middle',
                  outline: 0,
                }}
              >
                <td
                  style={{
                    padding: '4px',
                    fontSize: '12px',
                    color: theme.palette.common.white,
                    fontWeight: 700,
                    fontFamily: 'Mulish,sans-serif',
                    lineHeight: 1.43,
                    display: 'table-cell',
                  }}
                >
                  Pos. Beschreibung{' '}
                </td>
                <td
                  style={{
                    padding: '4px',
                    fontSize: '12px',
                    color: theme.palette.common.white,
                    fontWeight: 700,
                    fontFamily: 'Mulish,sans-serif',
                    lineHeight: 1.43,
                    display: 'table-cell',
                    textAlign: 'center',
                  }}
                >
                  Menge
                </td>
                <td
                  style={{
                    padding: '4px',
                    fontSize: '12px',
                    color: theme.palette.common.white,
                    fontWeight: 700,
                    fontFamily: 'Mulish,sans-serif',
                    lineHeight: 1.43,
                    display: 'table-cell',
                    textAlign: 'center',
                  }}
                >
                  Einzelpreis EUR
                </td>
                <td
                  style={{
                    padding: '4px',
                    fontSize: '12px',
                    color: theme.palette.common.white,
                    fontWeight: 700,
                    fontFamily: 'Mulish,sans-serif',
                    lineHeight: 1.43,
                    display: 'table-cell',
                    textAlign: 'center',
                  }}
                >
                  Betrag EUR
                </td>
              </tr>
            </thead>
            <tbody style={{ display: 'table-row-group' }}>
              {ordersInvoiceFiltered?.map((row, index) => (
                <Fragment key={`${row.orderNumber}-${index}`}>
                  {row.orderNumber ? (
                    <>
                      <tr
                        style={{
                          color: 'inherit',
                          display: 'table-row',
                          verticalAlign: 'middle',
                          outline: 0,
                        }}
                      >
                        <td
                          style={{
                            fontFamily: 'Mulish,sans-serif',
                            display: 'table-cell',
                            verticalAlign: 'inherit',
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                            textAlign: 'left',
                            fontWeight: 700,
                            fontSize: '12px',
                            padding: '25px 10px 0 10px',
                            border: '0px solid',
                          }}
                        >
                          Lieferschein {row.orderNumber}
                        </td>
                      </tr>
                      {row.data?.map((rowData, indexData) => (
                        <tr
                          key={`${rowData.title}-${indexData}`}
                          style={{
                            color: 'inherit',
                            display: 'table-row',
                            verticalAlign: 'middle',
                            outline: 0,
                            borderBottom:
                              ordersInvoiceFiltered.length - 1 === index &&
                              (row.data?.length || 0) - 1 === indexData
                                ? '2px solid #F44336'
                                : 'none',
                          }}
                        >
                          <td
                            style={{
                              lineHeight: '8px',
                              fontFamily: 'Mulish,sans-serif',
                              borderBottom: '1px solid rgba(224, 224, 224, 1)',
                              textAlign: 'left',
                              fontWeight: 400,
                              fontSize: '10px',
                              border: '0px solid',
                              padding: 0,
                              paddingBottom:
                                ordersInvoiceFiltered.length - 1 === index &&
                                (row.data?.length || 0) - 1 === indexData
                                  ? '30px'
                                  : 0,
                              paddingLeft: '30px',
                            }}
                          >
                            <br />
                            {`${indexData + 1}. ${rowData.article} | ${rowData.brandName} | 
                            ${rowData.title}
                            `}
                          </td>
                          <td
                            style={{
                              lineHeight: '8px',
                              fontFamily: 'Mulish,sans-serif',
                              borderBottom: '1px solid rgba(224, 224, 224, 1)',
                              textAlign: 'center',
                              fontWeight: 400,
                              fontSize: '10px',
                              border: '0px solid',
                              display: 'table-cell',
                              padding: 0,
                              paddingBottom:
                                ordersInvoiceFiltered.length - 1 === index &&
                                (row.data?.length || 0) - 1 === indexData
                                  ? '30px'
                                  : 0,
                            }}
                          >
                            {rowData.count}
                          </td>
                          <td
                            style={{
                              lineHeight: '8px',
                              fontFamily: 'Mulish,sans-serif',
                              borderBottom: '1px solid rgba(224, 224, 224, 1)',
                              textAlign: 'center',
                              fontWeight: 400,
                              fontSize: '10px',
                              border: '0px solid',
                              display: 'table-cell',
                              padding: 0,
                              paddingBottom:
                                ordersInvoiceFiltered.length - 1 === index &&
                                (row.data?.length || 0) - 1 === indexData
                                  ? '30px'
                                  : 0,
                            }}
                          >
                            {rowData.price}
                          </td>
                          <td
                            style={{
                              lineHeight: '8px',
                              fontFamily: 'Mulish,sans-serif',
                              borderBottom: '1px solid rgba(224, 224, 224, 1)',
                              textAlign: 'center',
                              fontWeight: 400,
                              fontSize: '10px',
                              border: '0px solid',
                              display: 'table-cell',
                              padding: 0,
                              paddingBottom:
                                ordersInvoiceFiltered.length - 1 === index &&
                                (row.data?.length || 0) - 1 === indexData
                                  ? '30px'
                                  : 0,
                            }}
                          >
                            {roundAmountToString((rowData?.price || 0) * rowData.count)}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : null}
                </Fragment>
              ))}
              <tr
                style={{
                  color: 'inherit',
                  display: 'table-row',
                  verticalAlign: 'middle',
                  outline: 0,
                  borderBottom: '2px solid #F44336',
                }}
              >
                <td
                  rowSpan={3}
                  style={{
                    fontFamily: 'Mulish,sans-serif',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    padding: 0,
                    border: 0,
                  }}
                />
                <td
                  colSpan={2}
                  style={{
                    fontFamily: 'Mulish,sans-serif',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    padding: '4px',
                    paddingLeft: '20px',
                    border: 0,
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  Netto
                </td>
                <td
                  style={{
                    fontFamily: 'Mulish,sans-serif',
                    textAlign: 'right',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    padding: '4px',
                    paddingRight: '30px',
                    border: 0,
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  {(total - (total * 0.2) / 1.2).toFixed(2)}
                </td>
              </tr>
              <tr
                style={{
                  color: 'inherit',
                  display: 'table-row',
                  verticalAlign: 'middle',
                  outline: 0,
                  borderBottom: '2px solid #F44336',
                }}
              >
                <td
                  colSpan={2}
                  style={{
                    fontFamily: 'Mulish,sans-serif',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    padding: '4px',
                    paddingLeft: '20px',
                    border: 0,
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  zzgl. USt. 20%
                </td>
                <td
                  style={{
                    fontFamily: 'Mulish,sans-serif',
                    textAlign: 'right',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    padding: '4px',
                    paddingRight: '30px',
                    border: 0,
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  {((total * 0.2) / 1.2).toFixed(2)}
                </td>
              </tr>
              <tr
                style={{
                  color: 'inherit',
                  display: 'table-row',
                  verticalAlign: 'middle',
                  outline: 0,
                  backgroundColor: '#F44336',
                }}
              >
                <td
                  colSpan={2}
                  style={{
                    fontFamily: 'Mulish,sans-serif',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    padding: '4px',
                    paddingLeft: '20px',
                    border: 0,
                    fontSize: 12,
                    fontWeight: 700,
                    color: theme.palette.common.white,
                  }}
                >
                  Gesamtsumme EUR
                </td>
                <td
                  style={{
                    fontFamily: 'Mulish,sans-serif',
                    textAlign: 'right',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    padding: '4px',
                    paddingRight: '30px',
                    border: 0,
                    fontSize: 12,
                    fontWeight: 700,
                    color: theme.palette.common.white,
                  }}
                >
                  {total.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {!invoicePaid ? (
          <p
            style={{
              margin: 0,
              fontFamily: 'Mulish,sans-serif',
              fontSize: '10px',
              fontWeight: 400,
              lineHeight: 1.5,
              textAlign: 'center',
            }}
          >
            <span style={{ fontWeight: 700 }}> Zahlungsbedingungen:</span> Bitte überweisen Sie den
            zu zahlenden Betrag bis{' '}
            <span style={{ fontWeight: 700 }}>{`${formatDate(
              addDays(new Date(date ?? new Date()), 14)
            )}`}</span>{' '}
            unter Angabe der Zahlungsreferenz{' '}
            <span style={{ fontWeight: 700 }}>{`(${billNumber})`}</span> auf das Konto
            <span style={{ fontWeight: 700 }}> {`IBAN ${IBAN}.`}</span>
          </p>
        ) : (
          invoicePaidComment.split('\n').map((line, index) => (
            <p
              key={index}
              style={{
                margin: 0,
                fontFamily: 'Mulish,sans-serif',
                fontSize: '10px',
                fontWeight: 400,
                lineHeight: 1.5,
                textAlign: 'start',
              }}
            >
              {line}
            </p>
          ))
        )}
        <div
          style={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '40px',
          }}
        >
          <div
            className="red-line"
            style={{
              position: 'absolute',
              top: '14px',
              height: '4px',
              width: '100%',
              backgroundColor: '#f44336',
              zIndex: 1,
            }}
          ></div>
          {invoiceFooterData.map(({ id, icon: Icon, text }) => (
            <div
              key={id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '12px',
                zIndex: 10,
              }}
            >
              <div
                style={{
                  width: '25px',
                  height: '25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#fff',
                  padding: '4px',
                  border: '1px solid #000',
                  borderRadius: '100%',
                }}
              >
                <Icon />
              </div>
              {text.map((text, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '8px',
                    fontSize: '10px',
                  }}
                >
                  {text}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

InvoicePrint.displayName = 'InvoicePrint';

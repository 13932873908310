import React, { FC, useMemo } from 'react';
import { formatISO } from 'date-fns';
import { Button, Stack } from '@mui/material';

import { useLocalization } from '../../../../localization';
import { useDefaultRangeCalendar } from '../../../../hooks';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { useGetSuppliersQuery } from '../../../../graphql/queries/__generated__/getSuppliers';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { WarehouseReturnFromDeliveryTabSearchParams } from './hooks/useWarehouseReturnFromDeliveryTabSearchParams';
import { MultipleSelectFilter } from '../../../OrdersList/components/MultipleSelectFilter';

interface Props {
  result?: string | number;
  changeParam: (
    params: Partial<Record<keyof WarehouseReturnFromDeliveryTabSearchParams, string | string[]>>
  ) => void;
  selectedDates: Date[];
  resetFilters: () => void;
  providers: string[];
}

export type NoSpareStatusType = {
  label: string;
  value: string;
};

export const ToolBarReturnTab: FC<Props> = ({
  result,
  changeParam,
  providers,
  resetFilters,
  selectedDates,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();

  const { data: dataSuppliers } = useGetSuppliersQuery({
    variables: {
      filters: { or: [{ blocked: { eq: false } }, { blocked: { eq: null } }] },
      pagination: {
        limit: -1,
      },
    },
  });

  const providersOptions = useMemo(() => {
    return (
      dataSuppliers?.suppliers?.data?.map(item => {
        return {
          value: item?.id || '',
          label: item.attributes?.name || `#${item?.id}`,
        };
      }) ?? []
    );
  }, [dataSuppliers?.suppliers?.data]);

  return (
    <WrapperToolBar>
      <Stack width="20%" flexDirection="row" justifyContent="left">
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || ''}
        />
      </Stack>
      <Stack spacing={2} bgcolor="white" flexDirection="row" justifyContent="right">
        <Stack direction="row" gap={2} alignItems="center">
          <MultipleSelectFilter
            labelFilter={translateLang('provider')}
            items={providersOptions}
            selectedItems={providers}
            changeUrlParams={selectedProviders => changeParam({ providers: selectedProviders })}
          />
          <RangeCalendar
            selectedDates={selectedDates}
            defaultRangeDates={reportRangeDates}
            setSelectedDates={dateRange => {
              changeParam({
                dateTo: formatISO(dateRange[1]),
                dateFrom: formatISO(dateRange[0]),
              });
            }}
          />
          <Button
            color="primary"
            variant="outlined"
            onClick={() => resetFilters()}
            style={{
              width: '120px',
              height: '40px',
              textTransform: 'none',
            }}
          >
            <TranslatedField
              noWrap
              isTranslate
              fontSize={16}
              color="black"
              originText="resetFilters"
            />
          </Button>
        </Stack>
      </Stack>
    </WrapperToolBar>
  );
};

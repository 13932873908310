import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { PaginationFragmentDoc } from '../../fragments/__generated__/Pagination';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetContactNotificationsQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationArg>;
  sort?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  filters?: Types.InputMaybe<Types.ContactNotificationFiltersInput>;
}>;


export type GetContactNotificationsQuery = { __typename?: 'Query', contactNotifications?: { __typename?: 'ContactNotificationEntityResponseCollection', data: Array<{ __typename?: 'ContactNotificationEntity', id?: string | null, attributes?: { __typename?: 'ContactNotification', name: string, phoneNumber: string, VINcode?: string | null, message?: string | null, status?: Types.Enum_Contactnotification_Status | null, createdAt?: any | null, updatedAt?: any | null, locale?: string | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', page: number, pageCount: number, pageSize: number, total: number } } } | null };


export const GetContactNotificationsDocument = gql`
    query getContactNotifications($pagination: PaginationArg, $sort: [String], $filters: ContactNotificationFiltersInput) {
  contactNotifications(pagination: $pagination, sort: $sort, filters: $filters) {
    data {
      id
      attributes {
        name
        phoneNumber
        VINcode
        message
        status
        createdAt
        updatedAt
        locale
      }
    }
    meta {
      ...Pagination
    }
  }
}
    ${PaginationFragmentDoc}`;

/**
 * __useGetContactNotificationsQuery__
 *
 * To run a query within a React component, call `useGetContactNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactNotificationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetContactNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactNotificationsQuery, GetContactNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactNotificationsQuery, GetContactNotificationsQueryVariables>(GetContactNotificationsDocument, options);
      }
export function useGetContactNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactNotificationsQuery, GetContactNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactNotificationsQuery, GetContactNotificationsQueryVariables>(GetContactNotificationsDocument, options);
        }
export type GetContactNotificationsQueryHookResult = ReturnType<typeof useGetContactNotificationsQuery>;
export type GetContactNotificationsLazyQueryHookResult = ReturnType<typeof useGetContactNotificationsLazyQuery>;
export type GetContactNotificationsQueryResult = Apollo.QueryResult<GetContactNotificationsQuery, GetContactNotificationsQueryVariables>;
import React, { FC, useState } from 'react';
import { Grid, Button, Box, CircularProgress, Stack } from '@mui/material';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { Title } from '../../../components/Wrapper/Title';
import { useGetClientById } from '../../../graphql/queries/hook/useGetClientById';
import { useParams } from 'react-router-dom';
import { ItemVehicle } from '../../ClientInfo/components/ItemVehicle';
import { AddVehicleModal } from '../../ClientInfo/components/AddVehicleModal';
import { UrlParamsObjectType } from '../../../types/types';
import { VehiclesType } from '../../../helpers/types';
import { useLocalization } from '../../../localization';
import { SearchInput } from '../../../components/SearchInput';
interface Props {
  children?: JSX.Element[] | JSX.Element;
  isStepper?: boolean;
}
export const VehicleList: FC<Props> = ({ children, isStepper = false }) => {
  const { idUser = '' } = useParams<UrlParamsObjectType>();
  const { vehicles: vehiclesData, loading } = useGetClientById(idUser);
  const [searchText, setSearchText] = useState('');
  const [vehicles, setVehicles] = useState<VehiclesType[] | undefined>([]);
  const [vinCodeState, setVinCodeState] = useState('');
  const { translateLang } = useLocalization();
  const [openModal, setOpenModal] = useState<{ isOpen: boolean; vehicle?: VehiclesType | null }>({
    isOpen: false,
    vehicle: null,
  });

  const changeSearchText = (text: string | undefined) => {
    if (text) {
      setSearchText(text);
      const filteredVehicles = vehiclesData?.filter(vehicle =>
        vehicle?.vinCode?.toLocaleLowerCase()?.includes(text.toLocaleLowerCase())
      );
      if ((filteredVehicles?.length ?? 0) >= 1) {
        setVehicles(filteredVehicles);
      } else {
        setVehicles([]);
      }
    } else {
      setSearchText('');
      setVehicles(vehiclesData);
    }
  };

  return (
    <Box pb={2} px={2} ml={1} pt={2} mr={1} bgcolor="white" borderRadius={2}>
      <Stack flexDirection="row" gap={3} alignItems="center">
        <Title textColor="black" titleHeader="vehicle" />
        {loading && <CircularProgress size={30} color="secondary" sx={{ mb: 1.5 }} />}
      </Stack>
      <Grid item lg={12} container spacing={2} mb={6}>
        <Grid item xs={7} gap={10} display="flex">
          <Button
            onClick={() => setOpenModal({ isOpen: true, vehicle: null })}
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              width: '60%',
              height: '100%',
              textTransform: 'none',
            }}
          >
            <TranslatedField noWrap isTranslate fontSize={16} color="black" originText="add_auto" />
          </Button>
          <SearchInput
            onChangeText={changeSearchText}
            value={''}
            placeholder={translateLang('SearchByVincode')}
          />
        </Grid>
      </Grid>
      <Grid item xs={9} textAlign="end">
        {children}
      </Grid>

      {searchText
        ? vehicles?.map((item, index) => (
            <ItemVehicle
              setVinCodeState={setVinCodeState}
              vinCodeState={vinCodeState}
              isStepper={isStepper}
              onClickItem={() => {
                setOpenModal({ isOpen: true, vehicle: item });
              }}
              key={item?.id || index.toString()}
              vehicle={item}
              isLastElement={vehicles.length - 1 === index}
            />
          ))
        : vehiclesData?.map((item, index) => (
            <ItemVehicle
              setVinCodeState={setVinCodeState}
              vinCodeState={vinCodeState}
              isStepper={isStepper}
              onClickItem={() => {
                setOpenModal({ isOpen: true, vehicle: item });
              }}
              key={item?.id || index.toString()}
              vehicle={item}
              isLastElement={vehiclesData.length - 1 === index}
            />
          ))}

      {openModal.isOpen && (
        <AddVehicleModal
          currentVehicle={openModal?.vehicle}
          customerId={idUser}
          open={true}
          handleClose={() => setOpenModal({ isOpen: false, vehicle: null })}
        />
      )}
    </Box>
  );
};

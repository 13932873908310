import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { CustomModal } from '../../../../components';
import { useLocalization } from '../../../../localization';
import { OrderItemType } from '../../../../helpers/types';
import { useUpdateOrderItemMutation } from '../../../../graphql/mutations/__generated__/updateOrderItem';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Typography,
  TableHead,
} from '@mui/material';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { Input } from '../../../../legos';
import { StyledTableCell } from '../../../../components/TableProduct/TableCellProduct';
import { formatDate } from '../../../../helpers/functions';

type Props = {
  open: boolean;
  handleClose: () => void;
  item: OrderItemType;
};

export const QuantityModal = ({ open, handleClose, item }: Props) => {
  const { translateLang } = useLocalization();
  const [runUpdateOrderItem, { loading }] = useUpdateOrderItemMutation();
  const { addNotification } = useHandlerNotificationApp();

  const validationSchema = yup.object({
    quantity: yup
      .number()
      .required(translateLang('emptyInputError'))
      .test(
        'is-less-than-balance-quantity',
        translateLang('QuantityCanNotBeLess'),
        function (value) {
          const { balanceQuantity } = this.parent;
          return (value ?? 0) >= balanceQuantity;
        }
      ),
  });

  const { handleSubmit, values, handleChange, errors } = useFormik({
    initialValues: {
      quantity: item?.quantity ?? 0,
      balanceQuantity: item?.balanceQuantity ?? 0,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ quantity }) => {
      try {
        const response = await runUpdateOrderItem({
          variables: {
            id: item?.id ?? '',
            data: {
              quantity,
            },
          },
        });
        if (response.data) {
          handleClose();
          addNotification({
            messageError: 'QuantityUpdatedSuccessfully',
            typeMessage: 'success',
          });
        }
      } catch (error: any) {
        addNotification({
          messageError: error?.message || 'Something went wrong',
          typeMessage: 'error',
        });
      }
    },
  });

  const tableHeader = [
    {
      title: translateLang('#'),
      containerProps: { width: '70px', color: '#F1F3F8' },
    },
    {
      title: translateLang('orderDate'),
      containerProps: { width: '70px', color: '#F1F3F8' },
    },
    {
      title: translateLang('customer'),
      containerProps: { width: '70px', color: '#F1F3F8' },
    },
    {
      title: translateLang('productName'),
      containerProps: { width: '70px', color: '#F1F3F8' },
    },
    {
      title: translateLang('manufacturer'),
      containerProps: { width: '70px', color: '#F1F3F8' },
    },
    {
      title: translateLang('article'),
      containerProps: { width: '70px', color: '#F1F3F8' },
    },

    {
      title: translateLang('quantityReceived'),
      containerProps: { width: '70px', color: '#F1F3F8', align: 'flex-end' },
      textAlign: 'right',
    },
    {
      title: '',
      containerProps: { width: '1px', color: '#F1F3F8' },
      minWidth: 1,
      paddingX: '1px',
    },
    {
      title: translateLang('quantity'),
      containerProps: { width: '70px', color: '#F1F3F8' },
      textAlign: 'left',
    },
  ];

  return (
    <>
      <CustomModal title={translateLang('UpdateQuantity')} open={open} handleClose={handleClose}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {tableHeader.map((item, index) => (
                  <StyledTableCell
                    key={`${item.title}${index}`}
                    sx={{
                      minWidth: item.minWidth ?? '50px',
                      textAlign: item.textAlign ?? 'center',
                      ...(item.paddingX ? { paddingX: item.paddingX } : null),
                    }}
                  >
                    {item.title}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={item?.id}>
                <TableCell align="center">{'#' + item.orderNumber}</TableCell>
                <TableCell>{formatDate(item?.orderDate ?? '')}</TableCell>
                <TableCell>{item?.fullName}</TableCell>
                <TableCell align="center">{item?.productName}</TableCell>
                <TableCell align="center">{item?.brandName}</TableCell>
                <TableCell align="center">{item?.article}</TableCell>
                <TableCell align="right" style={{ fontSize: '16px' }}>
                  {item?.balanceQuantity ?? 0}
                </TableCell>
                <TableCell
                  align="center"
                  size="small"
                  style={{ fontSize: '16px', minWidth: 1, width: '1px', padding: 0 }}
                >
                  /
                </TableCell>
                <TableCell align="left" style={{ fontSize: '16px' }}>
                  <Box minWidth={100}>
                    <Input
                      type="number"
                      value={values.quantity}
                      name="quantity"
                      onChange={handleChange}
                      error={!!errors.quantity}
                      variant="outlined"
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" py={1} justifyContent="flex-end" height={40}>
          {!!errors.quantity && <Typography color="error">{errors.quantity}</Typography>}
        </Box>

        <Box mt="30px" display="flex" justifyContent="center">
          <Button
            onClick={() => {
              handleSubmit();
            }}
            disabled={loading}
            variant="contained"
            color="secondary"
            style={{
              width: '40%',
              height: '40px',
              borderRadius: '10px',
            }}
          >
            {translateLang('save')}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

import React, { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, BoxProps, IconButton, Stack, Button, Breakpoint } from '@mui/material';

import { Icon } from '../legos';
import { TranslatedField } from './Layout/components/TranslatedField/TranslatedField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface Props extends BoxProps {
  title: string;
  open: boolean;
  bgcolor?: string;
  fullWidth?: boolean;
  maxWidth?: Breakpoint | undefined;
  handleClose: () => void;
  handleComeBack?: () => void;
  children?: JSX.Element[] | JSX.Element;
}

const style = {
  p: 4,

  boxShadow: 24,
  borderRadius: 2,
};

export const CustomModal: FC<Props> = ({
  open,
  title,
  children,
  fullWidth,
  maxWidth = 'xl',
  handleClose,
  handleComeBack,
  bgcolor = 'background.paper',
  ...props
}) => {
  return (
    <React.Fragment>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        fullWidth={fullWidth}
        onClose={() => handleClose()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <Stack
            flexGrow={1}
            direction="row"
            marginBottom={1}
            alignContent="center"
            justifyContent="space-between"
          >
            {handleComeBack && (
              <IconButton onClick={handleComeBack}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Box>
              <TranslatedField
                isTranslate
                variant="h5"
                color="black"
                marginTop="3px"
                originText={title}
              />
            </Box>
            <IconButton onClick={() => handleClose()}>
              <Icon icon="close" color="inherit" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ maxWidth: '100%', p: '0' }}>
          <Box bgcolor={bgcolor} sx={{ ...style }} {...props}>
            {children}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

import React, { FC, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { format } from 'date-fns';
import { useLocalization } from '../../../localization';
import { useDefaultRangeCalendar } from '../../../hooks';
import { Enum_Order_Status } from '../../../__generated__/types';
import { WrapperToolBar } from '../../../components/WrapperToolBar';
import { MultipleItemSelectFilterType, MultipleSelectFilter } from './MultipleSelectFilter';
import { RangeCalendar } from '../../../legos/rangeCalendar/RangeCalendar';
import { useGetManagers } from '../../../graphql/queries/hook/useGetManagers';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { OrdersSearchParams } from '../hooks/useOrdersSearchParams';

interface Props {
  result?: string | number;
  changeParam: (params: Partial<Record<keyof OrdersSearchParams, string | string[]>>) => void;
  managers: string[];
  statuses: string[];
  selectedDates: Date[];
  resetFilters: () => void;
}

export const ToolBarWithSearchAndSelect: FC<Props> = ({
  result,
  changeParam,
  managers,
  resetFilters,
  selectedDates,
  statuses,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();
  const { clients } = useGetManagers();

  const statusesOptions = useMemo(
    (): MultipleItemSelectFilterType[] =>
      [
        Enum_Order_Status.Created,
        Enum_Order_Status.Editing,
        Enum_Order_Status.InStock,
        Enum_Order_Status.Awaiting,
        Enum_Order_Status.Delivery,
        Enum_Order_Status.Completed,
        Enum_Order_Status.Coordination,
      ].map(value => {
        return {
          label: translateLang(value),
          value,
        };
      }),
    [translateLang]
  );

  const getItems = () => {
    if (clients) {
      return [
        ...clients.map((i, index) => {
          return {
            value: i?.id || index.toString(),
            label: `${i?.firstName || ''} ${i.lastName || ''} `,
          };
        }),
      ];
    }
    return [];
  };

  return (
    <WrapperToolBar>
      <Stack width="20%">
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || '0'}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        {clients.length > 0 && (
          <MultipleSelectFilter
            labelFilter={translateLang('manager')}
            items={getItems()}
            selectedItems={managers}
            changeUrlParams={selectedManagers => changeParam({ managers: selectedManagers })}
          />
        )}
        <MultipleSelectFilter
          labelFilter={translateLang('status')}
          items={statusesOptions}
          selectedItems={statuses}
          changeUrlParams={selectedStatuses => changeParam({ statuses: selectedStatuses })}
        />
        <RangeCalendar
          selectedDates={selectedDates}
          defaultRangeDates={reportRangeDates}
          setSelectedDates={dateRange => {
            changeParam({
              dateTo: format(dateRange[1], 'yyyy-MM-dd'),
              dateFrom: format(dateRange[0], 'yyyy-MM-dd'),
            });
          }}
        />
        <Button
          color="primary"
          variant="outlined"
          onClick={resetFilters}
          style={{
            width: '100%',
            textTransform: 'none',
            maxHeight: '40px',
            maxWidth: '120px',
          }}
        >
          <TranslatedField
            noWrap
            isTranslate
            fontSize={16}
            color="black"
            originText="resetFilters"
          />
        </Button>
      </Stack>
    </WrapperToolBar>
  );
};

import React, { useState } from 'react';
import { Avatar, Box, Button, Stack, Switch, Typography } from '@mui/material';
import { SearchField } from '../../../../components/SearchField';
import { GeneralSparesEntity, GeneralSparesFiltersInput } from '../../../../__generated__/types';
import { useLocalization } from '../../../../localization';
import { useGetGeneralSparesQuery } from '../../../../graphql/queries/__generated__/getGeneralSpares';
import logo from '../../../../assets/logotype.svg';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../../../components/TableProduct/TableProduct';
import { DiscountOutlined } from '@mui/icons-material';
import { DiscountModal } from '../Modal/DiscountModal';
import { CustomAvatar } from '../../../../components/CustomAvatar';

interface DataModalType {
  isOpen: boolean;
  spareItem?: GeneralSparesEntity | null;
}
export const ProductDiscount = () => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [filters, setFilters] = useState<GeneralSparesFiltersInput | undefined>();
  const [discountModal, setDiscountModal] = useState<DataModalType>({
    isOpen: false,
    spareItem: null,
  });
  const [checked, setChecked] = useState(false);
  const { data, loading } = useGetGeneralSparesQuery({
    variables: {
      locale: updateSelectLanguage,
      pagination: { limit: 10 },
      filters: {
        isAvailable: true,
        hasDiscount: checked ? true : undefined,
        searchText: checked ? undefined : filters?.searchText,
      },
    },
  });

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      { title: translateLang('photo'), containerProps: { width: '66px' } },
      { title: translateLang('article'), containerProps: { width: '90px' } },
      { title: translateLang('productName') },
      { title: translateLang(''), containerProps: { width: '200px' } },
    ];
  };
  const getRowsTable = (): Array<TableCellsValue> => {
    const cellsValues: Array<TableCellsValue> = [];
    if (data?.generalSpares?.data?.length) {
      data.generalSpares.data.forEach(spare => {
        const values: Array<BoxCell> = [];
        const img = spare.attributes?.images;
        const url = img && img.length ? img[0].imageURL200 : '';
        values.push({
          val: (
            <CustomAvatar
              alt="Remy Sharp"
              src={url}
              logo={logo}
              variant="rounded"
              sx={{
                width: '100%',
                height: '100%',
                '& img': {
                  objectFit: 'contain',
                },
              }}
            />
          ),
          containerProps: {
            borderLeft: 'none',
          },
        });
        values.push({ val: spare.attributes?.tecdoc_articleNumber });
        values.push({
          val: `${spare.attributes?.title}  ( ${spare.attributes?.brandName} )`,
          containerProps: { justifyContent: 'flex-start' },
        });
        values.push({
          val: (
            <Button
              onClick={() => {
                setDiscountModal({
                  isOpen: true,
                  spareItem: spare,
                });
              }}
              style={{
                color: '#97A408',
                borderColor: '#97A408',
                textTransform: 'none',
                marginRight: '15px',
              }}
              variant="outlined"
              // disabled={!item?.amount}
              startIcon={<DiscountOutlined />}
            >
              {translateLang('addDiscount')}
            </Button>
          ),
          containerProps: { borderLeft: 'none', justifyContent: 'flex-end' },
        });

        cellsValues.push({ cellsValues: values });
      });
    }
    return cellsValues;
  };
  //18572 11-38221-01
  const handlerSearchField = (text: string) => {
    if (text) {
      setFilters({
        searchText: text.trim(),
      });
    } else {
      setFilters(undefined);
    }
  };
  const closeModal = () => {
    setDiscountModal({ isOpen: false, spareItem: null });
  };

  return (
    <>
      <Stack spacing={2} pb={8}>
        <SearchField
          delay={1100}
          executeWithDelay={handlerSearchField}
          placeholder={translateLang('searchForParts')}
        />
        <Box display="flex" alignItems="center">
          <Switch
            checked={checked}
            onChange={() => setChecked(!checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            size="medium"
          />
          <Typography color={checked ? '#000' : 'rgba(0,0,0,0.43)'}>
            {translateLang('showDiscountedItems')}
          </Typography>
        </Box>

        <Box pt={2} boxSizing="border-box">
          <TableProduct
            itemsRows={getRowsTable()}
            itemsHeader={getHeadersTable()}
            loading={loading}
          />
        </Box>
      </Stack>
      {discountModal.isOpen && (
        <DiscountModal
          open={discountModal.isOpen}
          handleClose={closeModal}
          spareItem={discountModal.spareItem}
        />
      )}
    </>
  );
};

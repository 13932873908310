import React, { Dispatch, FC, useState } from 'react';
import { CustomerType, useGetClientById } from '../../../graphql/queries/hook/useGetClientById';
import { IconButton, Stack, FormLabel, Button, Box, Checkbox, Typography } from '@mui/material';
import { Icon } from '../../../legos';
import { useLocalization } from '../../../localization';
import { StyledTypography } from './StyledTypography';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetClientByIdDocument } from '../../../graphql/queries/__generated__/getClintById';
import { useUpdateCustomerContactInfoMutation } from '../../../graphql/mutations/__generated__/updateCustomerContactInfo';
import { ComponentClientDeliveryAddress } from '../../../__generated__/types';
import { CustomModal } from '../../../components';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { Address, formatAddress, handlerError } from '../../../helpers/functions';
import { RadioButtonIcon } from '../../../components/TimeLine/RadioButtonIcon';
import { theme } from '../../../helpers';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { RolesEnum } from '../../../types/types';

interface DeliveryAddressProps {
  legalAddress: ComponentClientDeliveryAddress | null;
  userId: string;
  onClickInput(idAddress: string): void;
  selectedDeliveryAddress?: Address | null;
  setSelectedDeliveryAddress?: Dispatch<React.SetStateAction<Address | null>>;
}
export const LegalAddressField: FC<DeliveryAddressProps> = ({
  legalAddress,
  onClickInput,
  userId,
  setSelectedDeliveryAddress,
}) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [runUpdateCustomer] = useUpdateCustomerContactInfoMutation();
  const { contactInfoId } = useGetClientById(userId);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [, setDeletedId] = useState<string>('');
  const { addNotification } = useHandlerNotificationApp();
  const { role } = useGetMe();

  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
  };
  const openModals = (value?: string) => {
    if (value) {
      setDeletedId(value);
    }
    setOpenSuccessModal(true);
  };
  const handlerDelete = async () => {
    const variables = {
      legalAddress: null,
    };
    try {
      const response = await runUpdateCustomer({
        variables: {
          data: { ...variables },
          id: contactInfoId || '',
        },
        refetchQueries: [
          {
            query: GetClientByIdDocument,
            variables: {
              id: userId || '',
              locale: updateSelectLanguage as string,
            },
          },
        ],
      });
      if (response) {
        setOpenSuccessModal(false);
        addNotification({ messageError: 'Address  updated success', typeMessage: 'success' });
      }
    } catch (err: unknown) {
      handlerError(err);
    }
  };
  return (
    <>
      <FormLabel sx={{ color: '#7A828A', my: '10px', fontSize: '14px' }}>
        {translateLang('LegalAddress')}
      </FormLabel>

      {legalAddress && (
        <Stack key={legalAddress?.id} direction="row" alignItems="flex-end">
          {setSelectedDeliveryAddress ? (
            <Box sx={{ marginBottom: 1, marginRight: 2 }}>
              <RadioButtonIcon
                statuses={{
                  isActive: !legalAddress,
                  isCompleted: !!legalAddress,
                  isSkipped: false,
                }}
                onClick={() => {
                  setSelectedDeliveryAddress(legalAddress);
                }}
              />
            </Box>
          ) : null}
          <Stack
            flexWrap={'wrap'}
            onClick={() => {
              if (role === RolesEnum.Accounter || role === RolesEnum.SuperAdmin) {
                onClickInput(legalAddress?.id || '');
              }
            }}
            direction={{ xs: 'column', sm: 'row' }}
            gap="8px"
            alignItems="flex-end"
          >
            <StyledTypography value={legalAddress?.city} label={translateLang('city')} />
            <StyledTypography value={legalAddress?.zipCode} label={translateLang('zip')} />
            <StyledTypography value={legalAddress?.street} label={translateLang('street')} />
            <StyledTypography value={legalAddress?.number} label={translateLang('house')} />
            <StyledTypography
              value={legalAddress?.district}
              label={translateLang('deliveryArrea')}
            />
          </Stack>

          <IconButton
            sx={{ marginLeft: 2, width: 40, height: 40 }}
            onClick={() => openModals(legalAddress?.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      )}

      {!legalAddress && (
        <IconButton
          disabled={role !== RolesEnum.Accounter && role !== RolesEnum.SuperAdmin}
          sx={{
            width: '200px',
            fontSize: 'small',
            height: '40px',
            borderRadius: '0px',
            justifyContent: 'left',
            paddingLeft: '0px',
            color: '#848C22',
          }}
          onClick={() => onClickInput('')}
        >
          <Icon icon="add" color="inherit" />
          {translateLang('addLegalAddress')}
        </IconButton>
      )}
      <CustomModal
        title={translateLang('deleteConfirm')}
        handleClose={closeSuccessModal}
        open={openSuccessModal}
      >
        <Stack flexDirection="row" mt={5}>
          <Button
            onClick={closeSuccessModal}
            variant="contained"
            color="secondary"
            style={{
              width: '80%',
              height: '95%',
              marginRight: '2px',
            }}
          >
            <TranslatedField
              originText={translateLang('cancel')}
              fontSize={16}
              isTranslate
              noWrap
              color="white"
            />
          </Button>
          <Button
            onClick={handlerDelete}
            variant="contained"
            color="error"
            style={{
              width: '80%',
              height: '95%',
              marginLeft: '2px',
            }}
          >
            <TranslatedField
              originText={translateLang('del')}
              fontSize={16}
              isTranslate
              noWrap
              color="white"
            />
          </Button>
        </Stack>
      </CustomModal>
    </>
  );
};

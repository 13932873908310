import React, { FC, useState } from 'react';
import { Button } from '@mui/material';
import { useExportToCSV } from '../../../../../hooks/useExportToCSV';
import { Icon } from '../../../../../legos';
import { useLocalization } from '../../../../../localization';
import { OrderFilters } from '../../../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { useOrdersToExportLazyQuery } from './query/__generated__/ordersToExport';
import { formatOrderNumber } from '../../../../../utils/formatOrderNumber';
import { formatDate } from '../../../../../helpers/functions';
import { getUserFullName } from '../../../../../utils/getUserFullName';
import { roundAmountToString } from '../../../../../utils/currencyUtils';

type ExportOrdersToSCVProps = {
  filters: OrderFilters;
  sort: string[];
};

export const ExportOrdersToSCV: FC<ExportOrdersToSCVProps> = ({ filters, sort }) => {
  const { translateLang, selectLanguages } = useLocalization();
  const [loading, setLoading] = useState(false);

  const exportToCSV = useExportToCSV();

  const [runOrdersToExport] = useOrdersToExportLazyQuery();

  const exportHandler = async () => {
    try {
      setLoading(true);
      const { data } = await runOrdersToExport({
        variables: {
          locale: selectLanguages ?? 'en',
          filters,
          sort,
        },
      });

      const ordersData: string[][] = [];

      data?.orders?.data.forEach(order => {
        const o = order.attributes;
        const orderInfo: string[] = [
          `#${formatOrderNumber(o?.orderNumber, o?.orderNumberAdd)}`,
          formatDate(o?.date),
          getUserFullName(o?.customer?.data),
          roundAmountToString(o?.total),
          roundAmountToString((o?.total ?? 0) - (o?.paymentTotal ?? 0)),
          o?.status ?? '',
          `${
            o?.customer_bill?.data?.attributes?.billNumber
              ? `#${o?.customer_bill?.data?.attributes?.billNumber}`
              : '-'
          }`,
          formatDate(o?.customer_bill?.data?.attributes?.billDate) || '-',
        ];

        o?.order_items?.data?.forEach(item => {
          const quantity =
            (item.attributes?.quantity?.orderedQuantity ?? 0) -
            (item.attributes?.quantity?.returnedQuantity ?? 0);
          if (quantity) {
            ordersData.push([
              ...orderInfo,
              item.attributes?.car_spare?.data?.attributes?.tecdoc_articleNumber ?? '',
              item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data?.[0]?.attributes
                ?.title ?? '',
              item.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data?.[0]?.attributes
                ?.brandName ?? '',
              roundAmountToString(item.attributes?.customer_price),
              roundAmountToString(quantity),
            ]);
          }
        });
      });

      exportToCSV({
        data: ordersData,
        fileName: 'orders.csv',
        headers: [
          translateLang('numberOrder'),
          translateLang('orderDate'),
          translateLang('customer'),
          translateLang('orderAmount'),
          translateLang('debtAmount'),
          translateLang('status'),
          translateLang('invoiceBill'),
          translateLang('invoiceBillDate'),
          translateLang('article'),
          translateLang('productName'),
          translateLang('manufacturer'),
          translateLang('price'),
          translateLang('quantity'),
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={loading}
      sx={{ height: 40, color: '#97A408', borderColor: '#97A408', borderRadius: '10px' }}
      onClick={() => exportHandler()}
      variant="outlined"
      startIcon={<Icon icon="fileDownload" color="inherit" />}
    >
      {translateLang('exportToSCV')}
    </Button>
  );
};

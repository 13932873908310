import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { format } from 'date-fns';

import { useDefaultRangeCalendar } from '../../../../hooks';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { AccountingSuppliersTabSearchParams } from '../../../Warehouse/components/IncomingInvoicesTab/hooks/useAccountingSuppliersTabSearchParams';

type SupplierBalanceToolbarProps = {
  selectedDates: Date[];
  changeParam: (
    params: Partial<Record<keyof AccountingSuppliersTabSearchParams, string | string[]>>
  ) => void;
};

export const SupplierBalanceToolbar: FC<SupplierBalanceToolbarProps> = ({
  selectedDates,
  changeParam,
}) => {
  const { reportRangeDates } = useDefaultRangeCalendar();

  return (
    <Stack width="100%" flexDirection="row" justifyContent="end" mb={2.5} gap={6.25}>
      <RangeCalendar
        selectedDates={selectedDates}
        defaultRangeDates={reportRangeDates}
        setSelectedDates={dateRange => {
          changeParam({
            dateTo: format(dateRange[1], 'yyyy-MM-dd'),
            dateFrom: format(dateRange[0], 'yyyy-MM-dd'),
          });
        }}
      />
    </Stack>
  );
};

import React, { useEffect, useState } from 'react';
import { Stack, Box, SpeedDial, SpeedDialAction } from '@mui/material';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { useLocalization } from '../../localization';
import { AllOrdersTab } from './components/AllOrders/components/AllOrdersTab';
import { usePreDeliveryStateQuery } from '../../graphql/cacheQuery/__generated__/preDeliveryState';
import { PreDeliveryModal } from '../Warehouse/components/OrderTab/components/PreDelivery/PreDeliveryModal';
import { clearDataPreDelivery } from '../../graphql/store/helper/js';
import { OrdersHistory } from './components/OrdersHistory';
import { TableChangeButtonInDelivery } from './components/TableChangeButtonInDelivery';
import { useGetIsMobileQuery } from '../../graphql/cacheQuery/__generated__/getIsMobile';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PatchesName } from '../../types/types';
import { CropFree } from '@mui/icons-material';
import SwipeIcon from '@mui/icons-material/Swipe';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { OrdersDeliveryTodayTab } from './components/OrdersDeliveryToday';

export const DeliveryPage = () => {
  const { translateLang } = useLocalization();

  const { data } = useGetIsMobileQuery();
  const isMobile = data?.isMobile ?? false;

  const [searchParams] = useSearchParams({ tab: isMobile ? 'history' : 'allOrders' });
  const navigate = useNavigate();

  const actions = [
    { icon: <SwipeIcon />, name: translateLang('return'), color: '#5269A3' },
    { icon: <QrCodeScannerIcon />, name: translateLang('issueReceipt'), color: '#848C22' },
  ];

  const activeTable = isMobile ? 'history' : searchParams.get('tab') ?? 'allOrders';
  const { data: dataPreDeliveryState } = usePreDeliveryStateQuery();

  const handleChange = (val: string | undefined) => {
    if (val) {
      navigate(`/delivery?tab=${val}`);
    }
  };

  const getActiveTable = () => {
    if (isMobile) {
      return <OrdersHistory />;
    }
    switch (activeTable) {
      case 'deliverToday':
        return <OrdersDeliveryTodayTab />;
      case 'history':
        return <OrdersHistory />;
      default:
        return <AllOrdersTab />;
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClickAction = (name: string) => {
    if (name === translateLang('issueReceipt')) {
      navigate(`/${PatchesName.MobileDelivery}`);
    } else {
      navigate(`/${PatchesName.MobileReturnOrder}`);
    }
  };
  useEffect(() => {
    return () => {
      clearDataPreDelivery();
    };
  }, []);

  return (
    <>
      <WrapperPages isButton={false} titleHeader={translateLang('_delivery')}>
        <Stack spacing={2}>
          {!isMobile && (
            <Stack
              direction="row"
              justifyContent="space-between"
              height="48px"
              marginBottom="30px"
              flexWrap="wrap"
              spacing={3}
            >
              <TableChangeButtonInDelivery
                isMobile={isMobile}
                activeTable={activeTable}
                handleChange={handleChange}
              />
            </Stack>
          )}
          <Stack>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                placeContent: 'center',
              }}
            >
              {getActiveTable()}
            </Box>
          </Stack>
        </Stack>
        <>
          {isMobile && activeTable === 'history' && (
            <Box
              sx={{
                transform: 'translateZ(0px)',
                flexGrow: 1,
                position: 'fixed',
                bottom: '20px',
                right: '20px',
              }}
            >
              <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                icon={<CropFree />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
              >
                {actions.map(action => (
                  <SpeedDialAction
                    sx={{
                      '& .MuiSpeedDialAction-staticTooltipLabel': {
                        backgroundColor: 'white',
                        border: `1px solid ${action.color}`,
                        color: action.color,
                        borderRadius: '4px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '8px',
                        width: '180px',
                        textAlign: 'center',
                      },
                    }}
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={() => handleClickAction(action.name)}
                  />
                ))}
              </SpeedDial>
            </Box>
          )}
        </>
      </WrapperPages>
      {dataPreDeliveryState?.preDeliveryState.isOpenModal && (
        <PreDeliveryModal
          open={dataPreDeliveryState?.preDeliveryState.isOpenModal}
          handleClose={() => {
            clearDataPreDelivery();
          }}
        />
      )}
    </>
  );
};

import React, { FC } from 'react';
import { Stack, Button } from '@mui/material';
import { Enum_Order_Status } from '../../../../../__generated__/types';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useDefaultRangeCalendar } from '../../../../../hooks';
import { RangeCalendar } from '../../../../../legos/rangeCalendar/RangeCalendar';
import { useLocalization } from '../../../../../localization';
import { ItemSelectFilterType, SelectFilter } from '../../../../OrdersList/components/SelectFilter';
import { format } from 'date-fns';
import { HelpSearchOrder } from '../../../../../components/HelpSearchOrder';
import { DeliveryOrdersTabSearchParams } from '../hooks/useDeliveryOrdersTabSearchParams';
import { SearchInput } from '../../../../../components/SearchInput';
import { MultipleSelectFilter } from '../../../../OrdersList/components/MultipleSelectFilter';

interface Props {
  result?: string | number;
  changeParam: (
    params: Partial<Record<keyof DeliveryOrdersTabSearchParams, string | string[]>>
  ) => void;
  statuses: string[];
  selectedDates: Date[];
  resetFilters: () => void;
  searchText: string;
  changeSearchText: (text: string | undefined) => void;
}

const getStatusesOptions = (
  translateLang: (transVar: string) => string
): ItemSelectFilterType[] => [
  ...[Enum_Order_Status.PickedUp, Enum_Order_Status.Delivery].map(value => ({
    label: translateLang(value),
    value,
  })),
];

export const AllOrdersToolbar: FC<Props> = ({
  changeParam,
  resetFilters,
  selectedDates,
  statuses,
  searchText,
  changeSearchText,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();

  return (
    <>
      <Stack
        bgcolor="white"
        pl={2}
        paddingY={1}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="right"
        spacing={2}
        gap={4}
      >
        <SearchInput
          placeholder={translateLang('searchOrdersAccountingLabel')}
          endAdornment={<HelpSearchOrder />}
          value={searchText}
          onChangeText={changeSearchText}
        />
        <Stack
          pr={3}
          py="17px"
          spacing={2}
          bgcolor="white"
          flexDirection="row"
          justifyContent="right"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <MultipleSelectFilter
              labelFilter={translateLang('status')}
              items={getStatusesOptions(translateLang)}
              selectedItems={statuses}
              changeUrlParams={selectedStatuses => changeParam({ statuses: selectedStatuses })}
            />
            <RangeCalendar
              selectedDates={selectedDates}
              defaultRangeDates={reportRangeDates}
              setSelectedDates={dateRange => {
                changeParam({
                  dateTo: format(dateRange[1], 'yyyy-MM-dd'),
                  dateFrom: format(dateRange[0], 'yyyy-MM-dd'),
                });
              }}
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={resetFilters}
              style={{
                width: '100%',
                textTransform: 'none',
              }}
            >
              <TranslatedField
                noWrap
                isTranslate
                fontSize={16}
                color="black"
                originText="resetFilters"
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

import React from 'react';
import { Box, Stack } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';

import { TabsBar } from './components/TabsBar';
import { UrlParamsObjectType } from '../../types/types';
import { OrderHistoryTab } from './components/OrderHistoryTab';
import { ClientInfoHeader } from './components/ClientInfoHeader';
import { VehicleList } from '../CheckoutPage/components/VehicleList';
import { ClientReturns } from './components/ClientReturns/ClientReturns';
import { ContactInformationTab } from './components/ContactInformationTab';
import { ClientTransaction } from '../Accounting/components/AllClients/components/ClientTransaction/ClientTransaction';

export const ClientInfo = () => {
  const { idUser = '' } = useParams<UrlParamsObjectType>();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTable = searchParams.get('tab') ?? 'contact_info';

  const handleChange = (val: string | undefined) => {
    if (val && searchParams.get('tab') !== val) {
      setSearchParams({ tab: val });
    }
  };

  const getActiveTable = () => {
    switch (searchParams.get('tab')) {
      case 'contact_info':
        return <ContactInformationTab id={idUser} />;
      case 'vehicle':
        return <VehicleList />;
      case 'history':
        return <OrderHistoryTab />;
      case 'balance':
        return <ClientTransaction userId={idUser} />;
      case 'return':
        return <ClientReturns userId={idUser} />;
      default:
        return <ContactInformationTab id={idUser} />;
    }
  };

  return (
    <Stack width="100%" height="100%">
      <ClientInfoHeader idUser={idUser} activeTable={activeTable} />

      <Stack>
        <Box mb={3.75}>
          <TabsBar activeTable={activeTable} handleChange={handleChange} />
        </Box>

        {getActiveTable()}
      </Stack>
    </Stack>
  );
};

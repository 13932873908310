import React, { DragEvent, FC, useMemo, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';

import { theme } from '../../../../../../helpers';
import { useLocalization } from '../../../../../../localization';
import { Icon } from '../../../../../../legos';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { AvailableInvoices } from './AvailableInvoices';
import { LinkedInvoices } from './LinkedInvoices';
import {
  Enum_Supplierpayment_Paymentdirection,
  Enum_Supplierpayment_Paymentmethod,
  IncomingInvoiceEntity,
  IncomingInvoiceFiltersInput,
  SupplierTransactionDocType,
} from '../../../../../../__generated__/types';
import { useSupplierPaymentInfo } from '../SupplierPaymentModal/hooks';
import { useGetIncomingInvoices } from '../../../../../../graphql/queries/hook/useGetIncomingInvoices';
import { useUpdateSupplierPaymentMutation } from '../../../../../../graphql/mutations/__generated__/updateSupplierPayment';

import { formatCurrency } from '../../../../../../utils/currencyUtils';
import { formatDateTime } from '../../../../../../utils/dateUtils';
import { RangeCalendar } from '../../../../../../legos/rangeCalendar/RangeCalendar';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { SearchField } from '../../../../../../components/SearchField';
import { useUpdateSupplierReturnMutation } from '../../../../../../graphql/mutations/__generated__/updateSupplierReturn';
import { formatDate } from '../../../../../../helpers/functions';
import { PAGE_COUNT } from '../../../../../../helpers/constants';

type LinkingPaymentModalProps = {
  handleClose: (isStateChanged: boolean) => void;
  isModalOpen: boolean;
  supplierId: string;
  documentId: string;
  documentType: SupplierTransactionDocType;
};

export type InvoiceDragDirection = 'link' | 'unlink';
export type StartDragInvoice = (invoice: IncomingInvoiceEntity) => void;
export type DraggedInvoice = {
  invoice: IncomingInvoiceEntity;
  direction: InvoiceDragDirection;
};

export const LinkingPaymentModal: FC<LinkingPaymentModalProps> = ({
  handleClose,
  isModalOpen,
  supplierId,
  documentId,
  documentType,
}) => {
  const { translateLang } = useLocalization();

  const [selectedDates, setSelectedDates] = useState([
    startOfDay(subDays(new Date(), 30)),
    new Date(),
  ]);
  const page = useRef<number>(1);
  const [search, setSearch] = useState<string>('');
  const [invoiceOnlyWithDebt, setInvoiceOnlyWithDebt] = useState<boolean>(true);
  const [isStateChanged, setIsStateChanged] = useState<boolean>(false);
  const [draggedInvoice, setDraggedInvoice] = useState<DraggedInvoice | null>(null);
  const [updateLoading, setUpdateLoading] = useState(false);

  const includePayment = useMemo(
    () =>
      [
        SupplierTransactionDocType.IncomingPaymentCash,
        SupplierTransactionDocType.IncomingPaymentNonCash,
        SupplierTransactionDocType.OutgoingPaymentCash,
        SupplierTransactionDocType.OutgoingPaymentNonCash,
      ].includes(documentType),
    [documentType]
  );

  const includeReturn = useMemo(
    () => documentType === SupplierTransactionDocType.Return,
    [documentType]
  );

  const [runUpdateSupplierPayment] = useUpdateSupplierPaymentMutation();
  const [runUpdateSupplierReturn] = useUpdateSupplierReturnMutation();

  const invoiceFilters = useMemo(() => {
    const filters: IncomingInvoiceFiltersInput = {
      and: [
        {
          supplier: {
            id: {
              eq: supplierId,
            },
          },
        },
      ],
    };

    if (search) {
      const searchAsNumber = parseInt(search);
      if (Number.isInteger(searchAsNumber)) {
        filters?.and?.push({
          or: [
            {
              id: {
                eq: `${searchAsNumber}`,
              },
            },
            {
              supplierInvoiceNumber: {
                containsi: search,
              },
            },
          ],
        });
      } else {
        filters?.and?.push({
          supplierInvoiceNumber: {
            containsi: search,
          },
        });
      }
    }

    if (invoiceOnlyWithDebt) {
      filters?.and?.push(
        {
          debtTotal: {
            notNull: true,
          },
        },
        {
          debtTotal: {
            gt: 0,
          },
        }
      );
    }

    if (selectedDates?.[0]) {
      const startDate = startOfDay(selectedDates[0]);

      filters?.and?.push({
        date: {
          gte: formatDateTime(startDate, { format: 'yyyy-MM-dd' }),
        },
      });
    }

    if (selectedDates?.[1]) {
      const endDate = endOfDay(selectedDates[1]);
      filters?.and?.push({
        date: {
          lte: formatDateTime(endDate, { format: 'yyyy-MM-dd' }),
        },
      });
    }

    page.current = 1;

    return filters;
  }, [invoiceOnlyWithDebt, selectedDates, supplierId, search]);

  const {
    data: incomingInvoicesData,
    loading: incomingInvoicesLoading,
    refetch: refetchIncomingInvoices,
    totalPage,
  } = useGetIncomingInvoices({
    filters: invoiceFilters,
    page: page.current ?? 1,
    pageSize: PAGE_COUNT,
  });

  const {
    supplierPaymentInfo,
    refetch: refetchSupplierPaymentInfo,
    loading: supplierPaymentInfoLoading,
  } = useSupplierPaymentInfo({
    includePayment,
    includeReturn,
    paymentId: includePayment ? documentId : undefined,
    returnId: includeReturn ? documentId : undefined,
    filters: {
      ...(includePayment
        ? {
            supplier_payment: {
              id: {
                eq: documentId,
              },
            },
          }
        : {
            supplier_return: {
              id: {
                eq: documentId,
              },
            },
          }),
    },
  });

  const dragStartAvailableInvoiceHandler: StartDragInvoice = invoice => {
    setDraggedInvoice({ invoice, direction: 'link' });
  };

  const dragStartLinkedInvoiceHandler: StartDragInvoice = invoice => {
    setDraggedInvoice({ invoice, direction: 'unlink' });
  };

  const handleRefetch = async () => {
    await Promise.all([refetchSupplierPaymentInfo(), refetchIncomingInvoices()]);
  };

  const updateAmount = async (
    incomingInvoicePayments: {
      id: string;
      amount: number;
    }[]
  ) => {
    try {
      setUpdateLoading(true);
      if (includeReturn) {
        await runUpdateSupplierReturn({
          variables: {
            id: documentId,
            data: {
              incomingInvoiceReturns: incomingInvoicePayments,
            },
          },
        });
      } else {
        await runUpdateSupplierPayment({
          variables: {
            id: documentId,
            data: {
              incomingInvoicePayments,
            },
          },
        });
      }
      await handleRefetch();
      setIsStateChanged(true);
    } catch (err) {
      await handleRefetch();
    } finally {
      setUpdateLoading(false);
    }
  };

  const dropHandler = async (e: DragEvent<HTMLDivElement>) => {
    const unLink = async () => {
      const incomingInvoicePayments = supplierPaymentInfo.supplierPaymentDetails.map(
        paymentDetail => ({
          id: paymentDetail?.attributes?.incoming_invoice?.data?.id ?? '',
          amount: paymentDetail?.attributes?.amount ?? 0,
        })
      );

      const linkedInvoiceIndex = supplierPaymentInfo.supplierPaymentDetails.findIndex(
        paymentDetail =>
          paymentDetail.attributes?.incoming_invoice?.data?.id === draggedInvoice!.invoice.id
      );
      if (linkedInvoiceIndex >= 0) {
        incomingInvoicePayments.splice(linkedInvoiceIndex, 1);
        await updateAmount(incomingInvoicePayments);
      }
    };

    e.stopPropagation();

    if (
      includePayment &&
      supplierPaymentInfo?.supplierPayment?.attributes &&
      draggedInvoice?.invoice?.id
    ) {
      if (draggedInvoice?.direction === 'link') {
        const incomingInvoicePayments = supplierPaymentInfo.supplierPaymentDetails.map(
          paymentDetail => ({
            id: paymentDetail?.attributes?.incoming_invoice?.data?.id ?? '',
            amount: paymentDetail?.attributes?.amount ?? 0,
          })
        );

        let amount = supplierPaymentInfo?.supplierPayment?.attributes?.remainingAmount;

        if (
          (documentType === SupplierTransactionDocType.OutgoingPaymentCash ||
            documentType === SupplierTransactionDocType.OutgoingPaymentNonCash) &&
          supplierPaymentInfo?.supplierPayment?.attributes.remainingAmount >
            Math.abs(draggedInvoice.invoice.attributes?.debtTotal ?? 0)
        ) {
          amount = Math.abs(draggedInvoice.invoice.attributes?.debtTotal ?? 0);
        }

        incomingInvoicePayments.push({
          id: draggedInvoice.invoice.id,
          amount,
        });

        await updateAmount(incomingInvoicePayments);
      } else {
        await unLink();
      }
    } else if (
      includeReturn &&
      supplierPaymentInfo?.supplierReturn?.attributes &&
      draggedInvoice?.invoice?.id
    ) {
      if (draggedInvoice?.direction === 'link') {
        const incomingInvoiceReturns = supplierPaymentInfo.supplierPaymentDetails.map(
          paymentDetail => ({
            id: paymentDetail?.attributes?.incoming_invoice?.data?.id ?? '',
            amount: paymentDetail?.attributes?.amount ?? 0,
          })
        );

        const amount =
          supplierPaymentInfo?.supplierReturn?.attributes.remainingAmount >
          Math.abs(draggedInvoice.invoice.attributes?.debtTotal ?? 0)
            ? Math.abs(draggedInvoice.invoice.attributes?.debtTotal ?? 0)
            : supplierPaymentInfo?.supplierReturn?.attributes.remainingAmount;

        incomingInvoiceReturns.push({
          id: draggedInvoice.invoice.id,
          amount,
        });

        await updateAmount(incomingInvoiceReturns);
      } else {
        await unLink();
      }
    }
  };

  const documentTypeTitle = useMemo(() => {
    if (includePayment) {
      return supplierPaymentInfo?.supplierPayment?.attributes?.paymentDirection ===
        Enum_Supplierpayment_Paymentdirection.Outgoing
        ? `${translateLang('payment')} (${translateLang(
            supplierPaymentInfo?.supplierPayment?.attributes?.paymentMethod ===
              Enum_Supplierpayment_Paymentmethod.Cash
              ? 'cash'
              : 'nonCash'
          )})`
        : `${translateLang('paymentReceived')} (${translateLang(
            supplierPaymentInfo?.supplierPayment?.attributes?.paymentMethod ===
              Enum_Supplierpayment_Paymentmethod.Cash
              ? 'cash'
              : 'nonCash'
          )})`;
    }

    return translateLang('turnoverDocument');
  }, [
    includePayment,
    supplierPaymentInfo?.supplierPayment?.attributes?.paymentDirection,
    supplierPaymentInfo?.supplierPayment?.attributes?.paymentMethod,
    translateLang,
  ]);

  const isInvoiceDraggable = useMemo(() => {
    if (includeReturn) {
      return (supplierPaymentInfo?.supplierReturn?.attributes?.remainingAmount ?? 0) > 0;
    }

    if (
      documentType === SupplierTransactionDocType.OutgoingPaymentCash ||
      documentType === SupplierTransactionDocType.OutgoingPaymentNonCash
    ) {
      return (supplierPaymentInfo.supplierPayment?.attributes?.remainingAmount ?? 0) > 0;
    }

    return true;
  }, [
    documentType,
    includeReturn,
    supplierPaymentInfo?.supplierReturn?.attributes?.remainingAmount,
    supplierPaymentInfo?.supplierPayment?.attributes?.remainingAmount,
  ]);

  const linkedAmountChange = async (newAmount: string, invoiceId?: string | null) => {
    if (invoiceId) {
      const incomingInvoicePayments = supplierPaymentInfo.supplierPaymentDetails.map(
        paymentDetail => ({
          id: paymentDetail?.attributes?.incoming_invoice?.data?.id ?? '',
          amount: paymentDetail?.attributes?.amount ?? 0,
        })
      );

      const linkedInvoiceIndex = supplierPaymentInfo.supplierPaymentDetails.findIndex(
        paymentDetail => paymentDetail.attributes?.incoming_invoice?.data?.id === invoiceId
      );

      const amountNumber = parseFloat(newAmount || '0');

      if (
        !isNaN(amountNumber) &&
        isFinite(amountNumber) &&
        amountNumber !== incomingInvoicePayments[linkedInvoiceIndex].amount
      ) {
        if (amountNumber === 0) {
          incomingInvoicePayments.splice(linkedInvoiceIndex, 1);
        } else {
          incomingInvoicePayments[linkedInvoiceIndex].amount = amountNumber;
        }
        await updateAmount(incomingInvoicePayments);
      }
    }
  };

  const loading = updateLoading || incomingInvoicesLoading || supplierPaymentInfoLoading;

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => handleClose(isStateChanged)}
      closeAfterTransition
      fullScreen
    >
      <DialogTitle>
        <Stack alignItems="center">
          <Stack width="100%" flexDirection="row" justifyContent="end" mb={2}>
            <IconButton onClick={() => handleClose(isStateChanged)} sx={{ p: 0 }}>
              <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
            </IconButton>
          </Stack>

          <TranslatedField
            isTranslate
            variant="h4"
            color={theme.palette.common.darkBlue}
            marginBottom={2.5}
            fontSize={30}
            originText={includePayment ? 'linkPayment' : 'linkReturns'}
          />
        </Stack>

        <Stack gap={2} flexDirection="row">
          <Stack gap={1}>
            <Box display="flex" flexDirection="row" gap={0.5} alignItems="baseline">
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={16}
                originText="docDate"
                suffix=":"
              />
              <Typography fontSize={18}>
                {formatDate(
                  includePayment
                    ? supplierPaymentInfo?.supplierPayment?.attributes?.paymentDate
                    : supplierPaymentInfo?.supplierReturn?.attributes?.dateReturned
                )}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" gap={0.5} alignItems="baseline">
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={16}
                originText="docNumber"
                suffix=":"
              />
              <Typography fontSize={18}>
                {includePayment
                  ? supplierPaymentInfo?.supplierPayment?.id ?? ''
                  : supplierPaymentInfo?.supplierReturn?.id}
              </Typography>
            </Box>
          </Stack>
          <Stack gap={1}>
            <Box display="flex" flexDirection="row" gap={0.5} alignItems="baseline">
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={16}
                originText="type"
                suffix=":"
              />
              <Typography fontSize={18}>{documentTypeTitle}</Typography>
            </Box>

            <Box display="flex" flexDirection="row" gap={0.5} alignItems="baseline">
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={16}
                originText="comment"
                suffix=":"
              />
              <Typography fontSize={18}>
                {includePayment
                  ? supplierPaymentInfo?.supplierPayment?.attributes?.comment ?? ''
                  : ''}
              </Typography>
            </Box>
          </Stack>
          <Stack gap={1}>
            <Box display="flex" flexDirection="row" gap={0.5} alignItems="baseline">
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={16}
                originText="total"
                suffix=":"
              />
              <Typography fontSize={18}>
                {formatCurrency(
                  includePayment
                    ? supplierPaymentInfo?.supplierPayment?.attributes?.total
                    : supplierPaymentInfo?.supplierReturn?.attributes?.total
                )}
              </Typography>
            </Box>
            <Box flexDirection="row" display="flex" gap={0.5} alignItems="baseline">
              <TranslatedField
                isTranslate
                variant="h4"
                fontSize={16}
                originText="remainingAmount"
                suffix=":"
              />

              <Typography fontSize={18} color={theme.palette.common.appleGreen}>
                {formatCurrency(
                  includePayment
                    ? supplierPaymentInfo?.supplierPayment?.attributes?.remainingAmount
                    : supplierPaymentInfo?.supplierReturn?.attributes?.remainingAmount
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>

        <TranslatedField
          pt={2}
          isTranslate
          variant="h4"
          fontSize={16}
          originText="filterByInvoices"
        />
        <Box flexDirection="row" gap={2} display="flex" pb={0.5} alignItems="center">
          <Box maxWidth={300}>
            <SearchField
              value={search}
              executeWithDelay={setSearch}
              sx={{
                maxWidth: 10,
              }}
            />
          </Box>
          <RangeCalendar selectedDates={selectedDates} setSelectedDates={setSelectedDates} />
          <FormControlLabel
            control={
              <Checkbox
                checked={invoiceOnlyWithDebt}
                onChange={(_, checked) => setInvoiceOnlyWithDebt(checked)}
              />
            }
            label={translateLang('invoiceOnlyWithDebt')}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box flexDirection="row" display="flex" gap={1} mt="2px" position="relative">
          <AvailableInvoices
            acceptDrag
            dropHandler={dropHandler}
            draggedInvoice={draggedInvoice}
            dragStartHandler={dragStartAvailableInvoiceHandler}
            invoices={incomingInvoicesData?.incomingInvoices?.data}
            draggable={isInvoiceDraggable}
          />
          <LinkedInvoices
            dropHandler={dropHandler}
            draggedInvoice={draggedInvoice}
            linkedAmountChange={linkedAmountChange}
            dragStartHandler={dragStartLinkedInvoiceHandler}
            supplierPaymentDetails={supplierPaymentInfo.supplierPaymentDetails}
          />

          <Box
            position="absolute"
            visibility={loading ? 'unset' : 'hidden'}
            borderRadius={2}
            top={0}
            left={0}
            bottom={0}
            right={0}
            bgcolor="#ffffff68"
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-start', py: 2 }}>
        <Pagination
          sx={{
            '& .MuiPaginationItem-root': {
              '&.Mui-selected': {
                background: '#5269A3',
                color: 'white',
                borderRadius: '8px',
              },
            },
          }}
          count={totalPage}
          shape="rounded"
          page={page.current}
          onChange={(event, value) => (page.current = value - 1)}
          defaultPage={5}
          siblingCount={3}
          boundaryCount={3}
        />
      </DialogActions>
    </Dialog>
  );
};

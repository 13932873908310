import React, { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { InputMaybe, UsersPermissionsUserFiltersInput } from '../../../__generated__/types';
import { getStoredParams, saveParamsToStorage } from '../../../utils/localStorage';
import { getProperPage } from '../../../utils/getProperPage';
export type ClientsListSearchParams = {
  searchText?: string;
  page?: string;
};

type SetParam = Partial<Record<keyof ClientsListSearchParams, string | string[]>>;

export const useClientsListSearchParams = (key: string, tab?: string) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams({
    page: '1',
    ...(tab ? { tab } : null),
    ...getStoredParams(key),
  });

  const page = getProperPage(urlSearchParams.get('page') ?? '1');
  const searchText = urlSearchParams.get('searchText') ?? '';

  useEffect(() => {
    setUrlSearchParams(currentUrlSearchParams => {
      const currentSearchText = currentUrlSearchParams.get('searchText');
      if (currentSearchText) {
        currentUrlSearchParams.set('searchText', currentSearchText);
      }
      const currentPage = getProperPage(currentUrlSearchParams.get('page') ?? '1');
      if (currentPage) {
        currentUrlSearchParams.set('page', currentPage);
      }
      if (tab) {
        currentUrlSearchParams.set('tab', tab);
      }

      return currentUrlSearchParams;
    });
  }, []);

  useEffect(() => {
    const tempUrlSearchParams = new URLSearchParams();
    if (urlSearchParams.get('searchText')) {
      tempUrlSearchParams.set('searchText', urlSearchParams.get('searchText') as string);
    }

    if (urlSearchParams.get('page')) {
      tempUrlSearchParams.set('page', getProperPage(urlSearchParams.get('page') ?? '1') as string);
    }

    saveParamsToStorage(tempUrlSearchParams, key);
  }, [urlSearchParams, key]);

  const changeSearchText = useCallback(
    (text: string | undefined) => {
      if (text !== searchText) {
        setUrlSearchParams(oldSearchParams => {
          if (text) {
            oldSearchParams.set('searchText', text);
          } else {
            oldSearchParams.delete('searchText');
          }

          oldSearchParams.set('page', '1');
          if (tab) {
            oldSearchParams.set('tab', tab);
          }

          return oldSearchParams;
        });
      }
    },
    [setUrlSearchParams, searchText, tab]
  );

  const changeParam = useCallback(
    (params: SetParam) => {
      setUrlSearchParams(oldSearchParams => {
        let isPageParamChanged = false;
        Object.keys(params).forEach(key => {
          const value = params[key as keyof ClientsListSearchParams];

          if (!isPageParamChanged && key === 'page') {
            isPageParamChanged = true;
          }
          if (!value || (Array.isArray(value) && value.length === 0)) {
            oldSearchParams.delete(key);
          } else if (Array.isArray(value)) {
            oldSearchParams.delete(key);
            value.forEach(v => oldSearchParams.append(key, v));
          } else {
            oldSearchParams.set(key, value);
          }
        });

        if (!isPageParamChanged) {
          oldSearchParams.set('page', '1');
        }

        if (tab) {
          oldSearchParams.set('tab', tab);
        }

        return oldSearchParams;
      });
    },
    [setUrlSearchParams, tab]
  );

  const filters = useMemo(() => {
    const newFilters: InputMaybe<UsersPermissionsUserFiltersInput> = {
      and: [{ blocked: { eq: false } }],
    };

    if (searchText) {
      let cleanedSearchText = searchText.trim().replace(/\s+/g, '');

      if (cleanedSearchText.startsWith('+')) {
        cleanedSearchText = cleanedSearchText.substring(1);
      }

      newFilters.and?.push({
        or: [
          { id: { containsi: searchText } },
          { username: { containsi: searchText } },
          { role: { name: { containsi: searchText } } },
          {
            customer_contact_info: {
              or: [
                { phoneNumbers: { number: { containsi: cleanedSearchText } } },
                { firstName: { containsi: searchText } },
                { lastName: { containsi: searchText } },
              ],
            },
          },
        ],
      });
    }

    return newFilters;
  }, [searchText]);

  return {
    page,
    filters,
    searchText,
    changeParam,
    changeSearchText,
  };
};

import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

import { getOrderNumberFilter } from '../../../utils/getOrderNumberFilter';
import { Enum_Order_Status, InputMaybe, OrderFiltersInput } from '../../../__generated__/types';
import { parse } from 'date-fns';
import { getStoredParams, saveParamsToStorage } from '../../../utils/localStorage';
import { getProperPage } from '../../../utils/getProperPage';
export type OrdersSearchParams = {
  dateFrom?: string;
  dateTo?: string;
  searchText?: string;
  managers?: string[];
  statuses?: (keyof Enum_Order_Status)[];
  page?: string;
};

type DefaultOrdersSearchParams = { defaultDateFrom?: string; defaultDateTo?: string };

type SetParam = Partial<Record<keyof OrdersSearchParams, string | string[]>>;

const ORDERS_PAGE_LOCAL_STORAGE_KEY = 'spOPSP';

export const useOrdersSearchParams = ({
  defaultDateFrom,
  defaultDateTo,
}: DefaultOrdersSearchParams = {}) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams({
    page: '1',
    ...(defaultDateFrom ? { dateFrom: defaultDateFrom } : null),
    ...(defaultDateTo ? { dateTo: defaultDateTo } : null),
    ...getStoredParams(ORDERS_PAGE_LOCAL_STORAGE_KEY),
  });

  const dateTo = urlSearchParams.get('dateTo');
  const page = getProperPage(urlSearchParams.get('page') ?? '1');
  const dateFrom = urlSearchParams.get('dateFrom');
  const searchText = urlSearchParams.get('searchText') ?? '';
  const statuses = useMemo(() => urlSearchParams.getAll('statuses'), [urlSearchParams]);
  const managers = useMemo(() => urlSearchParams.getAll('managers'), [urlSearchParams]);

  useEffect(() => {
    setUrlSearchParams(oldSearchParams => {
      if (oldSearchParams.get('dateFrom')) {
        oldSearchParams.set('dateFrom', oldSearchParams.get('dateFrom') as string);
      } else {
        oldSearchParams.delete('dateFrom');
      }

      if (oldSearchParams.get('dateTo')) {
        oldSearchParams.set('dateTo', oldSearchParams.get('dateTo') as string);
      } else {
        oldSearchParams.delete('dateTo');
      }

      oldSearchParams.set('page', getProperPage(oldSearchParams.get('page') ?? '1'));

      return oldSearchParams;
    });
  }, []);

  useEffect(() => {
    saveParamsToStorage(urlSearchParams, ORDERS_PAGE_LOCAL_STORAGE_KEY);
  }, [urlSearchParams]);

  const changSearchText = useCallback(
    (text: string | undefined) => {
      if (text !== searchText) {
        setUrlSearchParams(oldSearchParams => {
          oldSearchParams.set('page', '1');

          if (text) {
            oldSearchParams.set('searchText', text);
          } else {
            oldSearchParams.delete('searchText');
          }
          oldSearchParams.set('page', '1');

          return oldSearchParams;
        });
      }
    },
    [searchText, setUrlSearchParams]
  );

  const changeParam = useCallback(
    (params: SetParam) => {
      setUrlSearchParams(oldSearchParams => {
        let isPageParamChanged = false;
        Object.keys(params).forEach(key => {
          if (!isPageParamChanged && key === 'page') {
            isPageParamChanged = true;
          }
          const value = params[key as keyof OrdersSearchParams];
          if (!value || (Array.isArray(value) && value.length === 0)) {
            oldSearchParams.delete(key);
          } else if (Array.isArray(value)) {
            oldSearchParams.delete(key);
            value.forEach(v => oldSearchParams.append(key, v));
          } else {
            oldSearchParams.set(key, value);
          }
        });

        if (!isPageParamChanged) {
          oldSearchParams.set('page', '1');
        }

        return oldSearchParams;
      });
    },
    [setUrlSearchParams]
  );

  const filters = useMemo(() => {
    const newFilters: InputMaybe<OrderFiltersInput> = {
      and: [{ or: [{ isDeleted: { eq: false } }, { isDeleted: { null: true } }] }],
    };
    if (searchText) {
      const orderNumberFilter = getOrderNumberFilter(searchText);
      let cleanedSearchText = searchText.trim().replace(/\s+/g, '');

      if (cleanedSearchText.startsWith('+')) {
        cleanedSearchText = cleanedSearchText.substring(1);
      }
      newFilters.and?.push({
        or: [
          ...orderNumberFilter,
          {
            customer: {
              customer_contact_info: {
                or: [
                  { phoneNumbers: { number: { containsi: cleanedSearchText } } },
                  { firstName: { containsi: searchText } },
                  { lastName: { containsi: searchText } },
                  { user: { id: { containsi: searchText } } },
                ],
              },
            },
          },
          {
            customer: {
              username: { containsi: searchText },
            },
          },
        ],
      });
    }

    if (statuses.length) {
      newFilters.and?.push({
        status: { in: statuses },
      });
    }

    if (managers.length) {
      newFilters.and?.push({
        managers: { id: { in: managers } },
      });
    }

    if (dateFrom && dateTo) {
      const dateFromParsed = parse(dateFrom, 'yyyy-MM-dd', new Date());
      const dateToParsed = parse(dateTo, 'yyyy-MM-dd', new Date());

      if (!isNaN(dateFromParsed.getTime()) && !isNaN(dateToParsed.getTime())) {
        newFilters.and?.push({
          date: { between: [dateFrom, dateTo] },
        });
      }
    }

    return newFilters;
  }, [dateFrom, dateTo, managers, searchText, statuses]);

  const selectedDates = useMemo(() => {
    const datesRange = [];

    if (dateFrom) {
      const dateFromParsed = parse(dateFrom ?? '', 'yyyy-MM-dd', new Date());
      if (!isNaN(dateFromParsed.getTime())) {
        datesRange.push(dateFromParsed);
        if (dateTo) {
          const dateToParsed = parse(dateTo ?? '', 'yyyy-MM-dd', new Date());

          if (!isNaN(dateToParsed.getTime())) {
            datesRange.push(dateToParsed);
          }
        }
      }
    }

    return datesRange;
  }, [dateFrom, dateTo]);

  const resetFilters = useCallback(() => {
    setUrlSearchParams(() => {
      const newSearchParams = new URLSearchParams();
      if (defaultDateFrom) {
        newSearchParams.set('dateFrom', defaultDateFrom);
      }
      if (defaultDateTo) {
        newSearchParams.set('dateTo', defaultDateTo);
      }
      newSearchParams.set('page', '1');

      return newSearchParams;
    });
  }, [defaultDateFrom, defaultDateTo, setUrlSearchParams]);

  return {
    page,
    dateTo,
    filters,
    managers,
    statuses,
    dateFrom,
    searchText,
    changeParam,
    resetFilters,
    selectedDates,
    changSearchText,
  };
};

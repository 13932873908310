import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type CustomerContactInfoFragment = { __typename?: 'CustomerContactInfoEntityResponse', data?: { __typename?: 'CustomerContactInfoEntity', id?: string | null, attributes?: { __typename?: 'CustomerContactInfo', lastName?: string | null, firstName?: string | null, VATId?: string | null, companyName?: string | null, deliveryAddress?: Array<{ __typename?: 'ComponentClientDeliveryAddress', id: string, number?: string | null, city?: string | null, street?: string | null, zipCode?: string | null, district?: string | null, country?: string | null, main?: boolean | null } | null> | null, legalAddress?: { __typename?: 'ComponentClientDeliveryAddress', id: string, number?: string | null, city?: string | null, street?: string | null, zipCode?: string | null, district?: string | null, country?: string | null, main?: boolean | null } | null, phoneNumbers?: Array<{ __typename?: 'ComponentClientPhoneNumbers', number?: string | null, id: string } | null> | null, emails?: Array<{ __typename?: 'ComponentClientEmail', id: string, email?: string | null } | null> | null } | null } | null };

export const CustomerContactInfoFragmentDoc = gql`
    fragment customerContactInfo on CustomerContactInfoEntityResponse {
  data {
    id
    attributes {
      lastName
      firstName
      VATId
      companyName
      deliveryAddress {
        id
        number
        city
        street
        zipCode
        district
        country
        main
      }
      legalAddress {
        id
        number
        city
        street
        zipCode
        district
        country
        main
      }
      phoneNumbers {
        number
        id
      }
      emails {
        id
        email
      }
    }
  }
}
    `;
import React, { FC, Fragment, useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import { CustomModal } from '../../../components';
import { useLocalization } from '../../../localization';
import { getFittingPosition } from '../../../helpers/functions';
import { OrderItemType, VehiclesType } from '../../../helpers/types';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { ContentCopy, WhatsApp } from '@mui/icons-material';
import { roundAmountToString } from '../../../utils/currencyUtils';

interface Props {
  closeModal(): void;
  openModal: boolean;
  title: string;
  orderNumber?: string | null;
  total?: number | null;
  vehicle: VehiclesType | null;
  generalComment?: string | null;
  orderItems: OrderItemType[];
  phoneNumber: string;
  vinCode: string;
}
interface TotalCostsByProductName {
  [productName: string]: number[];
}
interface GroupedOrderItems {
  [productName: string]: OrderItemType[];
}

export const Receipt: FC<Props> = ({
  openModal,
  closeModal,
  title,
  orderNumber,
  total,
  vehicle,
  orderItems,
  generalComment,
  phoneNumber,
  vinCode,
}) => {
  const { translateLang } = useLocalization();
  const [copied, setCopied] = useState(false);

  const groupedOrderItems: GroupedOrderItems = orderItems.reduce((acc: GroupedOrderItems, item) => {
    const { productName, ...rest } = item;

    if (productName && acc[productName] && (item.quantityAfterRemovingAsReturn ?? 0) > 0) {
      acc[productName].push(rest);
    } else if (productName) {
      acc[productName] = [rest];
    }

    return acc;
  }, {});
  const vehicleText = `${vehicle?.carBrand ?? ''}, ${vehicle?.carModel ?? ''}, ${
    vehicle?.yearOfManufacture || ''
  }, ${vehicle?.modificationCar || ''}`;

  function calculateTotalCostByBrandName(
    groupedOrderItems: GroupedOrderItems
  ): TotalCostsByProductName {
    const result: TotalCostsByProductName = {};

    for (const [groupName, items] of Object.entries(groupedOrderItems)) {
      if (
        items.length === 3 &&
        new Set(items.map(item => item.brandName)).size === 3 &&
        items.every(item => item.quantity === 3)
      ) {
        const manufacturerCosts: number[] = [];
        items.forEach(item => {
          if (item.brandName) {
            manufacturerCosts.push((item?.quantity ?? 0) * (item?.customerPrice ?? 0));
          }
        });
        result[groupName] = manufacturerCosts.sort((a, b) => a - b);
      }
    }

    return result;
  }

  const totalCostByBrandName = calculateTotalCostByBrandName(groupedOrderItems);

  const receiptCopy = () => {
    const text = Object.keys(groupedOrderItems).map(productName => {
      const items = groupedOrderItems[productName];
      const productText = items.map(item => {
        const fittingPosition = getFittingPosition(item?.articleCriteria);
        return (
          `    ${item.brandName?.toUpperCase()}` +
          `\n         € ${item.customerPrice ?? 0} x ${
            item.quantity
          } Stk. = € ${roundAmountToString((item.customerPrice ?? 0) * (item.quantity ?? 0))}\n` +
          `${fittingPosition.length > 0 ? `Einbauseite: ${fittingPosition} \n\n` : ''}` +
          `${item.comment ? `Kommentar: ${item.comment} \n` : ''}`
        );
      });
      return `\n*${productName}*\n${productText.join('')} ${
        totalCostByBrandName?.[productName]
          ? totalCostByBrandName?.[productName]
              ?.map(cost => `\nGesamt: € ${roundAmountToString(cost)}`)
              .join('')
          : ''
      }
      `;
    });

    const mappedArray = text?.map(item => item);
    const mappedString = mappedArray?.join('');

    const massageText =
      `Bestellung: #${orderNumber ?? ''}\n` +
      `${vehicle ? `Auto: ${vehicleText}\n\n` : ''}` +
      `${vinCode ? `VIN-Code: ${vinCode}\n\n` : ''}` +
      mappedString +
      `${generalComment ? `\n\nAllgemeiner Kommentar: ${generalComment} \n\n\n` : ''}` +
      `\nGesamt € ${total}` +
      '\n*Preise und Versandkosten werden erst bestätigt, wenn du deinen Einkauf abschließt';

    navigator.clipboard
      .writeText(massageText)
      .then(() => {
        setCopied(true);
      })
      .catch(() => {
        setCopied(false);
      });

    return massageText;
  };

  const shareToWhatsApp = () => {
    const message = receiptCopy();
    const encodedMessage = encodeURIComponent(message);
    const url = `whatsapp://send?phone=${phoneNumber}&text=${encodedMessage}`;
    window.open(url, '_blank');
  };

  return (
    <CustomModal width={500} title={translateLang(title)} handleClose={closeModal} open={openModal}>
      <Stack
        spacing={3}
        height="500px"
        overflow="auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
          },
        }}
      >
        <Stack flexDirection="row" mt="20px !important">
          <TranslatedField originText={`Bestellung:`} fontSize={16} noWrap color="black" mr={0.5} />
          <Typography> {`#${orderNumber ?? ''}`}</Typography>
        </Stack>
        {vehicle ? (
          <Stack flexDirection="row" mt="8px !important">
            <TranslatedField originText={`Auto:`} fontSize={16} noWrap color="black" mr={0.5} />
            <Typography>{vehicleText}</Typography>
          </Stack>
        ) : null}
        {vinCode ? (
          <Stack flexDirection="row" mt="8px !important">
            <TranslatedField originText={`VIN-Code:`} fontSize={16} noWrap color="black" mr={0.5} />
            <Typography>{vinCode}</Typography>
          </Stack>
        ) : null}
        {Object.keys(groupedOrderItems).map(productName => (
          <Stack key={productName}>
            <Grid container>
              <Grid item xs={12}>
                <TranslatedField
                  originText={translateLang(productName || '').toUpperCase()}
                  fontSize={16}
                  fontWeight={700}
                  noWrap
                  color="black"
                />
              </Grid>
            </Grid>
            {groupedOrderItems[productName].map(item => {
              const fittingPosition = getFittingPosition(item?.articleCriteria);

              return (
                <Fragment key={item.id}>
                  <Grid container flexDirection="row" alignItems="baseline" gap={3}>
                    <Grid item xs="auto">
                      <TranslatedField
                        originText={translateLang(item.brandName || '')}
                        fontSize={16}
                        noWrap
                        ml="10px"
                        mt="8px !important"
                        color="black"
                      />
                    </Grid>
                    <Grid item xs="auto"></Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {item?.quantity !== 0 && (
                      <TranslatedField
                        originText={`€ ${item?.customerPrice || 0} x ${
                          item?.quantityAfterRemovingAsReturn ?? 0
                        } Stk. = € ${roundAmountToString(
                          (item?.customerPrice ?? 0) * (item?.quantityAfterRemovingAsReturn ?? 0)
                        )}`}
                        fontSize={16}
                        noWrap
                        color="#7A828A"
                        ml="30px"
                        mt="8px !important"
                      />
                    )}
                  </Grid>
                  {fittingPosition ? (
                    <Stack flexDirection="row" mt="8px !important" mb="8px !important">
                      <TranslatedField
                        originText={`Einbauseite:`}
                        fontSize={16}
                        noWrap
                        color="black"
                        mr={0.5}
                      />
                      <Typography>{fittingPosition}</Typography>
                    </Stack>
                  ) : null}
                  {item.comment ? (
                    <Stack flexDirection="row" mb="8px !important">
                      <TranslatedField
                        originText={`Kommentar:`}
                        fontSize={16}
                        noWrap
                        color="black"
                        mr={0.5}
                      />
                      <Typography>{item.comment}</Typography>
                    </Stack>
                  ) : null}
                </Fragment>
              );
            })}
            {totalCostByBrandName?.[productName] && (
              <Box mt="10px" display="flex" flexDirection="column" gap="10px">
                {totalCostByBrandName?.[productName].map(item => {
                  
                  return (
                    <Box style={{ fontSize: '16px', color: '#7A828A' }} key={item}>
                      Gesamt: € {roundAmountToString(item)}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Stack>
        ))}
        <Stack sx={{ mt: '36px !important', mb: '100px' }}>
          {generalComment ? (
            <Stack flexDirection="row" mt="8px !important">
              <TranslatedField
                originText={`Allgemeiner Kommentar:`}
                fontSize={16}
                noWrap
                color="black"
                mr={0.5}
              />
              <Typography>{generalComment}</Typography>
            </Stack>
          ) : null}
          <TranslatedField
            originText={`Gesamt € ${total}`}
            fontSize={16}
            mt={1}
            noWrap
            color="#7A828A"
          />
          <TranslatedField
            originText={
              '*Preise und Versandkosten werden erst bestätigt, wenn du deinen Einkauf abschließt'
            }
            fontSize={16}
            isTranslate
          />
        </Stack>
      </Stack>
      <Stack alignItems="baseline" flexDirection="row" mt={5} spacing={4}>
        <Button
          onClick={receiptCopy}
          variant="outlined"
          startIcon={<ContentCopy />}
          style={{
            borderRadius: '10px',
            color: !copied ? '' : 'green',
            width: '50%',
            height: '95%',
            marginRight: '12px',
          }}
        >
          {!copied ? translateLang('copy') : translateLang('copied')}
        </Button>
        <Button
          onClick={() => shareToWhatsApp()}
          variant="outlined"
          startIcon={<WhatsApp />}
          style={{
            borderRadius: '10px',
            width: '80%',
            height: '95%',
            marginLeft: '2px',
          }}
        >
          {translateLang('send')}
        </Button>
      </Stack>
    </CustomModal>
  );
};

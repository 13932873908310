import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type UpdateIncomingInvoiceMutationVariables = Types.Exact<{
  data: Types.IncomingInvoiceInput;
  id: Types.Scalars['ID'];
}>;


export type UpdateIncomingInvoiceMutation = { __typename?: 'Mutation', updateIncomingInvoice?: { __typename?: 'IncomingInvoiceEntityResponse', data?: { __typename?: 'IncomingInvoiceEntity', id?: string | null, attributes?: { __typename?: 'IncomingInvoice', status?: Types.Enum_Incominginvoice_Status | null, supplierInvoiceDate?: any | null, supplierInvoiceNumber?: string | null, supplierInvoiceTotal?: number | null, supplier?: { __typename?: 'SupplierEntityResponse', data?: { __typename?: 'SupplierEntity', id?: string | null, attributes?: { __typename?: 'Supplier', name?: string | null } | null } | null } | null } | null } | null } | null };


export const UpdateIncomingInvoiceDocument = gql`
    mutation updateIncomingInvoice($data: IncomingInvoiceInput!, $id: ID!) {
  updateIncomingInvoice(data: $data, id: $id) {
    data {
      id
      attributes {
        status
        supplier {
          data {
            id
            attributes {
              name
            }
          }
        }
        supplierInvoiceDate
        supplierInvoiceNumber
        supplierInvoiceTotal
      }
    }
  }
}
    `;
export type UpdateIncomingInvoiceMutationFn = Apollo.MutationFunction<UpdateIncomingInvoiceMutation, UpdateIncomingInvoiceMutationVariables>;

/**
 * __useUpdateIncomingInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateIncomingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIncomingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncomingInvoiceMutation, { data, loading, error }] = useUpdateIncomingInvoiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateIncomingInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIncomingInvoiceMutation, UpdateIncomingInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIncomingInvoiceMutation, UpdateIncomingInvoiceMutationVariables>(UpdateIncomingInvoiceDocument, options);
      }
export type UpdateIncomingInvoiceMutationHookResult = ReturnType<typeof useUpdateIncomingInvoiceMutation>;
export type UpdateIncomingInvoiceMutationResult = Apollo.MutationResult<UpdateIncomingInvoiceMutation>;
export type UpdateIncomingInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateIncomingInvoiceMutation, UpdateIncomingInvoiceMutationVariables>;
/* eslint-disable no-console */
import React, { useMemo, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { useParams } from 'react-router-dom';
import { CategoryList } from './CategoryList';
import { useLocalization } from '../../../localization';
import { UrlParamsObjectType } from '../../../types/types';
import { useGetTable } from '../hooks/useGetTable';
import { WrapperSearchFieldCollapse } from './WrapperSearchFieldCollapse';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import {
  Enum_Search_By,
  GeneralSparesFiltersInput,
  InputMaybe,
  OrderStaffItemInput,
} from '../../../__generated__/types';
import { useAddOrderItemMutation } from '../../../graphql/mutations/__generated__/addOrderItem';
import { useGetCategoryTreeQuery } from '../../../graphql/queries/__generated__/getCategoryTree';
import { useUpdateOrderMutation } from '../../../graphql/mutations/__generated__/updateOrder';
import { useGetOrderById } from '../../../graphql/queries/hook/useGetOrderById';
import { useGetManagers } from '../hooks/useGetManagers';
import { TableProductActive } from '../../../components/TableProductActive/TableProductActive';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useGetGeneralSparesQuery } from '../../../graphql/queries/__generated__/getGeneralSpares';
import { handlerError } from '../../../helpers/functions';
import { ApolloError } from '@apollo/client';
import { ModalInfo } from './ModalInfo';
import { FilterParts } from './FilterParts';
import { BottomNavigate } from './BottomNavigate';
import { updateSortList } from '../../../utils/sortUtils';

export enum ENUM_SEARCH_BY {
  Title = 'Title',
  ArticleNumbers = 'ArticleNumber',
  ComparableNumbers = 'ComparableNumbers',
  OEMNumbers = 'OEMNumbers',
}

const SearchParts = () => {
  const { idOrder = '' } = useParams<UrlParamsObjectType>();
  const { selectCar } = useGetOrderById(idOrder);
  const { updateSelectLanguage, translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [page, setPage] = useState(0);
  const { managers } = useGetManagers(idOrder);
  const [runAddOrderItem] = useAddOrderItemMutation();
  const [runOrderUpdateMutation] = useUpdateOrderMutation();
  const [filters, setFilters] = useState<GeneralSparesFiltersInput | undefined>(() =>
    getDefaultFilter()
  );
  const [sort, setSort] = useState<string[]>(['tecdoc_articleNumber:asc']);
  const [modalInfo, setModalInfo] = useState(false);
  const [numberSearch, setNumberSearch] = useState<string[]>([]);
  const [category, setCategory] = useState<string>('');
  const [subCategory, setSubCategory] = useState<string>('');
  const [checked, setChecked] = useState(false);
  const [currentActiveMenu, setCurrentActiveMenu] = useState<{
    id: string;
    name: string | null | undefined;
  }>({
    id: '',
    name: '',
  });
  const searchBy = [...numberSearch].filter(sortType => sortType !== null);

  const {
    data: dataCarSpares,
    loading: loadingCarSpares,
    refetch: carSparesRefetch,
  } = useGetGeneralSparesQuery({
    variables: {
      pagination: { page: page + 1, pageSize: PAGE_COUNT },
      locale: updateSelectLanguage,
      filters: {
        searchBy: searchBy as InputMaybe<Enum_Search_By>[],
        ...filters,
      },
      sort,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { getHeadersTable, getRowsTableActive } = useGetTable({
    dataCarSpares,
    handlerButtonAddOrderItem,
    carSparesRefetch,
    sort,
  });
  const [isCollapseFilter, setIsCollapseFilter] = useState(false);
  const [isCollapseCatalog, setIsCollapseCatalog] = useState(false);

  const { data: dataCategories } = useGetCategoryTreeQuery({
    variables: {
      input: {
        locale: updateSelectLanguage,
        levelTo: 1,
      },
    },
    skip: !isCollapseCatalog,
  });

  const handlerSearchFieldByText = (text: string) => {
    setFilters({
      ...filters,
      searchText: text.trim(),
    });
    setPage(0);
  };
  const handlerSearchFieldByBrand = (brandIds?: number[]) => {
    setFilters({
      ...filters,
      brandIds,
    });
    setPage(0);
  };
  const dataCategory = useMemo(
    () => dataCategories?.getCategoryTree?.categoryTree?.filter(item => item?.level === 0),
    [dataCategories]
  );
  const onCollapseClick = (type: 'catalog' | 'filter') => {
    if (type === 'catalog') {
      if (isCollapseFilter) {
        setIsCollapseFilter(false);
        setTimeout(() => {
          setIsCollapseCatalog(true);
        }, 500);
      } else {
        setIsCollapseCatalog(!isCollapseCatalog);
      }
    } else {
      if (isCollapseCatalog) {
        setIsCollapseCatalog(false);
        setTimeout(() => {
          setIsCollapseFilter(true);
        }, 500);
      } else {
        setIsCollapseFilter(!isCollapseFilter);
      }
    }
  };
  const changePageNumber = (val: number) => {
    setPage(val);
  };
  const updateTotalOrder = async () => {
    try {
      await runOrderUpdateMutation({
        variables: {
          id: idOrder,
          data: {
            managers,
          },
          locale: updateSelectLanguage as string,
        },
      });
    } catch (error) {
      console.error('[ORDER_ITEM_ERROR]', error);
      addNotification({ messageError: 'Order Item Error', typeMessage: 'error' });
    }
  };

  async function handlerButtonAddOrderItem({
    brandId,
    discount,
    external_id,
    price,
    quantity,
    spareId,
    tecdoc_articleNumber,
    deliveryDate,
  }: Omit<OrderStaffItemInput, 'orderId'>) {
    try {
      const response = await runAddOrderItem({
        variables: {
          data: {
            orderId: idOrder,
            brandId,
            discount,
            external_id,
            price,
            quantity,
            spareId,
            tecdoc_articleNumber,
            deliveryDate,
          },
        },
      });
      if (response && response.data?.createOrderItem?.data) {
        addNotification({
          messageError: translateLang('addCart'),
          typeMessage: 'success',
        });
        await updateTotalOrder();
        // refetchOrderItems && refetchOrderItems();
      } else {
        addNotification({
          messageError: translateLang('addCartError'),
          typeMessage: 'error',
        });
      }
    } catch (error: unknown) {
      if (error instanceof ApolloError && error?.message === 'Order item already exist') {
        setModalInfo(true);
      } else {
        handlerError(error);
      }
    }
  }

  const totalPages = dataCarSpares?.generalSpares?.meta?.pagination.total
    ? Math.ceil(dataCarSpares?.generalSpares?.meta?.pagination.total / PAGE_COUNT)
    : 0;
  function getDefaultFilter(): GeneralSparesFiltersInput {
    if (selectCar?.data) {
      return {
        // price: { gte: 0.01 },
        car: {
          tecdoc_carId: selectCar.data?.attributes?.tecdoc_carId || '',
          tecdoc_carType: selectCar.data?.attributes?.tecdoc_carType || '',
        },
      };
    }
    return {};
  }
  const handleNumbersSearchChange = (type: string) =>
    setNumberSearch(prevState => {
      const newState = prevState;
      if (newState.some(item => item === type)) {
        return [...newState.filter(item => item !== type)];
      } else {
        return [...newState, type];
      }
    });

  const handleSetFilters = (filters: GeneralSparesFiltersInput | undefined) => {
    setFilters(filters);
  };
  const clearFilter = () => {
    setCategory('');
    setSubCategory('');
    setChecked(false);
    setCurrentActiveMenu({
      id: '',
      name: '',
    });
    const _filters = { ...filters };
    delete _filters.tecdoc_categoryId;
    handleSetFilters(_filters);
  };
  const sortHandler = (field: string) => {
    const updatedSortList = updateSortList(sort, field);
    setSort(updatedSortList);
  };

  return (
    <>
      <WrapperSearchFieldCollapse
        isCollapseCatalog={isCollapseCatalog}
        isCollapseFilter={isCollapseFilter}
        onCollapseCatalogClick={() => onCollapseClick('catalog')}
        onCollapseFilterClick={() => onCollapseClick('filter')}
        onSearchParts={handlerSearchFieldByText}
        searchBy={searchBy}
        filters={filters}
      />
      <Collapse in={isCollapseFilter} timeout={{ enter: 400, exit: 300 }}>
        <FilterParts
          updateFilters={handlerSearchFieldByBrand}
          numberSearch={numberSearch}
          handleNumbersSearchChange={handleNumbersSearchChange}
        />
      </Collapse>
      <Collapse in={isCollapseCatalog} timeout={{ enter: 400, exit: 300 }}>
        {dataCategory?.length ? (
          <CategoryList
            filters={filters}
            dataCategories={dataCategory}
            handleSetFilters={handleSetFilters}
            category={category}
            subCategory={subCategory}
            currentActiveMenu={currentActiveMenu}
            setCategory={setCategory}
            setSubCategory={setSubCategory}
            setCurrentActiveMenu={setCurrentActiveMenu}
            checked={checked}
            setChecked={setChecked}
          />
        ) : null}
      </Collapse>
      <BottomNavigate
        category={category}
        currentActiveMenu={{
          name: '',
          id: '',
        }}
        subCategory={subCategory}
        clearFilter={clearFilter}
      />
      <Box pt={2} boxSizing="border-box">
        <TableProductActive
          itemsHeader={getHeadersTable()}
          itemsRows={getRowsTableActive()}
          totalPages={totalPages}
          startPage={dataCarSpares?.generalSpares?.meta?.pagination.page || 0}
          changeCurrentPage={changePageNumber}
          loading={loadingCarSpares}
          bgColorPagination="#f8f8f8"
          sortHandler={sortHandler}
        />
      </Box>
      <ModalInfo closeModal={() => setModalInfo(false)} isOpen={modalInfo} />
    </>
  );
};
export default SearchParts;

import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { GeneralSpareFragmentFragmentDoc } from '../../fragments/__generated__/GeneralSpareFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type SetDiscountForSpareMutationVariables = Types.Exact<{
  discounts: Array<Types.DiscountInput> | Types.DiscountInput;
  generalSpareId: Types.Scalars['ID'];
  locale: Types.Scalars['I18NLocaleCode'];
}>;


export type SetDiscountForSpareMutation = { __typename?: 'Mutation', updateDiscounts?: { __typename?: 'GeneralSparesEntityResponse', data?: { __typename?: 'GeneralSparesEntity', id: string, attributes?: { __typename?: 'GeneralSpare', descriptionsInfo?: any | null, articleCriteria?: any | null, isDraft?: boolean | null, source?: number | null, eanNumber?: any | null, oemNumbers?: any | null, images?: any | null, price?: number | null, brandId?: number | null, brandName?: string | null, title?: string | null, tecdoc_articleNumber?: string | null, discounts?: Array<{ __typename?: 'GeneralDiscounts', id?: string | null, discount?: number | null, supplier?: { __typename?: 'GeneralSupplier', id?: string | null } | null } | null> | null, suppliersSpare?: Array<{ __typename?: 'GeneralSuppliersSpare', id?: string | null, minOrderQuantity?: number | null, amount?: string | null, discount?: number | null, prevPrice?: number | null, price?: number | null, supplierPrice?: number | null, warehouse?: any | null, tecdoc_articleNumber?: string | null, external_id?: string | null, priceByRole?: { __typename?: 'PriceByRole', customer?: number | null, customer30?: number | null, customer40?: number | null, employee?: number | null, permanentWholesaleCustomer?: number | null, wholesaleCustomer?: number | null } | null, supplier?: { __typename?: 'GeneralSupplier', id?: string | null, name?: string | null, deliveryDay?: number | null } | null } | null> | null } | null } | null } | null };


export const SetDiscountForSpareDocument = gql`
    mutation setDiscountForSpare($discounts: [DiscountInput!]!, $generalSpareId: ID!, $locale: I18NLocaleCode!) {
  updateDiscounts(
    discounts: $discounts
    generalSpareId: $generalSpareId
    locale: $locale
  ) {
    data {
      ...GeneralSpareFragment
    }
  }
}
    ${GeneralSpareFragmentFragmentDoc}`;
export type SetDiscountForSpareMutationFn = Apollo.MutationFunction<SetDiscountForSpareMutation, SetDiscountForSpareMutationVariables>;

/**
 * __useSetDiscountForSpareMutation__
 *
 * To run a mutation, you first call `useSetDiscountForSpareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDiscountForSpareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDiscountForSpareMutation, { data, loading, error }] = useSetDiscountForSpareMutation({
 *   variables: {
 *      discounts: // value for 'discounts'
 *      generalSpareId: // value for 'generalSpareId'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSetDiscountForSpareMutation(baseOptions?: Apollo.MutationHookOptions<SetDiscountForSpareMutation, SetDiscountForSpareMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDiscountForSpareMutation, SetDiscountForSpareMutationVariables>(SetDiscountForSpareDocument, options);
      }
export type SetDiscountForSpareMutationHookResult = ReturnType<typeof useSetDiscountForSpareMutation>;
export type SetDiscountForSpareMutationResult = Apollo.MutationResult<SetDiscountForSpareMutation>;
export type SetDiscountForSpareMutationOptions = Apollo.BaseMutationOptions<SetDiscountForSpareMutation, SetDiscountForSpareMutationVariables>;
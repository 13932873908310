import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetIncomingInvoiceQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  locale?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetIncomingInvoiceQuery = { __typename?: 'Query', incomingInvoice?: { __typename?: 'IncomingInvoiceEntityResponse', data?: { __typename?: 'IncomingInvoiceEntity', id?: string | null, attributes?: { __typename?: 'IncomingInvoice', status?: Types.Enum_Incominginvoice_Status | null, supplierInvoiceNumber?: string | null, supplierInvoiceDate?: any | null, date: any, total?: number | null, supplierInvoiceTotal?: number | null, supplier?: { __typename?: 'SupplierEntityResponse', data?: { __typename?: 'SupplierEntity', id?: string | null } | null } | null, stocks?: { __typename?: 'StockRelationResponseCollection', data: Array<{ __typename?: 'StockEntity', id?: string | null, attributes?: { __typename?: 'Stock', supplierPrice?: number | null, incomingQuantity?: number | null, car_spare?: { __typename?: 'CarSpareEntityResponse', data?: { __typename?: 'CarSpareEntity', id?: string | null, attributes?: { __typename?: 'CarSpare', tecdoc_articleNumber?: string | null, car_spare_locales?: { __typename?: 'CarSpareLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarSpareLocaleEntity', id?: string | null, attributes?: { __typename?: 'CarSpareLocale', title?: string | null, brandName?: string | null } | null }> } | null } | null } | null } | null } | null }> } | null } | null } | null } | null };


export const GetIncomingInvoiceDocument = gql`
    query getIncomingInvoice($id: ID, $locale: String) {
  incomingInvoice(id: $id) {
    data {
      id
      attributes {
        status
        supplierInvoiceNumber
        supplierInvoiceDate
        date
        total
        supplierInvoiceTotal
        supplier {
          data {
            id
          }
        }
        stocks {
          data {
            id
            attributes {
              supplierPrice
              incomingQuantity
              car_spare {
                data {
                  id
                  attributes {
                    tecdoc_articleNumber
                    car_spare_locales(filters: {locale: {eq: $locale}}) {
                      data {
                        id
                        attributes {
                          title
                          brandName
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetIncomingInvoiceQuery__
 *
 * To run a query within a React component, call `useGetIncomingInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomingInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomingInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetIncomingInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<GetIncomingInvoiceQuery, GetIncomingInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncomingInvoiceQuery, GetIncomingInvoiceQueryVariables>(GetIncomingInvoiceDocument, options);
      }
export function useGetIncomingInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncomingInvoiceQuery, GetIncomingInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncomingInvoiceQuery, GetIncomingInvoiceQueryVariables>(GetIncomingInvoiceDocument, options);
        }
export type GetIncomingInvoiceQueryHookResult = ReturnType<typeof useGetIncomingInvoiceQuery>;
export type GetIncomingInvoiceLazyQueryHookResult = ReturnType<typeof useGetIncomingInvoiceLazyQuery>;
export type GetIncomingInvoiceQueryResult = Apollo.QueryResult<GetIncomingInvoiceQuery, GetIncomingInvoiceQueryVariables>;
import React, { FC, forwardRef } from 'react';
import * as yup from 'yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Input } from '../../../../legos';
import { useFormik } from 'formik';
import { useUpdateCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/updateCustomerContactInfo';
import { useGetClientById } from '../../../../graphql/queries/hook/useGetClientById';
import {
  ComponentClientEmailInput,
  CustomerContactInfoInput,
} from '../../../../__generated__/types';
import { GetClientByIdDocument } from '../../../../graphql/queries/__generated__/getClintById';
import { useAddCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/addCustomerContactInfo';

import { CustomModal } from '../../../../components';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../../../../localization';
import { handlerError } from '../../../../helpers/functions';
import { useValidation } from '../../../../hooks';

interface EmailProps {
  clientId: string;
  closeModal(): void;
  openModal: boolean;
  id?: string;
  email?: string;
}

enum EmailFields {
  Email = 'email',
}

export const EmailModal: FC<EmailProps> = ({ id, closeModal, openModal, clientId }) => {
  const { emails, isContactInfoActive, contactInfoId } = useGetClientById(clientId);
  const { updateSelectLanguage, translateLang } = useLocalization();
  const [runAddCustomerContactInfo] = useAddCustomerContactInfoMutation();
  const [runUpdateCustomer] = useUpdateCustomerContactInfoMutation();
  const { emailValidation } = useValidation();

  const getCurrentEmail = () => {
    if (id) {
      const item = emails?.find(item => item?.id === id);

      if (item && item?.email) {
        return item.email;
      }
    }

    return '';
  };
  const initialValues = {
    [EmailFields.Email]: getCurrentEmail(),
  };
  const { values, errors, touched, setErrors, handleSubmit, handleChange } = useFormik({
    initialValues,
    validationSchema: yup.object({
      [EmailFields.Email]: emailValidation,
    }),
    onSubmit: async values => {
      let emailItems: ComponentClientEmailInput[] | undefined;
      if (id) {
        emailItems = emails?.map(item => {
          let currentEmail = item?.email;
          if (item?.id === id) {
            currentEmail = values[EmailFields.Email];
          }

          return {
            id: item?.id,
            email: currentEmail,
          };
        });
      } else {
        emailItems = emails?.map(item => {
          return {
            id: item?.id,
            email: item?.email,
          };
        });

        if (emailItems) {
          emailItems?.push({ email: values[EmailFields.Email] });
        } else {
          emailItems = [{ email: values[EmailFields.Email] }];
        }
      }
      const variables: CustomerContactInfoInput = {
        emails: emailItems,
      };
      if (!isContactInfoActive) {
        variables.user = clientId;
        try {
          await runAddCustomerContactInfo({
            variables: {
              data: {
                ...variables,
              },
            },
            refetchQueries: [
              {
                query: GetClientByIdDocument,
                variables: {
                  id: clientId,
                  locale: updateSelectLanguage as string,
                },
              },
            ],
          });
        } catch (error: unknown) {
          handlerError(error);
        }
      } else {
        try {
          await runUpdateCustomer({
            variables: {
              data: {
                ...variables,
              },
              id: contactInfoId || '',
            },
          });
        } catch (error) {
          setErrors({
            [EmailFields.Email]: error as string,
          });
        } finally {
          closeModal();
        }
      }
    },
  });

  return (
    <CustomModal title="email" width={300} handleClose={closeModal} open={openModal}>
      <Stack component="form" flexGrow={1} spacing={6} onSubmit={handleSubmit}>
        <Input name={EmailFields.Email} value={values[EmailFields.Email]} onChange={handleChange} />
        <Box position="absolute">
          {touched[EmailFields.Email] && errors[EmailFields.Email] && (
            <Typography m="5px 0 0 0" color="red" fontSize="13px">
              {errors[EmailFields.Email]}
            </Typography>
          )}
        </Box>

        <Button color="secondary" variant="contained" size="large" type="submit">
          <TranslatedField originText="saveChanges" isTranslate color="white" />
        </Button>
      </Stack>
    </CustomModal>
  );
};

import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetSearchProductQueryVariables = Types.Exact<{
  input: Types.SearchProductsInput;
}>;


export type GetSearchProductQuery = { __typename?: 'Query', searchProducts?: Array<{ __typename?: 'SearchProductsResult', tecdoc_articleNumber?: string | null, orderId?: string | null, orderNumber?: string | null, orderNumberAdd?: string | null, orderItemQuantity?: number | null, supplierName?: string | null, title?: string | null, orderItemId?: string | null, brandName?: string | null, brandId?: string | null, supplierId?: string | null } | null> | null };


export const GetSearchProductDocument = gql`
    query getSearchProduct($input: SearchProductsInput!) {
  searchProducts(input: $input) {
    tecdoc_articleNumber
    orderId
    orderNumber
    orderNumberAdd
    orderItemQuantity
    supplierName
    title
    orderItemId
    brandName
    brandId
    supplierId
    supplierName
  }
}
    `;

/**
 * __useGetSearchProductQuery__
 *
 * To run a query within a React component, call `useGetSearchProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchProductQuery(baseOptions: Apollo.QueryHookOptions<GetSearchProductQuery, GetSearchProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchProductQuery, GetSearchProductQueryVariables>(GetSearchProductDocument, options);
      }
export function useGetSearchProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchProductQuery, GetSearchProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchProductQuery, GetSearchProductQueryVariables>(GetSearchProductDocument, options);
        }
export type GetSearchProductQueryHookResult = ReturnType<typeof useGetSearchProductQuery>;
export type GetSearchProductLazyQueryHookResult = ReturnType<typeof useGetSearchProductLazyQuery>;
export type GetSearchProductQueryResult = Apollo.QueryResult<GetSearchProductQuery, GetSearchProductQueryVariables>;
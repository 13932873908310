type GetUserFullNameParamsSingle = {
  attributes?: {
    username?: string | null;
    customer_contact_info?: {
      data?: {
        attributes?: {
          lastName?: string | null;
          firstName?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

type GetUserFullNameParams = GetUserFullNameParamsSingle | GetUserFullNameParamsSingle[];
export const getUserFullName = (data?: GetUserFullNameParams | null) => {
  if (Array.isArray(data)) {
    return data
      .map(item => {
        const { lastName, firstName } =
          item?.attributes?.customer_contact_info?.data?.attributes ?? {};
        if (lastName || firstName) {
          return formatClientName(firstName, lastName);
        } else {
          return item?.attributes?.username ?? '';
        }
      })
      .join(', ');
  } else {
    const { lastName, firstName } = data?.attributes?.customer_contact_info?.data?.attributes ?? {};
    if (lastName || firstName) {
      return formatClientName(firstName, lastName);
    } else {
      return data?.attributes?.username ?? '';
    }
  }
};

export const formatClientName = (
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null
) =>
  `${firstName ?? ''}${firstName && lastName ? ` ${lastName}` : lastName ?? ''}` ||
  companyName ||
  '';

import React, { useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { addDays, format } from 'date-fns';
import { useLocalization } from '../../../../localization';
import { useGetClientReturnsForWareHouseQuery } from './hooks/useGetCustomerReturnsForWarehouseQuery';
import { ReturnsTabTable } from './ReturnsTabTable';
import { ToolBarReturnsTab } from './ToolBarReturnsTab';
import { CreateUpdateReturnModal } from './CreateUpdateReturnModal';
import { useWarehouseReturnsFromCustomerTabSearchParams } from './hooks/useWarehouseReturnsFromCustomerTabSearchParams';
import { SearchInput } from '../../../../components/SearchInput';
import { PAGE_COUNT } from '../../../../helpers/constants';

export const ReturnsTab = () => {
  const { translateLang } = useLocalization();
  const {
    page,
    status,
    filters,
    searchText,
    changeParam,
    resetFilters,
    selectedDates,
    changeSearchText,
  } = useWarehouseReturnsFromCustomerTabSearchParams({
    defaultDateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    defaultDateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  const [isEdit, setEdit] = useState<boolean>(false);
  const [openReturnModal, setOpenReturnModal] = useState<boolean>(false);

  const { customerReturns, isLoading, totalPage, total } = useGetClientReturnsForWareHouseQuery({
    filters,
    page: parseInt(page),
    pageSize: PAGE_COUNT,
  });

  const closeReturnModal = () => {
    setOpenReturnModal(false);
  };

  return (
    <Stack width="100%" paddingBottom={10}>
      <Box style={{ display: 'flex', justifyContent: 'end', marginBottom: '50px' }}>
        <Button
          style={{ width: '220px' }}
          disabled={true}
          variant="contained"
          onClick={() => setOpenReturnModal(true)}
        >
          {translateLang('createReturn')}
        </Button>
      </Box>
      <SearchInput
        onChangeText={changeSearchText}
        placeholder={translateLang('search_returns')}
        value={searchText}
      />
      <ToolBarReturnsTab
        result={total}
        status={status}
        selectedDates={selectedDates}
        resetFilters={resetFilters}
        changeParam={changeParam}
      />

      <ReturnsTabTable
        returnedItems={customerReturns}
        loading={isLoading}
        changePage={page => changeParam({ page: `${page + 1}` })}
        currentPage={parseInt(page)}
        totalPage={totalPage}
      />
      <CreateUpdateReturnModal isEdit={isEdit} open={openReturnModal} close={closeReturnModal} />
    </Stack>
  );
};

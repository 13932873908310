import React, { FC, useMemo } from 'react';

import { useLocalization } from '../../../../localization';
import {
  Enum_Componentorderstatuseditinghistory_Status,
  Enum_Order_Status,
} from '../../../../__generated__/types';
import { Stack } from '@mui/material';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import {
  MultipleItemSelectFilterType,
  MultipleSelectFilter,
} from '../../../OrdersList/components/MultipleSelectFilter';
import { WarehouseOrderTabSearchParams } from './hooks/useWarehouseOrderTabSearchParams';

interface Props {
  changeParam: (
    params: Partial<Record<keyof WarehouseOrderTabSearchParams, string | string[]>>
  ) => void;
  statuses: string[];
  result: string | number | undefined;
}

export type OrderStatusEditingHistory_Status = {
  label: Enum_Componentorderstatuseditinghistory_Status;
  value: string;
};

export const ToolBarCompletedOrderTab: FC<Props> = ({ result, statuses, changeParam }) => {
  const { translateLang } = useLocalization();

  const statusesOptions = useMemo(
    (): MultipleItemSelectFilterType[] =>
      [
        Enum_Componentorderstatuseditinghistory_Status.Awaiting,
        Enum_Componentorderstatuseditinghistory_Status.InStock,
        Enum_Componentorderstatuseditinghistory_Status.PickedUp,
        Enum_Componentorderstatuseditinghistory_Status.Delivery,
        Enum_Componentorderstatuseditinghistory_Status.Completed,
      ].map(value => {
        return {
          label: translateLang(value),
          value,
        };
      }),
    [translateLang]
  );

  return (
    <WrapperToolBar>
      <Stack width="20%" flexDirection="row" justifyContent="left">
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || ''}
        />
      </Stack>
      <Stack spacing={2} bgcolor="white" flexDirection="row" justifyContent="right">
        <Stack direction="row" gap={2} alignItems="center">
          <MultipleSelectFilter
            labelFilter={translateLang('status')}
            items={statusesOptions}
            selectedItems={statuses}
            changeUrlParams={selectedStatuses => changeParam({ statuses: selectedStatuses })}
          />
        </Stack>
      </Stack>
    </WrapperToolBar>
  );
};

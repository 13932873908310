export const getOrderNumberFilter = (filter?: string) => {
  const orderNumberFilter = [];

  let orderNumber;
  let orderNumberAdd;
  if (filter) {
    const regexWithHash = /^(\d+)(?:-(\d+))?/;
    const regexWithoutHash = /-(\d+)/;

    let match = filter.match(regexWithHash);
    if (match) {
      [, orderNumber, orderNumberAdd] = match;
    }

    match = filter.match(regexWithoutHash);
    if (match) {
      [, orderNumberAdd] = match;
    }

    if (orderNumber) {
      if (orderNumberAdd) {
        orderNumberFilter.push({
          and: [
            { orderNumber: { containsi: orderNumber } },
            { orderNumberAdd: { containsi: orderNumberAdd } },
          ],
        });
      } else {
        orderNumberFilter.push({ orderNumber: { containsi: orderNumber } });
      }
    } else if (orderNumberAdd) {
      orderNumberFilter.push({ orderNumberAdd: { containsi: orderNumberAdd } });
    }
  }

  return orderNumberFilter;
};

import React, { Dispatch, FC, Fragment, SetStateAction, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocalization } from '../../../localization';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { PAGE_COUNT } from '../../../helpers/constants';
import { TableProduct } from '../../../components/TableProduct/TableProduct';
import { useGetTableClientsListData } from '../hooks/useGetTableClientsListData';
import { useUpdateCustomerMutation } from '../../../graphql/mutations/__generated__/updateCustomer';
import { handlerError } from '../../../helpers/functions';
import { ModalConfirm } from '../../../components/ModalConfirm';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { DataModalType } from '../../../helpers/types';
import { useClientsListSearchParams } from '../hooks/useClientsListSearchParams';
import { useGetClients } from '../../../graphql/queries/hook/useGetClients';
import { SearchInput } from '../../../components/SearchInput';
import { InputMaybe, UsersPermissionsUserFiltersInput } from '../../../__generated__/types';

interface TableClientsProps {
  handlerCellClick(idUser: string | number): void;
  isCreateOrder?: boolean;
  isClientModal?: boolean;
  setSelectClientModal?: Dispatch<SetStateAction<boolean>>;
}
type UserType = {
  userID: string;
  name: string;
  type: string;
};

const CLIENTS_LIST_LOCAL_STORAGE_KEY = 'spCLSP';

export const TableClients: FC<TableClientsProps> = ({
  handlerCellClick,
  isCreateOrder,
  isClientModal,
  setSelectClientModal,
}) => {
  const { addNotification } = useHandlerNotificationApp();
  const { translateLang } = useLocalization();

  const { page, filters, searchText, changeSearchText, changeParam } = useClientsListSearchParams(
    CLIENTS_LIST_LOCAL_STORAGE_KEY
  );
  const [clientSearchText, setClientSearchText] = useState('');
  const [clientModalPage, setClientModalPage] = useState(1);
  const clientModalFilters = useMemo(() => {
    const newFilters: InputMaybe<UsersPermissionsUserFiltersInput> = {
      and: [{ blocked: { eq: false } }],
    };

    if (clientSearchText) {
      let cleanedSearchText = clientSearchText.trim().replace(/\s+/g, '');

      if (cleanedSearchText.startsWith('+')) {
        cleanedSearchText = clientSearchText.substring(1);
      }

      newFilters.and?.push({
        or: [
          { id: { containsi: clientSearchText } },
          { username: { containsi: clientSearchText } },
          { role: { name: { containsi: clientSearchText } } },
          {
            customer_contact_info: {
              or: [
                { phoneNumbers: { number: { containsi: clientSearchText } } },
                { firstName: { containsi: clientSearchText } },
                { lastName: { containsi: clientSearchText } },
              ],
            },
          },
        ],
      });
    }

    return newFilters;
  }, [clientSearchText]);

  const { clients, pagination, isLoading } = useGetClients({
    pagination: {
      page: isClientModal ? clientModalPage : parseInt(page),
      pageSize: PAGE_COUNT,
    },
    filters: isClientModal ? clientModalFilters : filters,
  });

  const [runUpdateCustomer, { loading: loadRunUpdate }] = useUpdateCustomerMutation();
  const [isModalConfirm, setIsModalConfirm] = useState<DataModalType<UserType>>({
    isOpen: false,
    selProduct: {
      name: '',
      type: '',
      userID: '',
    },
  });
  const [isModalClientConfirm, setModalClientConfirm] = useState(false);
  const [clientId, setClientId] = useState('');
  const { getHeadersTable, getRowsTable } = useGetTableClientsListData({
    clients,
    isCreateOrder,
    handlerCellClick,
    isClientModal,
    setModalClientConfirm,
    setClientId,
    handlerBlockedUser: (userID, name, type) =>
      setIsModalConfirm({
        isOpen: true,
        selProduct: {
          name,
          type,
          userID,
        },
      }),
  });

  async function handlerBlockedUser(userID: string) {
    try {
      const resp = await runUpdateCustomer({
        variables: {
          id: userID,
          data: {
            blocked: true,
          },
        },
      });
      if (resp.data?.updateUsersPermissionsUser) {
        addNotification({
          messageError: `[SUCCESS]: ${translateLang('successBlockUser')}`,
          typeMessage: 'success',
        });
      }
    } catch (err: unknown) {
      handlerError(err);
    }
  }
  const getInformation = () => {
    return (
      <>
        <Typography>{`ID: #${translateLang(isModalConfirm.selProduct?.userID ?? '')}`}</Typography>
        <Typography>
          {`${translateLang('customerType')}: ${translateLang(
            isModalConfirm.selProduct?.type ?? ''
          )}`}
        </Typography>
        <Typography>
          {`${translateLang('name')}: ${translateLang(isModalConfirm.selProduct?.name ?? '')}`}
        </Typography>
      </>
    );
  };

  return (
    <Fragment>
      <Box sx={{ width: '100%' }}>
        <SearchInput
          value={searchText}
          onChangeText={text => {
            if (isClientModal) {
              setClientSearchText(text ?? '');
              setClientModalPage(1);
            } else {
              changeSearchText(text);
            }
          }}
          placeholder={translateLang('search_name')}
        />
        <Box width="100%" bgcolor="white" padding="17px 27px" boxSizing="border-box">
          <TranslatedField
            originText="result"
            isTranslate
            suffix={`: ${pagination?.total || 0}`}
            fontSize="30px"
          />
        </Box>
        <Box p={1} boxSizing="border-box">
          <TableProduct
            startPage={pagination?.page || 0}
            totalPages={pagination?.total ? Math.ceil(pagination.total / PAGE_COUNT) : 0}
            itemsRows={getRowsTable()}
            itemsHeader={getHeadersTable()}
            changeCurrentPage={newPage => {
              if (isClientModal) {
                setClientModalPage(newPage + 1);
              } else {
                changeParam({ page: `${newPage + 1}` });
              }
            }}
            loading={isLoading || loadRunUpdate}
          />
        </Box>
      </Box>
      {isModalConfirm.isOpen && (
        <ModalConfirm
          title={`${translateLang('wantBlockUser')}?`}
          information={getInformation()}
          closeModal={() =>
            setIsModalConfirm({
              isOpen: false,
              selProduct: {
                name: '',
                type: '',
                userID: '',
              },
            })
          }
          openModal={isModalConfirm.isOpen}
          handlerConfirm={() => handlerBlockedUser(isModalConfirm?.selProduct?.userID ?? '')}
        />
      )}
      {isModalClientConfirm && (
        <ModalConfirm
          title={`${translateLang('confirmClientChange')}`}
          information={`${translateLang('changeClient')}`}
          closeModal={() => {
            setModalClientConfirm(false);
          }}
          openModal={true}
          handlerConfirm={() => {
            if (setSelectClientModal) {
              setSelectClientModal(false);
            }
            handlerCellClick(clientId ?? '');
          }}
        />
      )}
    </Fragment>
  );
};

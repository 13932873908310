import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetStockCellAddressesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStockCellAddressesQuery = { __typename?: 'Query', stockCellAddresses?: Array<{ __typename?: 'StockCellAddressItem', id?: string | null, shelf?: string | null, cell?: string | null, isEmptyCell?: boolean | null, priority?: number | null } | null> | null };


export const GetStockCellAddressesDocument = gql`
    query getStockCellAddresses {
  stockCellAddresses {
    id
    shelf
    cell
    isEmptyCell
    priority
  }
}
    `;

/**
 * __useGetStockCellAddressesQuery__
 *
 * To run a query within a React component, call `useGetStockCellAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCellAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCellAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockCellAddressesQuery(baseOptions?: Apollo.QueryHookOptions<GetStockCellAddressesQuery, GetStockCellAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockCellAddressesQuery, GetStockCellAddressesQueryVariables>(GetStockCellAddressesDocument, options);
      }
export function useGetStockCellAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockCellAddressesQuery, GetStockCellAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockCellAddressesQuery, GetStockCellAddressesQueryVariables>(GetStockCellAddressesDocument, options);
        }
export type GetStockCellAddressesQueryHookResult = ReturnType<typeof useGetStockCellAddressesQuery>;
export type GetStockCellAddressesLazyQueryHookResult = ReturnType<typeof useGetStockCellAddressesLazyQuery>;
export type GetStockCellAddressesQueryResult = Apollo.QueryResult<GetStockCellAddressesQuery, GetStockCellAddressesQueryVariables>;
import { endOfToday, startOfMonth } from 'date-fns';

import {
  CustomerReturnItemFiltersInput,
  Enum_Customerreturn_Status,
  Pagination,
  PaginationArg,
} from '../../../../../__generated__/types';
import { useGetCustomerReturnsForWarehouseQuery } from '../../../../../graphql/queries/__generated__/getCustomerReturnsForWarehousePage';
import { useMemo, useState } from 'react';
import { PAGE_COUNT } from '../../../../../helpers/constants';
import { useSearchParams } from 'react-router-dom';

type GetUserFullNameParams = {
  attributes?: {
    username?: string | null;
    customer_contact_info?: {
      data?: {
        attributes?: {
          lastName?: string | null;
          firstName?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};
export interface ReturnType {
  data?: GetUserFullNameParams;
  returnId: string | null;
  id?: string | null;
  correctiveInvoiceDate: any;
  correctiveInvoiceNumber?: string | null;
  dateOfReceiving: any;
  dateReturned: any;
  status?: Enum_Customerreturn_Status | null;
  returnReason?: string | null;
  returnComment?: string | null;
  name?: string;
  surname?: string;
  dateIncoming?: Date | null;
  total?: number | null;
  fullName?: string;
}

export type PaginationTransactionsType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  customerReturns: Array<ReturnType>;
  isLoading?: boolean;
  refetchCustomerReturns: () => void;
  totalPage: number;
  total: number;
}

export type FiltersType = {
  searchStrings?: string;
  searchNumbers?: string;
};
interface PropsHook {
  page?: number;
  pageSize?: number;
  filters: CustomerReturnItemFiltersInput;
}

export const useGetClientReturnsForWareHouseQuery = ({
  filters,
  page,
  pageSize,
}: PropsHook): ReturnHook => {
  const {
    data: clientReturnsData,
    loading: clientReturnsLoading,
    refetch: refetchCustomerReturns,
  } = useGetCustomerReturnsForWarehouseQuery({
    variables: {
      filters,
      pagination: {
        page,
        pageSize,
      },
      sort: ['dateReturned:asc', 'id:asc'],
    },
  });

  const customerReturns = useMemo(() => {
    if (clientReturnsData?.customerReturns?.data) {
      const returnsData = clientReturnsData?.customerReturns.data;
      return returnsData.map(item => {
        return {
          data: {
            attributes: {
              username: item.attributes?.customer?.data?.attributes?.username || null,
              customer_contact_info:
                item.attributes?.customer?.data?.attributes?.customer_contact_info,
            },
          },
          name:
            item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
              ?.firstName ?? '',
          surName:
            item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
              ?.firstName ?? '',
          returnId: item?.id ?? '',
          id: item.attributes?.customer?.data?.id ?? '',
          total: item.attributes?.total || 0,
          dateOfReceiving:
            item?.attributes?.customer_return_items?.data[0]?.attributes?.stock?.data?.attributes
              ?.incoming_invoice?.data?.attributes?.supplierInvoiceDate ?? '',
          correctiveInvoiceDate: item?.attributes?.correctiveInvoiceDate ?? '',
          correctiveInvoiceNumber: item.attributes?.correctiveInvoiceNumber ?? '',
          dateReturned: item.attributes?.dateReturned ?? '',
          status: item.attributes?.status ?? Enum_Customerreturn_Status.Created,
          returnReason:
            item?.attributes?.customer_return_items?.data[0]?.attributes?.returnReason ?? '',
          returnComment:
            item?.attributes?.customer_return_items?.data[0]?.attributes?.returnComment ?? '',
        };
      });
    }
    return [];
  }, [clientReturnsData?.customerReturns?.data]);

  return {
    customerReturns,
    isLoading: clientReturnsLoading,
    refetchCustomerReturns,
    totalPage: clientReturnsData?.customerReturns?.meta?.pagination.pageCount ?? 0,
    total: clientReturnsData?.customerReturns?.meta?.pagination.total ?? 0,
  };
};

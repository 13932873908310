import { useMemo } from 'react';
import { Enum_Order_Status, OrderFiltersInput } from '../../../__generated__/types';
import { useGetOrdersWarehouseQuery } from '../__generated__/getOrdersWarehouse';
import { formatOrderNumber } from '../../../utils/formatOrderNumber';

export interface OrderItemWarehouseType {
  orderId: string;
  orderNumber: string;
  customerFirstName: string;
  customerLastName: string;
  customerId: string;
  stockAddressOrder: { id: string; address: string; stockId: string }[];
  orderQuantity: number;
  balanceQuantity: number;
  deliveryQuantity: number;
  status: Enum_Order_Status;
}
interface ReturnHook {
  orderItemsWarehouse: Array<OrderItemWarehouseType>;
  loading: boolean;
  totalPage: number;
  pageSize: number;
  total: number;
}

interface PropsHook {
  filters?: OrderFiltersInput;
  page?: number;
  pageSize?: number;
}
export const useGetOrdersWarehouse = ({ filters, page, pageSize }: PropsHook): ReturnHook => {
  const { data, loading } = useGetOrdersWarehouseQuery({
    variables: {
      pagination: {
        page,
        pageSize,
      },
      filters,
      sort: ['date:desc', 'orderNumber', 'orderNumberAdd'],
    },
  });

  const orderItems = useMemo(() => {
    const orderItemsWarehouse: Array<OrderItemWarehouseType> = [];
    if (data?.orders?.data.length) {
      data.orders.data.forEach(item => {
        const orderNumber = formatOrderNumber(
          item.attributes?.orderNumber,
          item.attributes?.orderNumberAdd
        );

        const arrSet = new Set();
        const stockAddressOrder: { id: string; address: string; stockId: string }[] = [];
        if (item.attributes?.order_items?.data.length) {
          item.attributes.order_items.data.forEach(i => {
            const addressId = i?.attributes?.stock?.data?.attributes?.stock_cell_address?.data?.id;
            const cellAddresses =
              i?.attributes?.stock?.data?.attributes?.stock_cell_address?.data?.attributes;
            const a = cellAddresses?.shelf || '';
            const b = cellAddresses?.cell || '';
            if (a && b) {
              arrSet.add({
                id: addressId,
                address: `${a}/${b}`,
                stockId: i.attributes?.stock?.data?.id,
              });
            }
          });
        }
        stockAddressOrder.push(
          ...(Array.from(arrSet) as { id: string; address: string; stockId: string }[])
        );

        const balanceQuantity = item.attributes?.order_items?.data.reduce((acc, i) => {
          if (i.attributes?.quantity?.outgoingQuantity) {
            return acc + i.attributes.quantity.outgoingQuantity;
          }
          return acc;
        }, 0);
        const orderQuantity = item.attributes?.order_items?.data.reduce((acc, i) => {
          if (i.attributes?.quantity?.orderedQuantity) {
            return acc + i.attributes.quantity.orderedQuantity;
          }
          return acc;
        }, 0);
        const deliveryQuantity = item.attributes?.order_items?.data.reduce((acc, i) => {
          if (i.attributes?.quantity?.deliveryQuantity) {
            return acc + i.attributes.quantity.deliveryQuantity;
          }
          return acc;
        }, 0);
        orderItemsWarehouse.push({
          orderId: item.id || '',
          orderNumber,
          customerId: item.attributes?.customer?.data?.id || '',
          customerFirstName:
            item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
              ?.firstName || '',
          customerLastName:
            item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
              ?.lastName || '',
          balanceQuantity: balanceQuantity || 0,
          orderQuantity: orderQuantity || 0,
          deliveryQuantity: deliveryQuantity || 0,
          status: item?.attributes?.status || Enum_Order_Status.Created,
          stockAddressOrder,
        });
      });
    }

    return orderItemsWarehouse;
  }, [data?.orders?.data]);

  return {
    orderItemsWarehouse: orderItems,
    loading,
    pageSize: data?.orders?.meta.pagination.total || 0,
    totalPage: data?.orders?.meta.pagination.pageCount ?? 0,
    total: data?.orders?.meta.pagination.total ?? 0,
  };
};

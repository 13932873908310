export const updateSortList = (prevSort: string[], field: string) => {
  let newSort;
  const index = prevSort.findIndex(item => item.startsWith(field));
  if (index > -1) {
    const direction = prevSort[index].split(':')?.[1]?.toLowerCase();
    if (direction === 'asc') {
      newSort = [`${field}:desc`];
    } else {
      newSort = [`${field}:asc`];
    }
  } else {
    newSort = [`${field}:asc`];
  }

  return newSort;
};

export const getSortProps = (
  sort: string[],
  field: string
): {
  direction: 'asc' | 'desc';
  active: boolean;
  fieldName: string;
} => {
  const sortString = sort.find(item => item.startsWith(field));
  if (sortString) {
    const direction = sortString.split(':')?.[1]?.toLowerCase();
    if (direction === 'asc' || direction === 'desc') {
      return {
        active: true,
        direction,
        fieldName: field,
      };
    }
    return {
      active: true,
      direction: 'asc',
      fieldName: field,
    };
  }

  return {
    active: false,
    direction: 'asc',
    fieldName: field,
  };
};

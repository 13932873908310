import React, { FC, useEffect, useMemo } from 'react';
import { Stack, Button } from '@mui/material';
import { format, isValid } from 'date-fns';

import { useLocalization } from '../../../../../localization';
import { useDefaultRangeCalendar } from '../../../../../hooks';
import { Enum_Order_Status } from '../../../../../__generated__/types';
import { RangeCalendar } from '../../../../../legos/rangeCalendar/RangeCalendar';
import { ItemSelectFilterType, SelectFilter } from '../../../../OrdersList/components/SelectFilter';
import { Enum_Order_Payment_Status } from '../../../../../graphql/queries/hook/useGetAllOrdersAccounting';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { OrdersSearchParams } from '../hooks/useAccountingOrdersSearchParams';
import { MultipleSelectFilter } from '../../../../OrdersList/components/MultipleSelectFilter';

interface Props {
  result?: string | number;
  changeParam: (params: Partial<Record<keyof OrdersSearchParams, string | string[]>>) => void;
  paymentStatus: string;
  statuses: string[];
  selectedDates: Date[];
  resetFilters: () => void;
}

const STATUSES_FILTERS_PAYMENT: ItemSelectFilterType[] = [
  ...[Enum_Order_Payment_Status.Paid, Enum_Order_Payment_Status.NotPaid].map(value => ({
    label: value,
    value,
  })),
];

const getStatusesOptions = (
  translateLang: (transVar: string) => string
): ItemSelectFilterType[] => [
  ...[
    Enum_Order_Status.Ordered,
    Enum_Order_Status.Awaiting,
    Enum_Order_Status.InStock,
    Enum_Order_Status.PickedUp,
    Enum_Order_Status.Delivery,
    Enum_Order_Status.Completed,
  ].map(value => ({
    label: translateLang(value),
    value,
  })),
];

export const AllOrdersToolbar: FC<Props> = ({
  changeParam,
  paymentStatus,
  selectedDates,
  statuses,
  resetFilters,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();

  return (
    <>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="end"
        mb={2.5}
        spacing={2}
        p={2}
        bgcolor="white"
      >
        <Stack spacing={2} flexDirection="row" justifyContent="right">
          <Stack direction="row" gap={2} alignItems="center">
            <SelectFilter
              labelFilter={translateLang('statusPayment')}
              items={STATUSES_FILTERS_PAYMENT}
              selectItem={paymentStatus}
              changeUrlParams={selectedPaymentStatus =>
                changeParam({ paymentStatus: selectedPaymentStatus })
              }
            />
            <MultipleSelectFilter
              labelFilter={translateLang('orderStatus')}
              items={getStatusesOptions(translateLang)}
              selectedItems={statuses}
              changeUrlParams={selectedStatuses => changeParam({ statuses: selectedStatuses })}
            />

            <RangeCalendar
              selectedDates={selectedDates}
              defaultRangeDates={reportRangeDates}
              setSelectedDates={dateRange => {
                changeParam({
                  dateTo: format(dateRange[1], 'yyyy-MM-dd'),
                  dateFrom: format(dateRange[0], 'yyyy-MM-dd'),
                });
              }}
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={resetFilters}
              style={{
                height: 40,
                textTransform: 'none',
              }}
            >
              <TranslatedField
                noWrap
                isTranslate
                fontSize={16}
                color="black"
                originText="resetFilters"
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

import * as React from 'react';
import { useLocalization } from '../../../localization';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, Stack, Breadcrumbs } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { PatchesName } from '../../../types/types';
import { useGetCurrentClientQuery } from '../../../graphql/cacheQuery/__generated__/getClient';
import { useGetCurrentOrderQuery } from '../../../graphql/cacheQuery/__generated__/getCurrentOrder';
import { formatClientName } from '../../../utils/getUserFullName';

export const BreadCrumps = () => {
  const { translateLang } = useLocalization();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);
  const { data: currentClient } = useGetCurrentClientQuery();
  const { data: currentOrder } = useGetCurrentOrderQuery();

  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link underline="hover" color="text.primary" href="/">
          {translateLang('main')}
        </Link>
        {pathnames.map((name, index) => {
          let to = `/${pathnames.slice(0, index + 1).join('/')}`;
          let value = name;
          let needTranslate = true;
          if (pathnames.includes(PatchesName.Accounting)) {
            if (index > 0) {
              value = '';
              to = '';
            }
          } else if (pathnames.includes(PatchesName.Clients)) {
            if (index === 1) {
              needTranslate = false;
              value =
                formatClientName(
                  currentClient?.client.firstName,
                  currentClient?.client.lastName,
                  currentClient?.client.companyName
                ) || `#${name}`;
            }
          } else if (pathnames.includes(PatchesName.Orders)) {
            if (pathnames.includes(PatchesName.CreateOrder)) {
              if (index === 1) {
                needTranslate = false;

                value =
                  formatClientName(
                    currentClient?.client.firstName,
                    currentClient?.client.lastName,
                    currentClient?.client.companyName
                  ) ||
                  `Customer ${currentClient?.client.id ? '#' + currentClient?.client.id : 'new'}`;
                to = currentClient?.client.id
                  ? `/${PatchesName.Clients}/${pathnames[index + 1]}`
                  : `/${PatchesName.Orders}/${PatchesName.CreateOrder}`;
              }
              if (index === 3) {
                needTranslate = false;

                value = `${
                  currentOrder?.currentOrder.orderNumber
                    ? `#${currentOrder?.currentOrder.orderNumber}`
                    : name
                } `;
                to = `/${PatchesName.Orders}/${currentClient?.client.id}/${name}`;
              }
              if (index === 2) {
                needTranslate = false;

                value = '';
                to = '';
              }
            } else {
              if (index === 1) {
                needTranslate = false;

                value =
                  formatClientName(
                    currentClient?.client.firstName,
                    currentClient?.client.lastName,
                    currentClient?.client.companyName
                  ) || `#${name}`;
                to = `/${PatchesName.Clients}/${name}`;
              }
              if (index === 2) {
                needTranslate = false;

                value = `${
                  currentOrder?.currentOrder.orderNumber
                    ? `#${currentOrder?.currentOrder.orderNumber}`
                    : name
                } `;
                to = `/${PatchesName.Orders}/${currentClient?.client.id}/${name}`;
              }
            }
          }

          if (value) {
            return (
              <Link underline="hover" color="text.primary" href={to} key={to}>
                {needTranslate ? translateLang(value) : value}
              </Link>
            );
          }
        })}
      </Breadcrumbs>
    </Stack>
  );
};

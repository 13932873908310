import { Box, Divider, Grid, Stack, useMediaQuery } from '@mui/material';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { theme } from '../../../helpers';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { useGetClientById } from '../../../graphql/queries/hook/useGetClientById';
import { useLocalization } from '../../../localization';
import { AddressModal, CredentialsInformationsModal, PhoneModal } from './Modals';
import { CredentialsInformations } from './СredentialsInformations';
import { AddressField } from './AddressField';
import { PhonesField } from './PhonesField';
import { UserInformaton } from './UserInformaton';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { UrlParamsObjectType } from '../../../types/types';
import { setCurrentClientCred } from '../../../graphql/store/functionsWitingCache';
import { Address } from '../../../helpers/functions';

interface Props {
  children?: JSX.Element[] | JSX.Element;
  isNewOrder: boolean;
  selectedDeliveryAddress: Address | null;
  setSelectedDeliveryAddress: Dispatch<React.SetStateAction<Address | null>>;
}

export const ClientInfoStep: FC<Props> = ({
  children,
  isNewOrder,
  selectedDeliveryAddress,
  setSelectedDeliveryAddress,
}) => {
  const { idUser = '' } = useParams<UrlParamsObjectType>();
  const { translateLang } = useLocalization();

  const { isSuperAdmin, isAccounter } = useGetMe();
  const {
    typeClient,
    typePriceClient,
    phoneNumbers,
    deliveryAddress,
    lastName,
    firstName,
    email,
    userName,
    primaryPhoneNumber,
    companyName,
    VATId,
  } = useGetClientById(idUser);

  const [openPhoneModal, setOpenPhoneModal] = useState<{ id?: string; isOpen: boolean }>({
    isOpen: false,
  });
  const [openAddressModal, setOpenAddressModal] = useState<{ id?: string; isOpen: boolean }>({
    isOpen: false,
  });
  const [openUserCredentianlsModal, setOpenUserCredentianlsModal] = useState<{
    id?: string;
    isOpen: boolean;
  }>({
    isOpen: false,
  });

  const openPhoneModals = (value?: string) => {
    setOpenPhoneModal({ id: value, isOpen: true });
  };

  const openAddressModals = (value?: string) => {
    setOpenAddressModal({ id: value, isOpen: true });
  };

  const openUserCredentianlsModals = (value?: string) => {
    setOpenUserCredentianlsModal({ id: value, isOpen: true });
  };

  useEffect(() => {
    if (isNewOrder && !selectedDeliveryAddress) {
      setSelectedDeliveryAddress(
        deliveryAddress?.find(address => address?.main) || deliveryAddress?.[0] || null
      );
    }
  }, [deliveryAddress, isNewOrder, setSelectedDeliveryAddress, selectedDeliveryAddress]);

  useEffect(() => {
    setCurrentClientCred({
      dataWrite: {
        client: {
          id: idUser,
          companyName: companyName ?? '',
          firstName: firstName ?? '',
          lastName: lastName ?? '',
        },
      },
    });
  }, [lastName, firstName, idUser, companyName]);

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        m: '10px',
        borderRadius: '10px',
        padding: '40px',
      }}
    >
      <TranslatedField
        originText={translateLang('clientRegistration')}
        isTranslate
        variant="h5"
        noWrap
        color="black"
        marginBottom="38px"
      />
      <Grid item container xs={12} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Grid item xs={12} md={7} pr="15px" justifyContent="flex-start" alignItems="flex-start">
          <Stack spacing={2}>
            <CredentialsInformations
              userName={userName}
              clientType={typeClient}
              id={idUser}
              firstName={firstName}
              lastName={lastName}
              companyName={companyName}
              VATId={VATId}
              isSuperAdminOrAccounter={isSuperAdmin || isAccounter}
              onClickInput={val => openUserCredentianlsModals(val)}
            />
            <PhonesField
              id={idUser}
              phoneNumbers={phoneNumbers}
              onClickInput={val => openPhoneModals(val)}
            />

            <AddressField
              userId={idUser}
              deliveryAddress={deliveryAddress}
              selectedDeliveryAddress={selectedDeliveryAddress}
              setSelectedDeliveryAddress={setSelectedDeliveryAddress}
              onClickInput={val => openAddressModals(val)}
            />
          </Stack>
        </Grid>
        <Divider
          sx={{ margin: '15px' }}
          orientation={useMediaQuery(theme.breakpoints.down('md')) ? 'horizontal' : 'vertical'}
          flexItem
        />
        <Grid item xs={12} md={3} pl="15px" justifyContent="flex-start" alignItems="flex-start">
          <Stack justifyContent="space-around" spacing={3}>
            <UserInformaton
              id={idUser}
              typeClient={typePriceClient}
              email={email}
              primaryPhoneNumber={primaryPhoneNumber}
            />
            {children}
          </Stack>
        </Grid>
      </Grid>

      {openPhoneModal.isOpen && (
        <PhoneModal
          clientId={idUser}
          id={openPhoneModal.id}
          openModal={true}
          closeModal={() => setOpenPhoneModal({ id: '', isOpen: false })}
        />
      )}
      {openAddressModal.isOpen && (
        <AddressModal
          isLegalAddress={false}
          clientId={idUser}
          id={openAddressModal.id}
          openModal={true}
          closeModal={() => setOpenAddressModal({ id: '', isOpen: false })}
        />
      )}
      {openUserCredentianlsModal.isOpen && (
        <CredentialsInformationsModal
          clientId={idUser}
          openModal={true}
          closeModal={() => setOpenUserCredentianlsModal({ id: '', isOpen: false })}
        />
      )}
    </Box>
  );
};

export const getStoredParams = (key: string) => {
  try {
    const storedParams = sessionStorage.getItem(key);
    return storedParams ? JSON.parse(storedParams) : {};
  } catch (e) {
    return {};
  }
};

export const saveParamsToStorage = (params: URLSearchParams, key: string) => {
  try {
    const paramsObject: Record<string, string | string[]> = {};
    params.forEach((value, key) => {
      if (paramsObject[key]) {
        if (Array.isArray(paramsObject[key])) {
          (paramsObject[key] as string[]).push(value);
        } else {
          paramsObject[key] = [paramsObject[key] as string, value];
        }
      } else {
        paramsObject[key] = value;
      }
    });
    sessionStorage.setItem(key, JSON.stringify(paramsObject));
  } catch (e) {
    return;
  }
};

export const resetParamsInStore = (key: string) => {
  sessionStorage.removeItem(key);
};

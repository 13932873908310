import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type SpecialProposalFragment = { __typename?: 'SpecialProposalEntity', id?: string | null, attributes?: { __typename?: 'SpecialProposal', name?: string | null, system_name?: Types.Enum_Specialproposal_System_Name | null, generalSpares?: { __typename?: 'GeneralSparesRelationSpecialProposalCollection', data: Array<{ __typename?: 'GeneralSparesEntity', id: string, attributes?: { __typename?: 'GeneralSpare', brandId?: number | null, brandName?: string | null, images?: any | null, price?: number | null, tecdoc_articleNumber?: string | null, title?: string | null } | null }> } | null } | null };

export const SpecialProposalFragmentDoc = gql`
    fragment specialProposal on SpecialProposalEntity {
  id
  attributes {
    name
    system_name
    generalSpares {
      data {
        id
        attributes {
          brandId
          brandName
          images
          price
          tecdoc_articleNumber
          title
        }
      }
    }
  }
}
    `;
import React, { FC, MouseEventHandler, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, Stack } from '@mui/material';

import { Input } from '../../../legos';
import { VehiclesType } from '../../../helpers/types';
import { useLocalization } from '../../../localization';
import { handlerError } from '../../../helpers/functions';
import { UrlParamsObjectType } from '../../../types/types';
import { useGetManagers } from '../../CheckoutPage/hooks/useGetManagers';
import { useGetOrderByIdLazy } from '../../../graphql/queries/hook/useGetOrderById';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { useUpdateOrderMutation } from '../../../graphql/mutations/__generated__/updateOrder';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  isSelect?: boolean;
  onClickItem(): void;
  isStepper?: boolean;
  vehicle: VehiclesType;
  isLastElement?: boolean;
  vinCodeState?: string;
  setVinCodeState: React.Dispatch<React.SetStateAction<string>>;
}
const INPUT_PROPS = {
  sx: { flex: 1, height: '46', minWidth: '200px' },
};

export const ItemVehicle: FC<Props> = ({
  vehicle,
  isStepper,
  onClickItem,
  vinCodeState,
  setVinCodeState,
}) => {
  const { idOrder = '' } = useParams<UrlParamsObjectType>();

  const { selectCar, orderItems, vinCode: orderVinCode } = useGetOrderByIdLazy(idOrder);
  const { addNotification } = useHandlerNotificationApp();
  const [runOrderUpdateMutation] = useUpdateOrderMutation();
  const { managers } = useGetManagers(idOrder);
  const { carID, vinCode, carModel, carBrand, carBodyType, modificationCar, yearOfManufacture } =
    vehicle;

  const { translateLang, updateSelectLanguage } = useLocalization();
  useEffect(() => {
    setVinCodeState(orderVinCode ?? '');
  }, [orderVinCode, setVinCodeState]);
  const handlerUpdateVehicle: MouseEventHandler<HTMLDivElement> = (event): void => {
    if ((event.target as Element)?.tagName === 'INPUT') {
      onClickItem();
    }
  };

  const handleSelect = () => {
    if (isSelectCar) {
      try {
        runOrderUpdateMutation({
          variables: {
            id: idOrder,
            data: {
              car: null,
              managers,
              vinCode: null,
            },

            locale: updateSelectLanguage as string,
          },
        }).then();
        setVinCodeState('');
      } catch (err: unknown) {
        handlerError(err);
        addNotification({ messageError: 'Order Item Error', typeMessage: 'error' });
      }
    } else {
      try {
        runOrderUpdateMutation({
          variables: {
            id: idOrder,
            data: {
              car: carID,
              managers,
              vinCode,
            },

            locale: updateSelectLanguage as string,
          },
        }).then();
        setVinCodeState(vinCode ?? '');
      } catch (err: unknown) {
        handlerError(err);
        addNotification({ messageError: 'Order Item Error', typeMessage: 'error' });
      }
    }
  };

  const inputStackCar = [
    { label: 'car_brand', value: carBrand },
    { label: 'yearOfManufacture', value: yearOfManufacture },
    { label: 'modifications_car', value: modificationCar },
  ];
  const inputCar = [
    { label: 'vinCode', value: vinCode },
    { label: 'car_model', value: carModel },
    { label: 'car_body_type', value: carBodyType },
  ];

  const isSelectCar = vehicle.vinCode === vinCodeState;
  const disableButtonSelectAuto = orderItems && orderItems?.length > 0 && selectCar?.data;

  return (
    <div onClick={handlerUpdateVehicle}>
      <Box
        border={isSelectCar ? 2 : undefined}
        borderColor="secondary.main"
        borderRadius={8}
        mb={3}
        padding={2}
      >
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="flex-end"
          flex-wrap="wrap"
          mb={2}
        >
          {inputStackCar.map((item, index) => (
            <Grid minWidth="200px" item xs={3} key={index.toString()}>
              <Input label={translateLang(item.label)} {...INPUT_PROPS} value={item.value} />
            </Grid>
          ))}

          <Grid minWidth="200px" item xs={3} mt={2}>
            {isStepper && idOrder && (
              <Button
                disabled={!!disableButtonSelectAuto}
                onClick={handleSelect}
                variant="outlined"
                color="primary"
                style={{
                  width: '100%',
                  height: '47px',
                  textTransform: 'none',
                }}
              >
                <TranslatedField
                  noWrap
                  isTranslate
                  fontSize={16}
                  color={!disableButtonSelectAuto ? 'black' : '#969696'}
                  originText={translateLang(isSelectCar ? 'unSelectAuto' : 'selectAuto')}
                />
              </Button>
            )}
          </Grid>
        </Grid>
        <Stack pb={1} spacing={2} direction="row" alignItems="flex-end" borderColor="#9499A9">
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
            flex-wrap="wrap"
            mb={1}
          >
            {inputCar.map((item, index) => (
              <Grid item xs={3} key={index.toString()} minWidth="200px">
                <Input
                  key={index.toString()}
                  label={translateLang(item.label)}
                  {...INPUT_PROPS}
                  value={item.value}
                />
              </Grid>
            ))}
            <Grid item xs={3}></Grid>
          </Grid>
        </Stack>
      </Box>
      <Box borderBottom={0.5} mb={3} borderColor="#656263"></Box>
    </div>
  );
};

import React, { FC, JSX, useEffect, useRef, useState } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useDebounce } from '@uidotdev/usehooks';

export const SearchInput: FC<
  TextFieldProps & { endAdornment?: JSX.Element; onChangeText: (text: string | undefined) => void }
> = ({ endAdornment, onChangeText, value, ...props }) => {
  const [innerValue, setInnerValue] = useState(value);
  const valueDebounced = useDebounce(innerValue, 500);

  useEffect(() => {
    onChangeText(valueDebounced as string);
  }, [valueDebounced]);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return (
    <TextField
      id="filled-basic"
      fullWidth
      onChange={e => setInnerValue(e.target.value)}
      value={innerValue}
      variant="filled"
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment,
      }}
      sx={{
        '& .MuiInputAdornment-root': {
          marginTop: '0 !important',
        },
        '& input': {
          padding: '18px 17px',
        },
        '& .MuiFilledInput-root': {
          backgroundColor: 'rgba(255,255,255,1)',
        },
      }}
    />
  );
};

import React, { Dispatch, SetStateAction } from 'react';
import { TableClients } from '../../ClientsList/components/TableClients';
import { CustomModal } from '../../../components';
import {
  UpdateOrderMutationVariables,
  useUpdateOrderMutation,
} from '../../../graphql/mutations/__generated__/updateOrder';
import { useLocalization } from '../../../localization';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
interface Props {
  id: string;
  open: boolean;
  handleClose: () => void;
  setSelectClientModal: Dispatch<SetStateAction<boolean>>;
}

export const SelectClientModal = ({ id, open, handleClose, setSelectClientModal }: Props) => {
  const [runUpdateOrder] = useUpdateOrderMutation();
  const { updateSelectLanguage } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const updateClient = async (idUser: string) => {
    const variables: UpdateOrderMutationVariables = {
      id: id,
      data: {
        customer: idUser,
      },
      locale: updateSelectLanguage as string,
    };
    try {
      const response = await runUpdateOrder({ variables });
      if (response && response?.data) {
        addNotification({
          messageError: 'Update order success!!!',
          typeMessage: 'success',
        });
      }
    } catch (error) {
      addNotification({
        messageError: String(error),
        typeMessage: 'error',
      });
    }
  };

  return (
    <CustomModal
      title="Clients"
      handleClose={handleClose}
      open={open}
      bgcolor="#F1F3F8"
      display="flex"
      flexDirection="column"
      fullWidth={true}
    >
      <TableClients
        isCreateOrder
        handlerCellClick={idUser => {
          updateClient(String(idUser));
        }}
        isClientModal={true}
        setSelectClientModal={setSelectClientModal}
      />
    </CustomModal>
  );
};

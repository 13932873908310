import React, { Dispatch, ReactNode, SetStateAction, useCallback } from 'react';
import { HeaderCell } from '../../../components/TableProduct/TableProduct';
import { useLocalization } from '../../../localization';
import { TableSelectedSpareRowItem } from '../components/tablesComponents/TableSelectedSpareRowItem';
import { DataModalType, OrderItemType } from '../../../helpers/types';

interface Props {
  orderItems: Array<OrderItemType>;
  setDataDeleteModal: Dispatch<SetStateAction<DataModalType<string>>>;
  setCommentModal: Dispatch<SetStateAction<DataModalType<{ id: string; comment: string }>>>;
  setChangeQuantityModal: Dispatch<SetStateAction<DataModalType<OrderItemType>>>;
  setChangeCustomerPriceModal: Dispatch<SetStateAction<DataModalType<OrderItemType>>>;
  idOrder: string;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): ReactNode[];
}

export const useGetCartTable = ({
  setDataDeleteModal,
  setChangeQuantityModal,
  setChangeCustomerPriceModal,
  setCommentModal,
  orderItems,
  idOrder,
}: Props): ReturnHook => {
  const { translateLang, selectLanguages } = useLocalization();
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: { width: '20px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('manufacturer'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('provider'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('_delivery'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('quantity'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },

      {
        title: translateLang('discount'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('customerPrice'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productComment'),
        containerProps: { width: '260px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('amount'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('del'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
    ];
  };
  const getRowsTableActive = useCallback((): ReactNode[] => {
    if (orderItems && orderItems.length) {
      return orderItems?.map((item, index) => {
        return (
          <TableSelectedSpareRowItem
            locale={selectLanguages}
            idOrder={idOrder}
            key={item.id}
            itemRow={item}
            id={item.id || ''}
            index={index}
            setDataDeleteModal={setDataDeleteModal}
            changeQuantity={() =>
              setChangeQuantityModal({
                isOpen: true,
                selProduct: item,
              })
            }
            changeCustomerPrice={() =>
              setChangeCustomerPriceModal({
                isOpen: true,
                selProduct: item,
              })
            }
            openModalComment={() =>
              setCommentModal({
                isOpen: true,
                selProduct: { id: item?.id ?? '', comment: item?.comment ?? '' },
              })
            }
          />
        );
      });
    }
    return [];
  }, [orderItems]);

  return { getHeadersTable, getRowsTableActive };
};

import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

import { DeviderModal } from './components/deviderOrder/DeviderModal';
import { CompletedOrderTabTable } from './CompletedOrderTabTable';
import { ToolBarCompletedOrderTab } from './ToolBarCompletedOrderTab';
import { setDataDeviderState } from '../../../../graphql/store/functionsWitingCache';
import { useGetOrdersWarehouse } from '../../../../graphql/queries/hook/useGetOrdersWarehouse';
import { useDataDeviderStateQuery } from '../../../../graphql/cacheQuery/__generated__/getDevidedData';
import { HelpSearchOrder } from '../../../../components/HelpSearchOrder';
import { useWarehouseOrderTabSearchParams } from './hooks/useWarehouseOrderTabSearchParams';
import { PAGE_COUNT } from '../../../../helpers/constants';
import { SearchInput } from '../../../../components/SearchInput';
import { useLocalization } from '../../../../localization';

export const OrderTab = () => {
  const { translateLang } = useLocalization();
  const { data: dataDevider } = useDataDeviderStateQuery();
  const [openModal, setOpenModal] = useState(dataDevider?.dataDeviderState?.isOpenModal ?? false);

  const { page, filters, statuses, searchText, changeParam, changSearchText } =
    useWarehouseOrderTabSearchParams();

  const { orderItemsWarehouse, loading, totalPage, total } = useGetOrdersWarehouse({
    filters,
    page: parseInt(page),
    pageSize: PAGE_COUNT,
  });

  const clearDataDevider = () => {
    setDataDeviderState({
      dataWrite: {
        dataDeviderState: {
          idOrder: '',
          orderNumber: '',
          isOpenModal: false,
        },
      },
    });
  };

  useEffect(() => {
    setOpenModal(dataDevider?.dataDeviderState?.isOpenModal ?? false);
  }, [dataDevider?.dataDeviderState?.isOpenModal]);

  return (
    <Stack width="100%">
      <SearchInput
        value={searchText}
        onChangeText={text => changSearchText(text)}
        placeholder={translateLang('searchOrdersAccountingLabel')}
        endAdornment={<HelpSearchOrder />}
      />
      <ToolBarCompletedOrderTab result={total} statuses={statuses} changeParam={changeParam} />
      <CompletedOrderTabTable
        loading={loading}
        currentPage={parseInt(page)}
        totalPage={totalPage}
        changePage={page => changeParam({ page: `${page + 1}` })}
        orderItems={orderItemsWarehouse}
      />
      {openModal && (
        <DeviderModal
          open={openModal}
          closeModal={() => clearDataDevider()}
          idOrder={dataDevider?.dataDeviderState?.idOrder || ''}
          orderNumber={dataDevider?.dataDeviderState?.orderNumber || ''}
        />
      )}
    </Stack>
  );
};

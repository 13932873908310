import React, { FC } from 'react';
import { format } from 'date-fns';
import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material';

import { useLocalization } from '../../../../localization';
import { useDefaultRangeCalendar } from '../../../../hooks';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import { Enum_Customerreturn_Status } from '../../../../__generated__/types';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { WarehouseReturnsFromCustomerTabSearchParams } from './hooks/useWarehouseReturnsFromCustomerTabSearchParams';

interface Props {
  result?: string | number;
  changeParam: (
    params: Partial<Record<keyof WarehouseReturnsFromCustomerTabSearchParams, string | string[]>>
  ) => void;
  selectedDates: Date[];
  resetFilters: () => void;
  status?: string;
}

export type NoSpareStatusType = {
  label: string;
  value: string;
};

export type ReturnStatusEditingHistory = {
  value: Enum_Customerreturn_Status;
  label: string;
};

export const ToolBarReturnsTab: FC<Props> = ({
  result,
  changeParam,
  resetFilters,
  selectedDates,
  status,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();

  const warehouseReturnStatusOptions: ReturnStatusEditingHistory[] = [
    ...[Enum_Customerreturn_Status.Created, Enum_Customerreturn_Status.Completed].map(value => ({
      value: value,
      label: value ? translateLang(value) : '',
    })),
  ];

  return (
    <WrapperToolBar>
      <Stack width="100%" flexDirection="row" justifyContent="left">
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || ''}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <Stack direction="row" gap={2} alignItems="center">
          <TranslatedField originText={translateLang('status')} />
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ minWidth: 300 }}
            value={warehouseReturnStatusOptions.find(item => item.value === status)}
            options={warehouseReturnStatusOptions}
            isOptionEqualToValue={(option, value) => option?.label === value?.label}
            renderInput={params => <TextField {...params} />}
            getOptionLabel={option => {
              return option?.label || '';
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
            )}
            onChange={(_, option) => changeParam({ status: option?.value })}
            onInputChange={(e, value, reason) => {
              if (reason === 'clear') {
                changeParam({ status: '' });
              }
            }}
          />
        </Stack>

        <Stack spacing={2} bgcolor="white" flexDirection="row" justifyContent="right">
          <Stack direction="row" gap={2} alignItems="center">
            <RangeCalendar
              selectedDates={selectedDates}
              defaultRangeDates={reportRangeDates}
              setSelectedDates={dateRange => {
                changeParam({
                  dateTo: format(dateRange[1], 'yyyy-MM-dd'),
                  dateFrom: format(dateRange[0], 'yyyy-MM-dd'),
                });
              }}
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={() => resetFilters()}
              style={{
                width: '100%',
                textTransform: 'none',
              }}
            >
              <TranslatedField
                noWrap
                isTranslate
                fontSize={16}
                color="black"
                originText="resetFilters"
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </WrapperToolBar>
  );
};

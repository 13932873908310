import React, { FC, useEffect, useState } from 'react';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { Autocomplete, Box, Stack, TextField, UseAutocompleteProps } from '@mui/material';
import { useLocalization } from '../../../localization';

export type ItemSelectFilterType = {
  label: string;
  value: string;
};
interface Props {
  selectItem: string | string[];
  labelFilter: string;
  items?: Array<ItemSelectFilterType>;
  changeUrlParams(val?: string): void;
}
export const SelectFilter: FC<Props> = ({
  items = [],
  selectItem,
  changeUrlParams,
  labelFilter,
}) => {
  const { translateLang } = useLocalization();
  const [keyResetFilter, setKeyResetFilter] = useState(Math.random().toString().slice(1, -1));

  const handleChangeFilter: UseAutocompleteProps<
    ItemSelectFilterType,
    undefined,
    undefined,
    undefined
  >['onChange'] = (evt, option): void => {
    if (option) {
      const _option = option as ItemSelectFilterType;
      changeUrlParams(_option?.label || '');
    }
  };
  const getDefaultValue = () => {
    if (selectItem) {
      const index = items.findIndex(item => item.label === selectItem);
      if (index !== -1) {
        return items[index];
      }
    }
    return null;
  };

  useEffect(() => {
    if (!selectItem) {
      setKeyResetFilter(Math.random().toString().slice(1, -1));
    }
  }, [selectItem]);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TranslatedField originText={translateLang(labelFilter)} />
      <Autocomplete
        key={keyResetFilter}
        size="small"
        disablePortal
        id="combo-box-demo"
        sx={{ minWidth: 200 }}
        options={items}
        value={getDefaultValue()}
        renderInput={params => <TextField {...params} />}
        getOptionLabel={option => {
          return translateLang(option?.value) || '';
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.label}>
            {translateLang(option.value)}
          </Box>
        )}
        onChange={handleChangeFilter}
        onInputChange={(e, value, reason) => {
          if (reason === 'clear') {
            changeUrlParams();
          }
        }}
      />
    </Stack>
  );
};

import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, IconButton, Stack, Typography, styled } from '@mui/material';
import { ApolloQueryResult } from '@apollo/client';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { format } from 'date-fns';

import {
  GeneralSparesEntity,
  OrderStaffItemInput,
  PriceByRole,
} from '../../../../__generated__/types';
import {
  CellTableProps,
  TableProductActive,
} from '../../../../components/TableProductActive/TableProductActive';
import logo from '../../../../assets/logotype.svg';
import { CounterQuantity } from '../CounterQuantity';
import { UrlParamsObjectType } from '../../../../types/types';
import { CustomAvatar, CustomModal } from '../../../../components';
import { getMarkupForRolePrice } from '../../hooks/useGetPriceMarkup';
import { CarouselWithPreview } from '../../../../components/CarouselWithPreview';
import { useGetClientById } from '../../../../graphql/queries/hook/useGetClientById';
import { AddCircleOutline, Info } from '@mui/icons-material';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { getFittingPosition } from '../../../../helpers/functions';
import { AddCapacityModal } from '../../../ManagerPanel/AddCapacityModal';
import { GetGeneralSparesQuery } from '../../../../graphql/queries/__generated__/getGeneralSpares';
import { roundAmountToNumber } from '../../../../utils/currencyUtils';
import { CharacteristicsItem } from '../../../../components/CharachteristicsItem';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface Props {
  product: GeneralSparesEntity;
  handlerButtonAddOrderItem(params: Omit<OrderStaffItemInput, 'orderId'>): Promise<void>;
  handleRefetchCarSpares(): Promise<ApolloQueryResult<GetGeneralSparesQuery>>;
}
interface CharacteristicsProps {
  formattedValue: string;
  criteriaDescription: string;
}

const getDate = (countDey?: number | null): string => {
  const mockDate = new Date();
  mockDate.setDate(mockDate.getDate() + (countDey || 0));
  return format(mockDate, 'dd MMM');
};

export const TableSpareRowItem: FC<Props> = ({
  product,
  handlerButtonAddOrderItem,
  handleRefetchCarSpares,
}) => {
  const { idUser = '' } = useParams<UrlParamsObjectType>();
  const { typePriceClient } = useGetClientById(idUser);
  const [counts, setCounts] = useState<Array<number>>([1]);

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCapacityModalOpen, setIsCapacityModalOpen] = useState(false);

  // Normilize product data for table
  const {
    urlImage,
    images,
    fittingPosition,
    descriptionInfo,
    tecdocArticleId,
    articleCriteria,
    title,
    brandName,
    brandId,
    suppliersSpare,
  } = {
    images: product.attributes?.images,
    urlImage: product.attributes?.images?.length ? product.attributes?.images[0].imageURL200 : '',
    fittingPosition: getFittingPosition(product.attributes?.articleCriteria),
    descriptionInfo: product.attributes?.descriptionsInfo[0] || '',
    articleCriteria: product.attributes?.articleCriteria || '',
    tecdocArticleId: product.attributes?.tecdoc_articleNumber || '',
    title: product.attributes?.title || '',
    brandName: product.attributes?.brandName || '',
    brandId: product.attributes?.brandId || 0,
    suppliersSpare: product.attributes?.suppliersSpare?.length
      ? product.attributes.suppliersSpare
      : [],
  };
  const handleCountChange = (index: number, newCount: number) => {
    const _counts = [...counts];
    _counts[index] = newCount;
    setCounts(_counts);
  };
  const handlerPrice = (index: number, priceByRole?: PriceByRole | null): number => {
    if (priceByRole) {
      const price = getMarkupForRolePrice(typePriceClient, priceByRole);
      if (index >= 0) {
        return roundAmountToNumber(price * counts[index]);
      }
      return price;
    }
    return 0;
  };

  type HandlerType = {
    index: number;
    delivery_day: number;
    external_id: string;
    spareId: string;
    priceByRole?: PriceByRole | null;
  };
  const handlerButton = ({
    index,
    delivery_day,
    external_id,
    spareId,
    priceByRole,
  }: HandlerType) => {
    const _counts = [...counts];
    _counts[index] = 1;
    setCounts(_counts);
    const date = new Date();
    date.setDate(date.getDate() + delivery_day);

    handlerButtonAddOrderItem({
      brandId: brandId,
      spareId,
      external_id,
      discount: 0,
      price: handlerPrice(-1, priceByRole),
      quantity: counts[index],
      tecdoc_articleNumber: tecdocArticleId,
      deliveryDate: date.toISOString().split('T')[0],
    }).then();
  };

  const heightRow = suppliersSpare ? suppliersSpare?.length * 70 : 70;

  const cellProps: Omit<CellTableProps, 'children'> = {
    ceilStyle: {
      borderLeft: '1px solid #8D8D8D',
      height: `${heightRow < 100 ? 100 : heightRow}px`,
      minHeight: '100px',
      padding: '0 5px',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    styleTd: { valign: 'top' },
  };

  useEffect(() => {
    if (suppliersSpare && suppliersSpare.length) {
      const arr = suppliersSpare.map(item => {
        if (item?.minOrderQuantity && item.minOrderQuantity > 1) {
          return item.minOrderQuantity;
        }
        return 1;
      });
      setCounts(arr);
    }
  }, [suppliersSpare]);

  const handleCloseModal = (isRefetch?: boolean) => {
    isRefetch && setLoading(true);
    isModalOpen && setIsModalOpen(false);
    if (isCapacityModalOpen) {
      isRefetch && handleRefetchCarSpares().finally(() => setLoading(false));
      setIsCapacityModalOpen(false);
    }
  };

  const combinedContent = (
    <React.Fragment>
      {articleCriteria.map(
        ({ criteriaDescription, formattedValue }: CharacteristicsProps, index: number) =>
          criteriaDescription !== 'Fitting Position' ? (
            <CharacteristicsItem
              key={index}
              characteristic={criteriaDescription}
              value={formattedValue}
            />
          ) : null
      )}
    </React.Fragment>
  );
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  return (
    <>
      <TableProductActive.RowTable>
        <TableProductActive.CellTable
          ceilStyle={{
            width: 'auto !important',
            minWidth: '120px',
            height: `${100}px`,
            mx: 1.25,
            padding: '5px ',
            bgcolor: '#D9D9D9',
            borderLeft: 'none',
            borderRadius: '10px',
          }}
        >
          <CustomAvatar
            alt="Product photo"
            src={urlImage}
            logo={logo}
            variant="rounded"
            sx={{
              width: '100%',
              height: '100%',
              '& img': {
                objectFit: 'contain',
              },
            }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          />
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          <SubContainer height="100%">{tecdocArticleId}</SubContainer>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          <Stack
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            height="100%"
            sx={{ overflowY: 'scroll' }}
          >
            {title}
          </Stack>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          <SubContainer height="100%">
            {
              <Stack gap={1}>
                <Stack>{fittingPosition}</Stack>
                <Box display="flex" justifyContent="center">
                  <Stack>
                    {descriptionInfo.length !== 0 ? (
                      <Tooltip
                        title={
                          <TranslatedField
                            originText={descriptionInfo}
                            fontSize={16}
                            isTranslate
                            noWrap={false}
                          />
                        }
                      >
                        <Info sx={{ color: '#97a408' }} />
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </Stack>
                  <Stack>
                    {articleCriteria.length !== 0 ? (
                      <CustomTooltip title={combinedContent}>
                        <Info sx={{ color: '#5269a3' }} />
                      </CustomTooltip>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Box>
              </Stack>
            }
          </SubContainer>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          <SubContainer height="100%">{brandName}</SubContainer>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          {suppliersSpare?.map(item => (
            <SubContainer
              key={`name-${item?.id}-${item?.supplier?.id}`}
              height={suppliersSpare?.length === 1 ? '100px' : undefined}
            >
              {item?.supplier?.name}
            </SubContainer>
          ))}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          {suppliersSpare?.map(item => (
            <SubContainer
              key={`amount-${item?.id}-${item?.supplier?.id}`}
              height={suppliersSpare?.length === 1 ? '100px' : undefined}
            >
              {item?.amount || 0}
            </SubContainer>
          ))}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          {suppliersSpare?.map(item => (
            <SubContainer
              key={`delivery-${item?.id}-${item?.supplier?.id}`}
              height={suppliersSpare?.length === 1 ? '100px' : undefined}
            >
              {getDate(item?.supplier?.deliveryDay)}
            </SubContainer>
          ))}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          {suppliersSpare?.map((item, index) => (
            <SubContainer
              key={`min-amount-${item?.id}-${item?.supplier?.id}`}
              height={suppliersSpare?.length === 1 ? '100px' : undefined}
            >
              <CounterQuantity
                disabled={!item?.amount || item?.amount === '0'}
                minOrderQuantity={item?.minOrderQuantity}
                indexCounter={index}
                count={counts[index]}
                limit={item?.amount ? +item.amount.replace(/[^0-9]/g, '') : 0}
                handlerCountChange={handleCountChange}
              />
            </SubContainer>
          ))}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          {suppliersSpare?.map((item, index) => (
            <SubContainer
              key={`price-${item?.id}-${item?.supplier?.id}`}
              height={suppliersSpare?.length === 1 ? '100px' : undefined}
            >
              <Box pl={1}>
                {`${handlerPrice(index, item?.priceByRole).toFixed(2)}  (${item?.supplierPrice})`}
              </Box>
            </SubContainer>
          ))}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          <Stack>
            {suppliersSpare?.map((item, index) => (
              <SubContainer
                key={`add-cart-${item?.id}-${item?.supplier?.id}`}
                height={suppliersSpare?.length === 1 ? '100px' : undefined}
              >
                <IconButton
                  disabled={!item?.amount || item?.amount === '0'}
                  onClick={() =>
                    handlerButton({
                      index: index,
                      delivery_day: item?.supplier?.deliveryDay ?? 0,
                      external_id: item?.external_id ?? '',
                      spareId: item?.id ?? '',
                      priceByRole: item?.priceByRole,
                    })
                  }
                  sx={{
                    color: '#97A408',
                  }}
                >
                  <ShoppingCartCheckoutIcon />
                </IconButton>
              </SubContainer>
            ))}
          </Stack>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable {...cellProps}>
          <SubContainer height="100%">
            <Tooltip
              title={
                <TranslatedField originText={'addСapacity'} fontSize={16} isTranslate noWrap />
              }
              arrow
            >
              <IconButton
                onClick={() => {
                  setIsCapacityModalOpen(true);
                }}
                sx={{
                  color: '#97A408',
                }}
              >
                {loading ? <CircularProgress size={20} /> : <AddCircleOutline />}
              </IconButton>
            </Tooltip>
          </SubContainer>
        </TableProductActive.CellTable>
      </TableProductActive.RowTable>

      <CustomModal
        width="100%"
        maxWidth="xl"
        open={isModalOpen}
        handleClose={handleCloseModal}
        title=""
      >
        <CarouselWithPreview images={images} />
      </CustomModal>
      {isCapacityModalOpen && (
        <AddCapacityModal
          isOpen={isCapacityModalOpen}
          closeModal={handleCloseModal}
          product={product}
        />
      )}
    </>
  );
};

interface SubProps {
  children?: ReactNode | string;
  height?: string;
  disabled?: boolean;
}
const SubContainer: FC<SubProps> = ({ children, disabled, height }) => {
  return (
    <Box
      height={height ? height : '70px'}
      width="100%"
      display="flex"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      bgcolor={disabled ? '#d5d5d5' : 'none'}
    >
      {children}
    </Box>
  );
};

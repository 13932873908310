import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BackupSetting",
      "Banner",
      "Car",
      "CarBrand",
      "CarBrandLocale",
      "CarCategory",
      "CarLocale",
      "CarModel",
      "CarModelLocale",
      "CarSpare",
      "CarSpareBrand",
      "CarSpareLocale",
      "Category",
      "ComponentClientDeliveryAddress",
      "ComponentClientEmail",
      "ComponentClientPhoneNumbers",
      "ComponentDiscountDiscount",
      "ComponentOrderStatusEditingHistory",
      "ComponentReviewReview",
      "ContactNotification",
      "ContentReleasesRelease",
      "ContentReleasesReleaseAction",
      "CustomerAccount",
      "CustomerAccountTransaction",
      "CustomerBill",
      "CustomerContactInfo",
      "CustomerPayment",
      "CustomerReturn",
      "CustomerReturnItem",
      "I18NLocale",
      "IncomingInvoice",
      "JobLog",
      "Markup",
      "NewsletterSubscription",
      "NoSparePart",
      "Order",
      "OrderItem",
      "PaymentMethod",
      "Review",
      "Spare",
      "SpareArchive",
      "SpecialProposal",
      "StaffSetting",
      "Stock",
      "StockCellAddress",
      "StockHistory",
      "StockReturnedFromCustomer",
      "Supplier",
      "SupplierAccount",
      "SupplierPayment",
      "SupplierPaymentDetail",
      "SupplierReturn",
      "SupplierReturnItem",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vehicle"
    ]
  }
};
      export default result;
    
export type GetCustomerReturnQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  locale?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetCustomerReturnQuery = { __typename?: 'Query', customerReturn?: { __typename?: 'CustomerReturnEntityResponse', data?: { __typename?: 'CustomerReturnEntity', id?: string | null, attributes?: { __typename?: 'CustomerReturn', status: Types.Enum_Customerreturn_Status, createdAt?: any | null, total?: number | null, customer_return_items?: { __typename?: 'CustomerReturnItemRelationResponseCollection', data: Array<{ __typename?: 'CustomerReturnItemEntity', id?: string | null, attributes?: { __typename?: 'CustomerReturnItem', quantityReturned: number, createdAt?: any | null, stock?: { __typename?: 'StockEntityResponse', data?: { __typename?: 'StockEntity', id?: string | null, attributes?: { __typename?: 'Stock', supplierPrice?: number | null, incomingQuantity?: number | null, incoming_invoice?: { __typename?: 'IncomingInvoiceEntityResponse', data?: { __typename?: 'IncomingInvoiceEntity', id?: string | null, attributes?: { __typename?: 'IncomingInvoice', date: any, supplierInvoiceNumber?: string | null, supplierInvoiceDate?: any | null, supplierInvoiceTotal?: number | null } | null } | null } | null, car_spare?: { __typename?: 'CarSpareEntityResponse', data?: { __typename?: 'CarSpareEntity', id?: string | null, attributes?: { __typename?: 'CarSpare', tecdoc_articleNumber?: string | null, car_spare_locales?: { __typename?: 'CarSpareLocaleRelationResponseCollection', data: Array<{ __typename?: 'CarSpareLocaleEntity', id?: string | null, attributes?: { __typename?: 'CarSpareLocale', title?: string | null } | null }> } | null } | null } | null } | null } | null } | null } | null } | null }> } | null } | null } | null } | null };


export const GetCustomerReturnDocument = gql`
    query getCustomerReturn($id: ID, $locale: String) {
  customerReturn(id: $id) {
    data {
      id
      attributes {
        status
        createdAt
        total
        customer_return_items {
          data {
            id
            attributes {
              quantityReturned
              createdAt
              stock {
                data {
                  id
                  attributes {
                    supplierPrice
                    incomingQuantity
                    incoming_invoice {
                      data {
                        id
                        attributes {
                          date
                          supplierInvoiceNumber
                          supplierInvoiceDate
                          supplierInvoiceTotal
                        }
                      }
                    }
                    car_spare {
                      data {
                        id
                        attributes {
                          tecdoc_articleNumber
                          car_spare_locales(filters: {locale: {eq: $locale}}) {
                            data {
                              id
                              attributes {
                                title
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCustomerReturnQuery__
 *
 * To run a query within a React component, call `useGetCustomerReturnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerReturnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerReturnQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetCustomerReturnQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerReturnQuery, GetCustomerReturnQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerReturnQuery, GetCustomerReturnQueryVariables>(GetCustomerReturnDocument, options);
      }
export function useGetCustomerReturnLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerReturnQuery, GetCustomerReturnQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerReturnQuery, GetCustomerReturnQueryVariables>(GetCustomerReturnDocument, options);
        }
export type GetCustomerReturnQueryHookResult = ReturnType<typeof useGetCustomerReturnQuery>;
export type GetCustomerReturnLazyQueryHookResult = ReturnType<typeof useGetCustomerReturnLazyQuery>;
export type GetCustomerReturnQueryResult = Apollo.QueryResult<GetCustomerReturnQuery, GetCustomerReturnQueryVariables>;
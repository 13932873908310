import React from 'react';

import { Box } from '@mui/material';
import { AllOrdersToolbar } from './AllOrdersToolbar';
import { AllOrdersTable } from './AllOrdersTable';
import { useOrdersForDelivery } from '../../../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { addDays, format } from 'date-fns';
import { useDeliveryOrdersTabSearchParams } from '../hooks/useDeliveryOrdersTabSearchParams';
import { PAGE_COUNT } from '../../../../../helpers/constants';

export const AllOrdersTab = () => {
  const {
    page,
    filters,
    statuses,
    searchText,
    changeParam,
    resetFilters,
    selectedDates,
    changeSearchText,
  } = useDeliveryOrdersTabSearchParams({
    defaultDateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    defaultDateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  const { allOrdersDelivery, totalPage, loading } = useOrdersForDelivery({
    filters,
    page: parseInt(page),
    pageSize: PAGE_COUNT,
  });

  return (
    <Box width="100%">
      <AllOrdersToolbar
        statuses={statuses}
        searchText={searchText}
        changeParam={changeParam}
        changeSearchText={changeSearchText}
        selectedDates={selectedDates}
        resetFilters={resetFilters}
      />
      <AllOrdersTable
        allOrdersDelivery={allOrdersDelivery}
        totalPage={totalPage}
        changePage={page => changeParam({ page: `${page + 1}` })}
        currentPage={parseInt(page)}
        loading={loading}
      />
    </Box>
  );
};

import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';

import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { TableWarehouseStocksRowItem } from '../components/tablesComponents/TableWarehouseStocksRowItem';

import { ItemWarehouseType } from '../../../../../graphql/queries/hook/useGetWarehouse';
import { getSortProps } from '../../../../../utils/sortUtils';

interface HookProps {
  itemsWarehouse?: ItemWarehouseType[];
  sort: string[];
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetWarehouseTabTable = ({ itemsWarehouse, sort }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('№'),
        containerProps: {
          width: '56px',
          align: 'center',
          color: '#f1f3f8',
        },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '150px', color: '#f1f3f8', align: 'center' },
        sortProps: {
          ...getSortProps(sort, 'car_spare.tecdoc_articleNumber'),
        },
      },
      {
        title: translateLang('provider'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'supplier.name') },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '400px', color: '#f1f3f8', align: 'center' },
      },

      {
        title: translateLang('manufacturer'),
        containerProps: { width: '240px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'car_spare.car_spare_locales.brandName') },
      },
      {
        title: translateLang('quantityReceived'),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'incomingQuantity') },
      },
      {
        title: translateLang('quantityReserved'),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'reservedQuantity') },
      },
      {
        title: translateLang('quantityOnBalance'),
        containerProps: { width: '40px', color: '#f1f3f8', align: 'center' },
        sortProps: { ...getSortProps(sort, 'balanceQuantity') },
      },
      {
        title: translateLang('place'),
        containerProps: { width: '880px', color: '#f1f3f8', align: 'left' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (itemsWarehouse && itemsWarehouse?.length) {
      return itemsWarehouse?.map((item, index) => {
        return (
          <TableWarehouseStocksRowItem
            key={index}
            index={index}
            article={item.article}
            supplier={item.supplier}
            productName={item.productName}
            brandName={item.brandName}
            productStockId={item.productStockId}
            reservedQuantity={item.reservedQuantity}
            balanceQuantity={item.balanceQuantity}
            incomingQuantity={item.incomingQuantity}
            stockAddressOrder={item.stockAddressOrder}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};

import React, { FC, useMemo } from 'react';
import { format } from 'date-fns';
import { Button, Stack } from '@mui/material';

import { useLocalization } from '../../../../localization';
import { useDefaultRangeCalendar } from '../../../../hooks';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { useGetSuppliersQuery } from '../../../../graphql/queries/__generated__/getSuppliers';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { WarehouseExpectedTabSearchParams } from './hooks/useWarehouseExpectedTabSearchParams';
import { MultipleSelectFilter } from '../../../OrdersList/components/MultipleSelectFilter';
interface Props {
  result?: string | number;
  changeParam: (
    params: Partial<Record<keyof WarehouseExpectedTabSearchParams, string | string[]>>
  ) => void;
  selectedDates: Date[];
  resetFilters: () => void;
  providers: string[];
}

export const ToolBarExpectedTab: FC<Props> = ({
  result,
  selectedDates,
  changeParam,
  resetFilters,
  providers,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();

  const { data: dataSuppliers } = useGetSuppliersQuery({
    variables: {
      filters: { or: [{ blocked: { eq: false } }, { blocked: { eq: null } }] },
      sort: ['name:asc'],
      pagination: {
        limit: -1,
      },
    },
  });

  const providersOptions = useMemo(() => {
    if (dataSuppliers?.suppliers?.data.length) {
      return (
        dataSuppliers?.suppliers.data.map(item => {
          return {
            value: item?.id || '',
            label: item.attributes?.name || `#${item?.id}`,
          };
        }) ?? []
      );
    }
    return [];
  }, [dataSuppliers?.suppliers?.data]);

  return (
    <WrapperToolBar>
      <Stack width="20%" flexDirection="row" justifyContent="left">
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || ''}
        />
      </Stack>
      <Stack spacing={2} bgcolor="white" flexDirection="row" justifyContent="right">
        <Stack direction="row" gap={2} alignItems="center">
          <MultipleSelectFilter
            labelFilter={translateLang('provider')}
            items={providersOptions}
            selectedItems={providers}
            changeUrlParams={selectedProviders => changeParam({ providers: selectedProviders })}
          />
          <RangeCalendar
            selectedDates={selectedDates}
            defaultRangeDates={reportRangeDates}
            setSelectedDates={dateRange => {
              changeParam({
                dateTo: format(dateRange[1], 'yyyy-MM-dd'),
                dateFrom: format(dateRange[0], 'yyyy-MM-dd'),
              });
            }}
          />
          <Button
            color="primary"
            variant="outlined"
            onClick={() => resetFilters()}
            style={{
              width: '120px',
              height: '40px',
              textTransform: 'none',
            }}
          >
            <TranslatedField
              noWrap
              isTranslate
              fontSize={16}
              color="black"
              originText="resetFilters"
            />
          </Button>
        </Stack>
      </Stack>
    </WrapperToolBar>
  );
};
